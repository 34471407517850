<template>
  <div id="app" role="main" class="container-fluid mx-auto p-0">
    <navbar />
    <!--       <router-link to="/"><i class="material-icons">face</i>Home</router-link> |
      <router-link to="/about">About</router-link> -->
    <router-view />
    <eyfooter />
  </div>
</template>

<script>
//import { GET_CLIENTS } from "@/store/actions/clients";
import { GET_UPARENTS } from "@/store/actions/uparent";
import {
  GET_CHANGES,
} from "@/store/actions/admin";

import {
  GET_PIPELINE,
  GET_WINS,
  GET_WINS_GROWTH,
} from "@/store/actions/opportunities";
import {
  GET_ENGAGEMENTS_GTER,
  GET_ENGAGEMENTS_MARGIN,
} from "@/store/actions/engagements";

import navbar from "@/layout/navbar.vue";
import eyfooter from "@/layout/footer.vue";

export default {
  name: "app",
  components: {
    navbar,
    eyfooter,
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "EY Tax Account Leader";
    },
  },
  mounted() {
    this.$store.dispatch(GET_CHANGES);
    this.$store.dispatch(GET_UPARENTS);
    this.$store.dispatch(GET_PIPELINE);
    this.$store.dispatch(GET_WINS);
    this.$store.dispatch(GET_WINS_GROWTH);
    this.$store.dispatch(GET_ENGAGEMENTS_GTER);
    this.$store.dispatch(GET_ENGAGEMENTS_MARGIN);
  },
};
</script>
