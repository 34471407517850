var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid bg-white mw-100 px-12 mx-auto"},[_c('h2',[_vm._v("Dashboard")]),_c('div',{staticClass:"container-fluid bg-white mw-100 m-0",attrs:{"id":"container_fluid_m"}}),_c('div',{attrs:{"id":"overview_cards"}},[_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{attrs:{"title":"GTER YTD (CHF)","bg-variant":"light","border-variant":"primary"}},[_c('br'),_c('b-card-text',[_c('h2',[_vm._v(_vm._s(_vm.getGTER)+" M")])])],1),_c('b-card',{attrs:{"title":"Open Opportunities (CHF)","bg-variant":"light","border-variant":"primary"}},[_c('br'),_c('b-card-text',[_c('h2',[_vm._v(_vm._s(_vm.getPipeline)+" M")])])],1)],1),_c('div',{staticClass:"container-fluid bg-white mw-100 m-0",attrs:{"id":"container_fluid_m"}}),_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{attrs:{"title":"Margin YTD (CHF)","bg-variant":"light","border-variant":"primary"}},[_c('br'),_c('b-card-text',[_c('h2',[_vm._v(_vm._s(_vm.getMargin)+" M")])])],1),_c('b-card',{attrs:{"title":"Wins YTD (CHF)","bg-variant":"light","border-variant":"primary"}},[_c('br'),_c('b-card-text',[_c('h2',[_vm._v(_vm._s(_vm.getWins)+" M")])])],1),_c('b-card',{attrs:{"title":"Wins YTD growth % on PY","bg-variant":"light","border-variant":"primary"}},[_c('b-card-text',[_c('h2',[_vm._v(_vm._s(_vm.getWinsGrowth))])])],1)],1),_c('div',{staticClass:"container-fluid bg-white mw-100 m-0",attrs:{"id":"container_fluid_m"}})],1),(
        _vm.GTERvsPlannedLoadedGetter === false ||
        _vm.GTERplannedLoaded === false ||
        _vm.GTERMarginBySSLLoaded === false ||
        _vm.OpenBySSLLoaded === false ||
        _vm.WinsBySSLLoaded === false ||
        _vm.getpielineByStageLoaded === false ||
        _vm.getpielineWinsBySSlLoaded === false
      )?_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1):_vm._e(),(
        _vm.GTERvsPlannedLoadedGetter === true &&
        _vm.GTERplannedLoaded === true &&
        _vm.GTERMarginBySSLLoaded === true &&
        _vm.OpenBySSLLoaded === true && 
        _vm.WinsBySSLLoaded === true &&
        _vm.getpielineByStageLoaded === true &&
        _vm.getpielineWinsBySSlLoaded === true
      )?_c('dashboard'):_vm._e(),_c('b-button',{attrs:{"id":"buttonUp"},on:{"click":function($event){return _vm.up()}}},[_c('b-icon-arrow-up')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }