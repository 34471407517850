<template>
  <div>
    <div id="overview_cards">
      <!-- first block KPI cards -->

      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!--second block-->

      <b-row>
        <b-col>
          <b-card
            title="GTER v Plan (CHF)"
            id="GTERvPLAN"
            border-variant="primary"
          >
            <br />
            <b-card-text>
              <gterVsPlan />
            </b-card-text>
          </b-card>
        </b-col>

        <div
          class="container-fluid bg-white mw-100 m-0"
          id="container_fluid_m"
        ></div>
        <b-col>
          <b-card-group deck>
            <b-card
              id="card"
              title="GTER Plan (CHF)"
              bg-variant="light"
              border-variant="primary"
            >
              <br />
              <b-card-text>
                <h2>{{ getGTERPlan }} M</h2>
              </b-card-text>
            </b-card>

            <b-card
              id="card"
              title="Weighted Pipeline (CHF)"
              bg-variant="light"
              border-variant="primary"
            >
              <br />
              <b-card-text>
                <h2>{{getweightedPipeline}} M</h2>
              </b-card-text>
            </b-card>

           
          </b-card-group>
          <div
            class="container-fluid bg-white mw-100 m-0"
            id="container_fluid_m"
          ></div>
          <b-card
            id="SSLPenetration"
            title="SSL Penetration USD for Switzerland"
            sub-title="Are each of the SSLs hitting 5% of the overall revenue on the account?"
            bg-variant="light"
            border-variant="primary"
          >
            <sslPenetration />
          </b-card>
        </b-col>
      </b-row>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>
      <!-- third block -->
      <b-row>
       
        <b-col>
      
          <div
            class="container-fluid bg-white mw-100 m-0"
            id="container_fluid_m"
          ></div>
          <b-card
            id="GTERMargin"
            title="GTER FYTD and Margin FYTD by SSL (CHF)"
            bg-variant="light"
            border-variant="primary"
          >
            <b-card-text>
              <gterMarginBySSL />
            </b-card-text>
          </b-card>

          
        </b-col>
      </b-row>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>


     <!-- <b-card title="Charged hours by Competency">
        <charged-hours />
      </b-card>-->
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- fifth block -->
      <b-card-group deck>
       
        <b-card id="diagrammCard" title="Top 10 Accounts">
          <top10acc />
        </b-card>
      </b-card-group>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- sixth block -->
      <b-card id="diagrammCard" title="Pipeline and Wins by SSL (CHF)">
        <b-card-text><pipelinewins /></b-card-text>
      </b-card>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- seventh block -->

      <!-- eighth block Opportunity Diagramm-->
      <b-card-group deck>
        <b-card
          id="diagrammCard"
          title="Count of Opps by Opp Stage FY23"
          
        >
          <OppsByStage />
        </b-card>

        <b-card
          id="diagrammCard"
          title="Expected Amount by Opp Stage FY23 (CHF)"
         
        >
          <OppsSumByStage />
        </b-card>
      </b-card-group>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>


      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>
      <!-- tenth block -->
      <b-card id="diagrammCard" title="Open Opportunities created in FY 2023 by OP (CHF)">
        <div class="text-center mt-5" v-if="coloppipe === undefined">
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
        <vue-good-table
          v-if="coloppipe != undefined"
          :columns="coloppipe"
          :rows="getoppipeagg"
          :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
          :sort-options="{
            enabled: true,
          }"
          compactMode
        />
      </b-card>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 11th block -->
      <b-card id="diagrammCard" title="Top 25 Open Opportunities">
        <div class="text-center mt-5" v-if="geTopOpen25 === undefined">
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
        <vue-good-table
          v-if="geTopOpen25 != undefined"
          :columns="colOpps"
          :rows="geTopOpen25"
          :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
          :sort-options="{
            enabled: true,
          }"
          compactMode
        />
      </b-card>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 12th block -->
      <b-card-group deck>
        <b-card id="diagrammCard" title="Open Opportunities FYTD by SSL (CHF)">
          <b-card-text><openbyssl/></b-card-text>
        </b-card>
      </b-card-group>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 13th block -->
      <b-card id="diagrammCard" title="Top 25 Wins FYTD">
        <div class="text-center mt-5" v-if="gettop25Won === undefined">
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
        <vue-good-table
          v-if="gettop25Won != undefined"
          :columns="colOpps"
          :rows="gettop25Won"
          :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
          :sort-options="{
            enabled: true,
          }"
          compactMode
        />
      </b-card>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 14th block -->
      <b-card-group deck>
        <b-card id="diagrammCard" title="Wins FYTD by SSL (CHF)">
          <b-card-text><winsytdssl /></b-card-text>
        </b-card>
      </b-card-group>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 15th block -->

      <b-card id="diagrammCard1" title="Top 25 Losses/declines FYTD">
        <div class="text-center mt-5" v-if="gettop25Lost === undefined">
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
        <vue-good-table
          v-if="gettop25Lost != undefined"
          :columns="colOpps"
          :rows="gettop25Lost"
          :pagination-options="{
            enabled: true,
            perPage: 10,
          }"
          :sort-options="{
            enabled: true,
          }"
          compactMode
        />
      </b-card>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 16th block -->

      <b-card-group deck>
        <b-card
          title="Accounts Receivable (<90 days) in CHF"
          bg-variant="light"
          border-variant="primary"
        >
          <br />

          <b-card-text>
            <h2>{{ getARBelow90 }} M</h2>
          </b-card-text>
        </b-card>

        <b-card
          title="Accounts receivable 91-150 days in CHF"
          bg-variant="light"
          border-variant="primary"
        >
          <br />
          <b-card-text>
            <h2>{{ getARbetween90_150 }} M</h2>
          </b-card-text>
        </b-card>

        <b-card
          title="Accounts receivable 151 days+ in CHF"
          bg-variant="light"
          border-variant="primary"
        >
          <br />
          <b-card-text>
            <h2>{{ getAROver151 }} M</h2>
          </b-card-text>
        </b-card>
        <b-card
          title="Total Accounts Receivable in CHF"
          bg-variant="light"
          border-variant="primary"
        >
          <br />
          <b-card-text>
            <h2>{{ getARtotal }} M</h2>
          </b-card-text>
        </b-card>
      </b-card-group>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <!-- 17th block 
      <b-card-group deck>
        <b-card
          id="diagrammCard"
          title="AR aging >150 days, by Engagement Partner"
          sub-title="Total Amount in KCHF of aging invoices with aging over 150 days"
        >
          <div>
            <arAgingOver150 />
          </div>
        </b-card>

       
      </b-card-group>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>
       <b-card
          id="diagrammCard"
          title="AR aging by Engagement Partner (sorted largest to smallest)"
          sub-title="Total Amount in CHF of aging invoices"
        >
          <div>
            <arAgingByEngParter />
          </div>
        </b-card>-->

      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>
    </div>
  </div>
</template>

<script>
import OppsByStage from "@/components/dashboard/oppsByStage.vue";
import OppsSumByStage from "@/components/dashboard/oppsSumByStage.vue";
import gterVsPlan from "@/components/dashboard/gterVsPlan.vue";
import sslPenetration from "@/components/dashboard/sslPenetration";
import gterMarginBySSL from "@/components/dashboard/gterMarginBySSL";

import ytdAccountMargin from "@/components/dashboard/ytdAccountMargin";
import pipelinewins from "@/components/dashboard/pipelinewins";
import pipelinebySSL from "@/components/dashboard/pipelinebySSL";

import winsytdssl from "@/components/dashboard/winsytdssl";
import openbyssl from "@/components/dashboard/openbyssl";
import chargedHours from "@/components/dashboard/chargedHours";

import top10acc from "@/components/dashboard/top10acc";
import arAgingOver150 from "@/components/dashboard/arAgingOver150";
import arAgingByEngParter from "@/components/dashboard/arAgingByEngParter";



//import { Bar } from "vue-chartjs";

export default {
  //extends: Bar,
  props: {
    label: {
      type: String,
    },
    chartData: {
      type: Array,
    },
    options: {
      type: Object,
    },
  },
  name: "Dashboard",
  components: {
    OppsByStage,
    OppsSumByStage,
    gterVsPlan,
    sslPenetration,
    gterMarginBySSL,
    ytdAccountMargin,
    pipelinewins,
    pipelinebySSL,
    winsytdssl,
    openbyssl,
    chargedHours,
    top10acc,
    arAgingOver150,
    arAgingByEngParter,
  },
  data() {
    return {
      partnerview: [],
      totalpartnerview: [],
      accagg: [],
      aragingataloaded: false,
      loadedtotal: false,
      loadedtotal365: false,
      loadedtop10acc: false,
      chartOptionsBar: {
        responsive: true,
        //maintainAspectRatio: true,
        borderColor: "rgba(255, 230, 0, 1)",
        backgroundColor: "rgba(255, 230, 0, 1)",
      },
      colOpps: [
       
        {
          label: "Opportunity",
          field: "OpportunityNm",
        },
        {
          label: "Client",
          field: "CustomerNm",
        },
        {
          label: "SSL",
          field: "EngagementSubServiceLine",
        },
       
       
        {
          label: "Opportunity Partner",
          field: "OpportunityPartner",
        },
        {
          label: "Expected Amount",
          field: "ExpectedAmt",
          
          sortable: true,
          
         
        },
      ],
      coloppipe: [
        {
          label: "Opportunity Partner",
          field: "OP",
        },
        {
          label: "SSL",
          field: "SSL",
        },
        {
          label: "Total Pipeline Constant",
          field: "ValuePipeline",
        },
      ],
    };
  },
  mounted() {
    fieldFN: (value)  =>{
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          }
    
  },
  methods: {
 
  },

  computed: {
    getARBelow90() {
      var value = this.$store.getters.getengagementsARBelow90;

      return value;
    },
    getARBelow90Check() {
      var value = this.$store.getters.getengagementsARBelow90;
      return value;
    },
    getAROver151() {
      var value = this.$store.getters.getengagementsAROver150;

      return value;
    },
    getARtotal() {
      var value = this.$store.getters.getengagementsARTotal;
      return value;
    },
    getARbetween90_150() {
      var value = this.$store.getters.getengagementsARBetween90_150;
      return value;
    },

    getweightedPipeline() {
      var value = (this.$store.getters.getweightedPipeline / 1000000).toFixed(2);
      return value;
    },
    getweightedPipelineGrowth() {
      var value = this.$store.getters.getweightedPipelineGrowth;
      return value;
    },
    geTopOpen25() {
      return this.$store.getters.gettop25Open;
    },
    gettop25Won() {
      return this.$store.getters.gettop25Won;
    },
    gettop25Lost() {
      return this.$store.getters.gettop25Lost;
    },
    getoppipeagg() {
      return this.$store.getters.getpielineByPartner;
    },
    getGTERPlan() {
      var plan = (
        this.$store.getters.getengagementsGTERplanned / 1000000
      ).toFixed(2);
      return plan;
    },
  },
};
</script>


<style>
#overview_cards {
  margin-left: 5%;
  margin-right: 5%;
}
#GTERvPLAN {
  min-height: 585px;
}
#SSLPenetration {
  min-width: none;
  min-height: none;
}
#card {
  min-width: none;
  max-height: none;
}
#GTERbyDelivery {
  min-width: none;
  min-height: none;
}
#GTERMargin {
  min-width: none;
  min-height: none;
}
#diagrammCard {
  min-height: 490px;
}
#diagrammCard2 {
  min-height: 100px;
}
#container_fluid_m {
  min-height: 20px;
}
#buttonP {
  margin-left: 35%;
  margin-top: 25%;
}
#buttonB {
  margin-left: 35%;
  margin-top: 40%;
}
</style>

