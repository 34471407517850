import api from "./api.services";

const tenantid = sessionStorage.getItem('adal.idtoken');
const resource = "/opportunities";
const config = { headers: { Authorization: 'Bearer ' + tenantid } }
export default {
  get(ClientID) {
    const params = new URLSearchParams([['ClientID', ClientID ]])
    return api.get(`${resource}`, {params}, config);
  },
 getPipeline() {
  return api.get("/opportunities/pipeline",  config);
},
getWins() {
  return api.get("/opportunities/wins",  config);
},
getPipelineGrowth() {
  return api.get("/opportunities/pipelineGrowth",  config);
},
getWinGrowth() {
  return api.get("/opportunities/winGrowth",  config);
},
getPipelineWinsBySSL(){
  return api.get("/opportunities/PipelineWinsbySSL",  config);
},
getCountOppByStage(){
  return api.get("/opportunities/CountOppByStage",  config);
},
getPipelineByStage(){
  return api.get("/opportunities/PipelineStage",  config);
},
getPipelineBySSLComp(){
  return api.get("/opportunities/PipelineSSLCompetency",  config);
},
getPipelineByPartner(){
  return api.get("/opportunities/PipelinebyPartner",  config);
},
getWinBySSL(){
  return api.get("/opportunities/WinBySSL",  config);
},
getWinByDeliveryRegion(){
  return api.get("/opportunities/WinByDeliveryRegion",  config);
},
getOpenBySSL(){
  return api.get("/opportunities/OpenBySSL",  config);
},

getLossByRegion(){
  return api.get("/opportunities/LossByRegion",  config);
},
getTop25Open(){
  return api.get("/opportunities/Top25Open",  config);
},
getTop25Won(){
  return api.get("/opportunities/Top25Won",  config);
},
getTop25Lost(){
  return api.get("/opportunities/Top25Lost",  config);
},
getweightedPipeline(){
  return api.get("/opportunities/weightedPipeline",  config);
},
getweightedPipelineGrowth(){
  return api.get("/opportunities/weightedPipelineGrowth",  config);
},












};
