<template>
  <div>
    <b-table :fields="columns" :items="getGterBySSL" bordered small hover>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "sslPenetration",

  data() {
    return {
      columns: [
        {
          label: "Delivery Country: Switzerland",
          key: "EngagementSubServiceLine",
        },
        {
          label: "GTER in MCHF",
          key: "FYTD_TERAmt",
        },
        {
          label: "5%?",
          key: "fivePercent",
        },
      ],
    };
  },

  computed: {
    getGterBySSL() {
      
      return this.$store.getters.getengagementsSSLPenetration;
    },
  },
};
</script>

