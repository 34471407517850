<template>
  <apexchart
    id="chargedhours"
    height="351"
    :options="options"
    :series="series"
    type="bar"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data: function () {
    return {
      options: {
        dataLabels: {
  enabled: false,
 
 },
        chart: {
          id: "chargedhours",
        },
       
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: [],
          
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value ;
            },
            
          },
        },
      },
      series: [
        {
          name: "Charged Hours per Competency",
          data: [],
        },
         {
          name: "Margin per Hour",
          data: []
        },
      ],
    };
  },

 mounted() {
    var values = this.$store.getters.getengagementsChargedHoursByCompetency;

    var cat = [];
    var hrs = [];
    var margin = []

    for (var i = 1; i < values.length; i++) {
      cat.push(values[i].EngagementCompetency);
      hrs.push(values[i].FYTD_ChargedHours);
      margin.push(values[i].FYTD_MarginAmt);
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };
    this.series[0].data = hrs;
    this.series[1].data = margin;
  },
};
</script>

<style>
#chargedhours {
  height: 351;
}
</style>