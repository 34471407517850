import Vue from 'vue';
import VueRouter from 'vue-router';
import authentication from '../authentication/index_msal'

// INFO: import route components
import Home from '../views/Home.vue';
import Information from '../views/Information.vue';
import admin from '../views/Admin.vue';
import notAdmin from '../views/NotAdmin.vue';
import TalmanagementUltimateParents from '../views/TalmanagementUltimateParents.vue';
import Dashboard from '../views/Dashboard.vue';
import Feedback from '@/views/Feedback';
import talTracking from '@/views/AdminTracking.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuthentication: true,
            title: 'Home'
        }
    },
    {
        path: '/information',
        name: 'Information',
        component: Information,
        meta: {
            title: 'Information'
        }
    },
    /* https://medium.com/javascript-in-plain-english/defining-nested-routes-with-vue-router-2ce874cf2ca0 */
    
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        meta: {
            requiresAuthentication: true
        }
    },
    {
        path: '/talTracking',
        name: 'talTracking',
        component: talTracking,
        meta: {
            requiresAuthentication: true
        }
    },
    {
        path: '/notadmin',
        name: 'NotAdmin',
        component: notAdmin,
        meta: {
            requiresAuthentication: true

        }
    },
    {
        path: '/ultimateParents',
        name: 'TalmanagementUltimateParents',
        component: TalmanagementUltimateParents,
        meta: {
            requiresAuthentication: true,
            title: 'UltimateParents'
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuthentication: true,
            title: 'Dashboard'
        }
    },
    {
        path: '/Feedback',
        name: 'Feedback',
        component: Feedback,
        meta: {
            requiresAuthentication: true,
            title: 'Feedback'
        }
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});


//Global route guard
  router.beforeEach((to, from, next) => {
     if (to.matched.some(record => record.meta.requiresAuthentication)){
        //route requires auth --> check if logged in 
         if(authentication.isAuthenticated()){
             //proceed if authenticated
             next();}
             else{
                 authentication.initialize()
            }
         }else{
             next();
         }
     }); 


export default router;