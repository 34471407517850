<template>
  <!-- eslint-disable max-len -->

  <div class="home" id="home-container">
    <!--Header Picture block-->
    <div class="container-fluid bg-secondary mw-100 mx-auto p-0">
      <div class="ey-banner-wrapper">
        <div class="ey-img-box-home"  id="img_box"></div>
        <div class="ey-img-overlay"  id="img_overlay"></div>
        <div class="ey-text-box">
          <h1 class="text-white h1 font-weight-normal m-0">Swiss TAL Management</h1>
        </div>
      </div>
    </div>


    <div class="container-fluid bg-dark text-white mw-100 px-12 mx-auto">
      <div class="row align-items-center">
        <div class="col text-left text-yellow">
          <h2 class="h1 m-0 pb-5">Tax Account Leader (TAL)</h2>
        </div>
      </div>
      <!-- http://jsfiddle.net/brichins/gvhpfccu/ -->
      <div class="row d-flex justify-content-end">
        <div class="col-6">
          <h3 id="h3" class="text-left h4 m-0">A key role to ensure an Exceptional Client Service</h3>
        </div>
        <div class="col">
          <div>
            <p id="p" >The Tax Account Leader (TAL) under our TAL 2.0 program must own the tax department relationship at the company.</p>
            <p  id="p" >A high performing TAL needs to lead the charge and ensure Exceptional Client Service — Insightful, Proactive and Connected.</p>
            <p id="p"  >The TAL assigns those relationships to members of their Global Tax team to ensure a high standard cross Sub Service line and cross Service Line delivery.</p>
          </div>
        </div>
      </div>
    </div>
    <!--Empty spacer block-->
    <div class="container-fluid bg-dark mw-100 m-0"  id="container_fluid_l"></div>
    <!--Empty spacer block-->
    
    <!-- Icons-Features Block -->
      <HomeIcons />
    <div class="container-fluid bg-white mw-100 m-0"  id="container_fluid_l"></div>

  </div>
</template>

<script>
// @ is an alias to /src
import HomeIcons from '@/components/HomeIcons.vue';

export default {
  name: 'Home',
  components: {
    HomeIcons,
  },
};
</script>

<style>
#home-container {height: 150%;}
#img_box {height: 90%;}
#img_overlay {height: 90%;}
#p {font-size: 2.2vmin;}
#h3 {font-size: 3.25vmin;}
#container_fluid_l {min-height: 40px;}
#container_fluid_s {min-height: 20px;}
</style>