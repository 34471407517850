// INFO: import necessary api factory
import {
    GET_ENGAGEMENTS,
    GET_ENGAGEMENTS_GTER,
    GET_ENGAGEMENTS_GROWTH,
    GET_ENGAGEMENTS_GTER_PLANNED,
    GET_ENGAGEMENTS_GTER_VS_PLANNED,
    GET_ENGAGEMENTS_GTER_MARGIN_BY_SSL,
    GET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY,
    GET_ENGAGEMENTS_MARGIN_GROWTH_PY,
    GET_ENGAGEMENTS_TOP_TEN_ACCOUNTS,
    GET_ENGAGEMENTS_MARGIN,
    GET_ENGAGEMENTS_MARGIN_PERCENT,
    GET_ENGAGEMENTS_SSL_PENETRATION,
    GET_ENGAGEMENTS_AR_BELOW_90,
    GET_ENGAGEMENTS_AR_BETWEEN_90_150,
    GET_ENGAGEMENTS_AR_OVER_150,
    GET_ENGAGEMENTS_AR_TOTAL,
    GET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER,
    GET_ENGAGEMENTS_AR_BY_ENG_PARTNER,
    GET_ENGAGEMENT_SUM,
    GET_ENGAGEMENTS_BY_ID,
    LOAD_STATUS_UPARENTS_BY_ENGAGEMENT_ID
} from "../actions/engagements";
import apiFactory from '../../services/api.factory';
const engagements = apiFactory.get('engagements');



// INFO: Here we declare the stores available for this 'accounts' store
const state = {
    engagementsloaded: false,
    engagementsGTER: {},
    engagementsGTERGrowth: null,
    engagementsGTERplanned: null,
    engagementsGTERplannedLoaded: false,
    engagementsGTERvsPlanned: [],
    engagementsGTERvsPlannedLoaded: false,
    engagementsGTERMarginBySSL: [],
    engagementsGTERMarginBySSLLoaded: false,
    engagementsChargedHoursByCompetency: [],
    engagementsMarginGrowthPY: [],
    engagementsTopTenAccounts: [],
    engagementsMargin: null,
    engagementsMarginPercent: null,
    engagementsSSLPenetration: [],
    engagementsARBelow90: [],
    engagementsARBetween90_150: [],
    engagementsAROver150: [],
    engagementsARTotal: [],
    engagementsAROver150ByEngPartner: [],
    engagementsARByEngPartner: [],
    engagements: [],
    engagementsByID: [],
    engagementsbyIDloaded: false,
    engagementsARByEngPartnerLoaded: false
  
};
// INFO: getters are to compute a state (derivation)
const getters = {
    engagementsloadingstate: state => {
        return state.engagementsloaded;
    },
    getengagements: state => {
        return state.engagements;
    },
    
    getengagementsGTER: state => {
        return state.engagementsGTER[0].FYTD_TERAmt
    },
    getengagementsGrowth: state => {
        return state.engagementsGTERGrowth
    },
    getengagementsGTERplanned: state => {
        return state.engagementsGTERplanned[0].Planned_TER
    },
    getengagementsGTERplannedLoaded: state => {
        return state.engagementsGTERplannedLoaded
    },
    getengagementsGTERvsvPlanned: state => {
        return state.engagementsGTERvsPlanned
    },
    getengagementsGTERvsPlannedLoaded: state => {
        return state.engagementsGTERvsPlannedLoaded
    },
    getengagementsGTERMarginBySSL: state => {
        return state.engagementsGTERMarginBySSL
    },
    getengagementsGTERMarginBySSLLoaded: state => {
        return state.engagementsGTERMarginBySSLLoaded
    },
    getengagementsChargedHoursByCompetency: state => {
        return state.engagementsChargedHoursByCompetency.hoursByCompetency
    },
    getengagementsMarginGrowthPY: state => {
        return state.engagementsMarginGrowthPY.results
    },
    getengagementsTopTenAccounts: state => {
        return state.engagementsTopTenAccounts
    },
    getengagementsMargin: state => {
        return state.engagementsMargin[0].FYTD_MarginAmt
    },
    getengagementsMarginPercent: state => {
        return state.engagementsMarginPercent
    },
    getengagementsSSLPenetration: state => {
        return state.engagementsSSLPenetration
    },
    getengagementsARBelow90: state => {
        return state.engagementsARBelow90
    },
    getengagementsARBetween90_150: state => {
        return state.engagementsARBetween90_150
    },
    getengagementsAROver150: state => {
        return state.engagementsAROver150
    },
    getengagementsARTotal: state => {
        return state.engagementsARTotal
    },
    getengagementsAROver150ByEngPartner: state => {
        return state.engagementsAROver150ByEngPartner.ARbyEngPartner
    },
    getengagementsARByEngPartner: state => {
        return state.engagementsARByEngPartner.ARbyEngPartner
    },
    getengagementsByID: state => {
        return state.engagementsByID
    },
    getengagementsbyIDloaded: state => {
        return state.engagementsbyIDloaded
    },
    getengagementsARByEngPartnerLoaded: state => {
        return state.engagementsARByEngPartnerLoaded
    }

};

// INFO: Actions do not mutate directly a state instead they commit a mutation. Actions can contain async activities
const actions = {
    [GET_ENGAGEMENTS]: async ({ commit, dispatch }, ClientID) => {
        await engagements.get(ClientID).then(resp => {
            commit('SET_ENGAGEMENTS', resp.data);
        });
    },
    [GET_ENGAGEMENTS_GTER]: async ({ commit, dispatch }) => {
        await engagements.getGTERytd().then(resp => {
            commit('SET_ENGAGEMENTS_GTER', resp.data);
        });
    },
    [GET_ENGAGEMENTS_GROWTH]: async ({ commit, dispatch }) => {
        await engagements.getGTERGrowth().then(resp => {
            commit('SET_ENGAGEMENTS_GROWTH', resp.data);
        });
    },
    [GET_ENGAGEMENTS_GTER_PLANNED]: async ({ commit, dispatch }) => {
        await engagements.getGTERPlanned().then(resp => {
            commit('SET_ENGAGEMENTS_GTER_PLANNED', resp.data);
        });
    },
    [GET_ENGAGEMENTS_GTER_VS_PLANNED]: async ({ commit, dispatch }) => {
        await engagements.getGTERvsPlanned().then(resp => {
            commit('SET_ENGAGEMENTS_GTER_VS_PLANNED', resp.data);
        });
    },
    [GET_ENGAGEMENTS_GTER_MARGIN_BY_SSL]: async ({ commit, dispatch }) => {
        await engagements.getGTERMarginBySSL().then(resp => {
            commit('SET_ENGAGEMENTS_GTER_MARGIN_BY_SSL', resp.data);
        });
    },
    [GET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY]: async ({ commit, dispatch }) => {
        await engagements.getChargedHoursByCompetency().then(resp => {
            commit('SET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY', resp.data);
        });
    },
    [GET_ENGAGEMENTS_MARGIN_GROWTH_PY]: async ({ commit, dispatch }) => {
        await engagements.getMarginGrowthPY().then(resp => {
            commit('SET_ENGAGEMENTS_MARGIN_GROWTH_PY', resp.data);
        });
    },
    [GET_ENGAGEMENTS_TOP_TEN_ACCOUNTS]: async ({ commit, dispatch }) => {
        await engagements.getTopTenAccounts().then(resp => {
            commit('SET_ENGAGEMENTS_TOP_TEN_ACCOUNTS', resp.data);
        });
    },
    [GET_ENGAGEMENTS_MARGIN]: async ({ commit, dispatch }) => {
        await engagements.getMargin().then(resp => {
            commit('SET_ENGAGEMENTS_MARGIN', resp.data);
        });
    },
    [GET_ENGAGEMENTS_MARGIN_PERCENT]: async ({ commit, dispatch }) => {
        await engagements.getMarginPercent().then(resp => {
            commit('SET_ENGAGEMENTS_MARGIN_PERCENT', resp.data);
        });
    },
    [GET_ENGAGEMENTS_SSL_PENETRATION]: async ({ commit, dispatch }) => {
        await engagements.getSSLPenetration().then(resp => {
            commit('SET_ENGAGEMENTS_SSL_PENETRATION', resp.data);
        });
    },
    [GET_ENGAGEMENTS_AR_BELOW_90]: async ({ commit, dispatch }) => {
        await engagements.getARbelow90().then(resp => {
            commit('SET_ENGAGEMENTS_AR_BELOW_90', resp.data);
        });
    },
    [GET_ENGAGEMENTS_AR_BETWEEN_90_150]: async ({ commit, dispatch }) => {
        await engagements.getARbetween90_150().then(resp => {
            commit('SET_ENGAGEMENTS_AR_BETWEEN_90_150', resp.data);
        });
    },
    [GET_ENGAGEMENTS_AR_OVER_150]: async ({ commit, dispatch }) => {
        await engagements.getARover151().then(resp => {
            commit('SET_ENGAGEMENTS_AR_OVER_151', resp.data);
        });
    },
    [GET_ENGAGEMENTS_AR_TOTAL]: async ({ commit, dispatch }) => {
        await engagements.getARtotal().then(resp => {
            commit('SET_ENGAGEMENTS_AR_TOTAL', resp.data);
        });
    },
    [GET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER]: async ({ commit, dispatch }) => {
        await engagements.getARover150byEngPartner().then(resp => {
            commit('SET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER', resp.data);
        });
    },

    [GET_ENGAGEMENTS_AR_BY_ENG_PARTNER]: async ({ commit, dispatch }) => {
        await engagements.getARbyEngPartner().then(resp => {
            commit('SET_ENGAGEMENTS_AR_BY_ENG_PARTNER', resp.data);
        });
    },
    [GET_ENGAGEMENTS_BY_ID]: async ({ commit, dispatch }, EngagementID) => {
        await engagements.getByEngagement(EngagementID).then(resp => {
            commit('SET_ENGAGEMENTS_BY_ID', resp.data);
        });
    },
    [LOAD_STATUS_UPARENTS_BY_ENGAGEMENT_ID]: async ({ commit }) => {
        commit("SET_LOAD_STATUS_BY_ENGAGEMENT");
      },



    
};

// INFO: Mutations are the only way to change the state
const mutations = {
    SET_ENGAGEMENTS(state, payload) {
        state.engagementsloaded = true;
        state.engagements = payload;
    },
    SET_ENGAGEMENTS_BY_ID(state, payload){
        state.engagementsbyIDloaded = true;
        state.engagementsByID = payload;
    },
    SET_ENGAGEMENTS_GTER(state, payload) {
        state.engagementsGTER = payload;
    },
    SET_ENGAGEMENTS_GROWTH(state, payload) {
        state.engagementsGTERGrowth = payload;
    },
    SET_ENGAGEMENTS_GTER_PLANNED(state, payload) {
        state.engagementsGTERplanned = payload;
        state.engagementsGTERplannedLoaded = true;
    },
    SET_ENGAGEMENTS_GTER_VS_PLANNED(state, payload) {
        state.engagementsGTERvsPlanned = payload;
        state.engagementsGTERvsPlannedLoaded = true;
    },
    SET_ENGAGEMENTS_GTER_MARGIN_BY_SSL(state, payload) {
        state.engagementsGTERMarginBySSL = payload;
        state.engagementsGTERMarginBySSLLoaded = true;
    },
    SET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY(state, payload) {
        state.engagementsChargedHoursByCompetency = payload;
    },
    SET_ENGAGEMENTS_MARGIN_GROWTH_PY(state, payload) {
        state.engagementsMarginGrowthPY = payload;
    },
    SET_ENGAGEMENTS_TOP_TEN_ACCOUNTS(state, payload) {
        state.engagementsTopTenAccounts = payload
    },
    SET_ENGAGEMENTS_MARGIN(state, payload) {
        state.engagementsMargin = payload
    },
    SET_ENGAGEMENTS_MARGIN_PERCENT(state, payload) {
        state.engagementsMarginPercent = payload
    },
    SET_ENGAGEMENTS_SSL_PENETRATION(state, payload){
        state.engagementsSSLPenetration = payload
    },
    SET_ENGAGEMENTS_AR_BELOW_90(state, payload){
        state.engagementsARBelow90 = payload
    },
    SET_ENGAGEMENTS_AR_BETWEEN_90_150(state, payload){
        state.engagementsARBetween90_150 = payload
    },
    SET_ENGAGEMENTS_AR_OVER_151(state, payload){
        state.engagementsAROver150 = payload
    },
    SET_ENGAGEMENTS_AR_TOTAL(state, payload){
        state.engagementsARTotal = payload
    },
    SET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER(state, payload){
        state.engagementsAROver150ByEngPartner = payload  
    },
    SET_ENGAGEMENTS_AR_BY_ENG_PARTNER(state, payload){
        state.engagementsARByEngPartner = payload
        state.engagementsARByEngPartnerLoaded = true;
    },
    SET_LOAD_STATUS_BY_ENGAGEMENT(state){
        state.engagementsbyIDloaded = false;
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};