import * as msal from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
let signInType = "loginRedirect";
let accountId = "";



const request = {
  scopes: ["user.read"]

};

const tokenRequest = {
  scopes: ["Mail.Read"],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
const silentRequest = {
  scopes: ["openid", "profile", "User.Read", "Mail.Read"]
};

const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',


    clientId: process.env.VUE_APP_CLIENT_ID_UAT,
    //redirectUri: "https://uat.talmanagement.ey.com/",
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: "https://intranet.ey.com/",
    protocolMode: "OIDC"

  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false
  }

};
const msalInstance = new msal.PublicClientApplication(msalConfig);
export default {

  initialize: async () => {
    await msalInstance.loginPopup({

    });
    try {
      const loginResponse = await msalInstance.loginPopup({});
    } catch (err) {
      console.log(err)
    }
    const myAccounts = msalInstance.getAllAccounts();

    console.log(myAccounts);

    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      console.log(tokenResponse.idToken)
    }).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenPopup(request);
      }
    }).catch(error => {
      handleError(error);
    });
  },

 
  isAuthenticated: () => {
    // getCachedToken will only return a valid, non-expired token.

    if (myMSALObj.getTokenCache(msalConfig.clientId)) { return true; }
    return false;
  }
}



/*  try {
let tokenResponse = await myMSALObj.handleRedirectPromise();
console.log(tokenResponse)
 
let accountObj;
if (tokenResponse) {
  accountObj = tokenResponse.account;
} else {
  accountObj = myMSALObj.getAllAccounts()[0];

}

if (accountObj && tokenResponse) {
 
} else if (accountObj) {
  
  try {
    tokenResponse = await myMSALObj.acquireTokenSilent({
      account: myMSALObj.getAllAccounts()[0],
      scopes: ["user.read"]
    })
  } catch (err) {
    await myMSALObj.acquireTokenRedirect({ scopes: ["user.read"] });
  }
} else {
 
  await myMSALObj.loginRedirect({ scopes: ["user.read"] })
}
} catch (error) {
console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
}

},
 
*/





