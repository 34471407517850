<template>
  <!-- eslint-disable max-len -->
  <div class="navigation-bar-stack-container">
    <div class="page-header">
      <div class="header-group">
        <!--  Due to accessibility this link to="/" was removed. Since it does the same as "Swiss TAL Management"  -->
        ><img
          alt="EY Logo"
          class="header-group__image"
          src="../../resources/images/ey-logo.svg"
        />
        <div class="header-group__labels-container">
          <span class="header-group__labels">Swiss TAL Management</span>
        </div>
      </div>
    </div>
    <nav
      class="navigation-bar-primary navigation-bar-primary--stack"
      id="navigation-bar-primary"
    >
      <ul>
        <li>
          <span class="navigation-bar-primary__nav-link">
            <b-link to="/">Home</b-link>
          </span>
        </li>
        <li>
          <span class="navigation-bar-primary__nav-link">
            <b-link to="/Information">TAL 2.0 Information</b-link>
          </span>
        </li>

        <li>
          <span class="navigation-bar-primary__nav-link">
            <b-link to="/ultimateParents">TAL Overview</b-link>
          </span>
        </li>
        <li>
          <span class="navigation-bar-primary__nav-link">
            <b-link to="/dashboard">KPIs</b-link>
          </span>
        </li>
        
        
        <span v-if="isAdmin === 0"><li>
          <span class="navigation-bar-primary__nav-link">
            <b-link to="/talTracking">TAL Tracking</b-link>
          </span>
        </li>
        </span>
        <!-- Remove if no longer needed: --> 
         <li>
          <span class="navigation-bar-primary__nav-link">
            <b-link @click="logout()">Logout</b-link>
          </span>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: "navbar",
  computed:{
  isAdmin(){

    return this.$store.getters.getError;
  }
},
  methods: {
    logout() {
      // clear the session storage & close tab
      sessionStorage.clear()
      alert("You are successfully logged out, redirection to EY Intranet!")
      console.log("Session Storage cleared!")
      window.location.href = "https://intranet.ey.com/";
    }
  },
};
</script>

<style></style>
