<template>
  <apexchart
    id="arAgingByEngParter"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data: function () {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: ['Mosimann, Conradin',	'Honold, Kersten',	'Semadeni, Thomas',	'Muller, Aurélien',	'Simonin, Karen',	'Casals, Virginie',	'Franklin, Clare',	'Nutsubidze, Ioseb',	'Krapf, Roger',	'Baumgartner, Martin',	'Fross, Andreas',	'Hildebrandt-Stürmer, Silke',	'Suter, Roland',	'Eggspuhler, Xavier',	'Lutz, Georg',	'Blum, Oliver',	'Hoffmann, Hartwig',	'Cadisch, Michael',	'Taboada Souza Neto, Ramon',	'Trachsel, Christoph',	'Gentsch, Daniel',	'Aivazian, Christian',	'Mühlemann, Marco',	'Parets, Michael',	'Suter, Benno',	'Stocker-Sohst, Andrea',	'Laganà, Stefan',	'Müller, Gregor',	'Stadler, Hubert',	'Nyffenegger, Markus',	'Hainaut, Jean-Marie',	'Bonvin, Nicolas',	'Leston, Juan',	'Schwerzmann, Reto',	'Fisler, Thomas',	'Sinha, Ashish',	'Richards, Nathan',	'Bieri, André',	'Kuhlmann, Carsten',	'Zemp, Martin',	'Geier, Rolf'],
        },
      },
      series: [
        {
          name: "Total Amount by Engagement Partner in CHF",
          data: [1415346.08,	982815.48,	439386.93,	435950.67,	413755.39,	393604.24,	252779.25,	222976.66,	213526.95,	212723.82,	194945.12,	183452.92,	182025.7,	180913.24,	153823.85,	144455.69,	140996.28,	123480.6,	112143.14,	104264.98,	99413.5,	88294.84,	82315.8,	80254.05,	79980.16,	78467.66,	69760.7,	60833.91,	58739.05,	53514.67,	50424.65,	49929.7,	48089.8,	38565.8,	30686.5,	22557.18,	19769.51,	16830.16,	14683.07,	11574,	8240],
        },
      ],
    };
  },

 /* mounted() {
    var values = this.$store.getters.getengagementsARByEngPartner;

    var cat = [];

    var plan = [];
    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].EngagementPartner);
      plan.push(parseFloat((values[i].totalARAmount / 1000).toFixed(1)));
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = plan;
  },*/
};
</script>

<style>
#pipelinewins {
  height: 460;
}
</style>