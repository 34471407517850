export const GET_UPARENTS = "GET_UPARENTS";
export const GET_UPARENTS_NO_TAL = "GET_UPARENTS_NO_TAL";
export const LOAD_STATUS_UPARENTS_NO_TAL = "LOAD_STATUS_UPARENTS_NO_TAL";
export const LOAD_STATUS_UPARENTS = "LOAD_STATUS_UPARENTS";
export const GET_UPARENTS_BY_NAME = "GET_UPARENTS_BY_NAME";
export const LOAD_STATUS_UPARENTS_BY_NAME = "LOAD_STATUS_UPARENTS_BY_NAME";
export const GET_UPARENTS_BY_GTAL = "GET_UPARENTS_BY_GTAL";
export const LOAD_STATUS_UPARENTS_BY_GTAL = "LOAD_STATUS_UPARENTS_BY_GTAL";
export const GET_UPARENTS_BY_SWISSTAL = "GET_UPARENTS_BY_SWISSTAL";
export const LOAD_STATUS_UPARENTS_BY_SwissTAL = "LOAD_STATUS_UPARENTS_BY_SwissTAL";
export const LOAD_STATUS_UPARENTS_BY_ClientName = "LOAD_STATUS_UPARENTS_BY_ClientName";
export const GET_UPARENTS_BY_ClientName = "GET_UPARENTS_BY_ClientName";