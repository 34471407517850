<template>
  <div>
    <!-- empty spacer block -->
    <!-- test -->
    <div
      class="container-fluid bg-white mw-100 m-0"
     
      id="container"
    ></div>
    <!-- Content block - table -->
    <div class="container-fluid bg-white mw-100 mx-auto my-2">
      <h3>Admin area</h3>
      <br />
      <inbox />
      <br />
      <br />
      <changelog />
    </div>
  </div>
</template>

<script>
import inbox from "@/components/admin/inbox.vue";
import changelog from "@/components/admin/changelog.vue";


export default {
  name: "admin",
  components: {
    inbox,
    changelog
  }, 
  
};
</script>

<style>
#container {min-height: 20px;
 
}
</style>
