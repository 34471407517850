<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h1>TAL Management - Ultimate Parents Overview</h1>
      </b-col>
      <b-col
        v-if="getUParentsStatus === true || getUParentsNoTalStatus === true"
        cols="5"
      >
        <b-button
          v-if="getUParentsNoTalStatus === false"
          size="sm"
          variant="outline-dark"
          class="btn-approve mx-2 btn-remove-border"
          id="button"
          @click="showClientsNoTAL()"
          >Show Ultimate Parents without TAL</b-button
        >

        <b-button
          v-if="getUParentsNoTalStatus === true"
          size="sm"
          variant="outline-dark"
          class="btn-approve mx-2 btn-remove-border"
          id="button"
          @click="showClientsTAL()"
          >Show Ultimate Parents with TAL</b-button
        >
        <!--span v-if="isAdmin == 0">
        <b-button
          v-b-tooltip.hover
          title="Add Client"
          size="sm"
          variant="outline-dark"
          class="btn-approve mx-2 btn-remove-border"
          id="button2"
          @click="showClientAdd()"
          ><b-icon-plus
        /></b-button></span>-->

        <b-button
          v-if="getUParentsNoTalStatus === false"
          v-b-tooltip.hover
          title="Refresh Table"
          size="sm"
          variant="outline-dark"
          class="btn-approve mx-2 btn-remove-border"
          id="button2"
          @click="refreshTable()"
          ><b-icon-arrow-clockwise
        /></b-button>

        <b-button
          v-if="getUParentsNoTalStatus === true"
          v-b-tooltip.hover
          title="Refresh Table"
          size="sm"
          variant="outline-dark"
          class="btn-approve mx-2 btn-remove-border"
          id="button2"
          @click="refreshTableNoTAL()"
          ><b-icon-arrow-clockwise
        /></b-button>
      </b-col>

      <b-col
        v-if="
          getUParentsNoTalStatus === false &&
          getUParentsStatus === false &&
          getclientsloadedName === false
        "
        cols="5"
      >
        <b-spinner small label="Small Spinner" id="spinner"></b-spinner>
      </b-col>
    </b-row>
    <br />

    <b-alert variant="success" dismissible fade :show="showAlert"
      >Data change request was sent!</b-alert
    >

    <b-alert variant="success" dismissible fade :show="showAlertEdit"
      >Client successfully updated!</b-alert
    >

    <b-alert variant="success" dismissible fade :show="showAlertAdd"
      >Client successfully added!</b-alert
    >
    <!-- search bar -->
    <div>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
      <b-row cols-sm="5">
        <b-form-group class="mb-0" id="form_group">
          <ValidationProvider
                        v-slot="{ errors }"
                        name="Ultimate Parent Name"
                        :rules="{
                          required: false,
                          regex:
                            /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                        }"
                      >
                      <span id="errorSpan">{{ errors[0] }}</span>
                        <br>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              placeholder="Type to Search for Ultimate Parents Name"
            ></b-form-input>
            
            <b-input-group-append>
              <b-button
                v-if="
                  getuparentsByGTalLoaded === false &&
                  getuparentsloadedName === false &&
                  getuparentsBySwissTALLoaded === false &&
                  getuparentsByClientNameLoaded === false
                "
                variant="light"
                :disabled="!filter || invalid"
                @click="seachAllClients()"
                ><b-icon-search
              /></b-button>
            </b-input-group-append>
            <b-button
              size="sm"
              v-if="getuparentsloadedName === true"
              @click="clearSearch()"
              ><b-icon-x
            /></b-button>
          </b-input-group>
          </ValidationProvider>
        </b-form-group>
        <br />

        <!-- search bar Global TAL-->
        <b-form-group class="mb-0" id="form_group">
          <ValidationProvider
                        v-slot="{ errors }"
                        name="Global TAL"
                        :rules="{
                          required: false,
                          regex:
                            /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                        }"
                      >
                      <span id="errorSpan">{{ errors[0] }}</span>
                        <br>
          <b-input-group size="sm">
             
            <b-form-input
              id="filter-input"
              v-model="filterGTAL"
              placeholder="Type to Search for Global TAL"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                v-if="
                  getuparentsByGTalLoaded === false &&
                  getuparentsloadedName === false &&
                  getuparentsBySwissTALLoaded === false &&
                  getuparentsByClientNameLoaded === false
                "
                variant="light"
                :disabled="!filterGTAL || invalid"
                @click="seachAllClientsGTAL()"
                ><b-icon-search
              /></b-button>
            </b-input-group-append>
            <b-button
              size="sm"
              v-if="getuparentsByGTalLoaded === true"
              @click="clearSearch()"
              ><b-icon-x
            /></b-button>
             
          </b-input-group>
          </ValidationProvider>
        </b-form-group>
        <br />

        <!-- search bar Swiss TAL-->
        <b-form-group class="mb-0" id="form_group">
          <ValidationProvider
                        v-slot="{ errors }"
                        name="Swiss TAL"
                        :rules="{
                          required: false,
                          regex:
                            /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                        }"
                      >
                      <span id="errorSpan">{{ errors[0] }}</span>
                        <br>
        
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filterSwissTAL"
              placeholder="Type to Search for Swiss TAL"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                v-if="
                  getuparentsByGTalLoaded === false &&
                  getuparentsloadedName === false &&
                  getuparentsBySwissTALLoaded === false &&
                  getuparentsByClientNameLoaded === false
                "
                variant="light"
                :disabled="!filterSwissTAL || invalid"
                @click="seachAllClientsSwissTAL()"
                ><b-icon-search
              /></b-button>
            </b-input-group-append>
            <b-button
              size="sm"
              v-if="getuparentsBySwissTALLoaded === true"
              @click="clearSearch()"
              ><b-icon-x
            /></b-button>
          </b-input-group>
          </ValidationProvider>
        </b-form-group>

        <!-- search bar Client Name-->
        <b-form-group class="mb-0" id="form_group">
           <ValidationProvider
                        v-slot="{ errors }"
                        name="Client Name"
                        :rules="{
                          required: false,
                          regex:
                            /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                        }"
                      >
                      <span id="errorSpan">{{ errors[0] }}</span>
                        <br>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filterClientName"
              placeholder="Type to Search for Client Name"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                v-if="
                  getuparentsByGTalLoaded === false &&
                  getuparentsloadedName === false &&
                  getuparentsBySwissTALLoaded === false &&
                  getuparentsByClientNameLoaded === false
                "
                variant="light"
                :disabled="!filterClientName || invalid"
                @click="seachAllClientsByName()"
                ><b-icon-search
              /></b-button>
            </b-input-group-append>
            <b-button
              size="sm"
              v-if="getuparentsByClientNameLoaded === true"
              @click="clearSearch()"
              ><b-icon-x
            /></b-button>
          </b-input-group>
           </ValidationProvider>
        </b-form-group>
      </b-row>
      </ValidationObserver>
      <br />
      <div
        v-if="
          getuparentsByGTalLoaded === false &&
          getuparentsloadedName === false &&
          getuparentsBySwissTALLoaded === false &&
          getuparentsByClientNameLoaded === false &&
          getuparentsloaded === false &&
          getUParentsNoTalStatus === false
        "
        class="text-center mt-5"
      >
        <b-spinner type="grow" label="Loading..."></b-spinner>

        <div
          class="container-fluid bg-white mw-100 m-0"
          id="container_fluid_s"
        ></div>
      </div>

      <!-- uparents table with tal -->

      <b-table
        v-if="getuparentsloaded === true"
        id="uparentTable"
        :items="getuparents"
        :fields="columns"
        bordered
        small
        hover
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        @filtered="onFiltered"
      >
        <template #cell(Account)="row">
          <!-- button show client to uparent -->
          <b-button
            size="sm"
            :disabled="isActive"
            @click="
              showChildren(row.item);
              row.toggleDetails();
              disableButton();
            "
            class="mr-2"
            variant="link"
          >
            {{ row.detailsShowing ? row.item.Account : row.item.Account }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              :items="getClientByUDUNS"
              :fields="columnsClients"
              striped
              responsive="sm"
              small
              bordered
            >
              <template #cell(Client)="row">
                <!-- button show client details -->
                <b-button
                  size="sm"
                  @click="
                    showClientDetail(row.item);
                    row.toggleDetails();
                    disableButton();
                  "
                  class="mr-2"
                  variant="link"
                >
                  {{ row.detailsShowing ? row.item.Client : row.item.Client }}
                </b-button>
              </template>
              <template #cell(edit)="row">
                <span v-if="isAdmin == 0">
                  <b-button
                    size="sm"
                    @click="showClientEdit(row.item)"
                    class="mr-2"
                    variant="link"
                  >
                    <b-icon-pencil-fill />
                  </b-button>
                </span>
                <span v-else></span>
              </template>
            </b-table>

            <b-button
              size="sm"
              @click="
                openSidebar();
                row.toggleDetails();
              "
              squared
              variant="primary"
              >Close</b-button
            >
          </b-card>
        </template>
      </b-table>
      <!-- uparents table without tal -->

      <b-table
        v-if="getUParentsNoTalStatus === true"
        id="uparentTableNOTAL"
        :items="getuparentsListNoTAL"
        :fields="columns"
        bordered
        small
        hover
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        @filtered="onFiltered"
      >
        <template #cell(Account)="row">
          <b-button
            size="sm"
            :disabled="isActive"
            @click="
              showChildren(row.item);
              row.toggleDetails();
              disableButton();
            "
            class="mr-2"
            variant="link"
          >
            {{ row.detailsShowing ? row.item.Account : row.item.Account }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              :items="getClientByUDUNS"
              :fields="columnsClients"
              striped
              responsive="sm"
              small
              bordered
            >
              <template #cell(Client)="row">
                <b-button
                  size="sm"
                  @click="
                    showClientDetail(row.item);
                    row.toggleDetails();
                  "
                  class="mr-2"
                  variant="link"
                >
                  {{ row.detailsShowing ? row.item.Client : row.item.Client }}
                </b-button>
              </template>
              <template #cell(edit)="row">
                <span v-if="isAdmin == 0">
                  <b-button
                    size="sm"
                    @click="showClientEdit(row.item)"
                    class="mr-2"
                    variant="link"
                  >
                    <b-icon-pencil-fill />
                  </b-button>
                </span>
                <span v-else></span>
              </template>
            </b-table>

            <b-button
              size="sm"
              @click="
                row.toggleDetails();
                isActive = !isActive;
              "
              squared
              variant="primary"
              >Close</b-button
            >
          </b-card>
        </template>
      </b-table>

      <!-- search Result -->

      <b-table
        v-if="getuparentsloadedName === true"
        id="SearchResultTable"
        :items="getUparentsByName"
        :fields="columns"
        bordered
        small
        hover
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(Account)="row">
          <b-button
            size="sm"
            :disabled="isActive"
            @click="
              showChildren(row.item);
              row.toggleDetails();
              disableButton();
            "
            class="mr-2"
            variant="link"
          >
            {{ row.detailsShowing ? row.item.Account : row.item.Account }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              :items="getClientByUDUNS"
              :fields="columnsClients"
              striped
              responsive="sm"
              small
              bordered
            >
              <template #cell(Client)="row">
                <b-button
                  size="sm"
                  @click="
                    showClientDetail(row.item);
                    row.toggleDetails();
                    disableButton();
                  "
                  class="mr-2"
                  variant="link"
                >
                  {{ row.detailsShowing ? row.item.Client : row.item.Client }}
                </b-button>
              </template>
              <template #cell(edit)="row">
                <span v-if="isAdmin == 0">
                  <b-button
                    size="sm"
                    @click="showClientEdit(row.item)"
                    class="mr-2"
                    variant="link"
                  >
                    <b-icon-pencil-fill />
                  </b-button>
                </span>
                <span v-else></span>
              </template>
            </b-table>

            <b-button
              size="sm"
              @click="
                openSidebar();
                row.toggleDetails();
              "
              squared
              variant="primary"
              >Close</b-button
            >
          </b-card>
        </template>
      </b-table>

      <!-- search Result GTAL -->

      <b-table
        v-if="getuparentsByGTalLoaded === true"
        id="SearchResultTableGTAL"
        :items="getuparentsByGTal"
        :fields="columns"
        bordered
        small
        hover
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(Account)="row">
          <b-button
            size="sm"
            :disabled="isActive"
            @click="
              showChildren(row.item);
              row.toggleDetails();
              disableButton();
            "
            class="mr-2"
            variant="link"
          >
            {{ row.detailsShowing ? row.item.Account : row.item.Account }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              :items="getClientByUDUNS"
              :fields="columnsClients"
              striped
              responsive="sm"
              small
              bordered
            >
              <template #cell(Client)="row">
                <b-button
                  size="sm"
                  @click="
                    showClientDetail(row.item);
                    row.toggleDetails();
                    disableButton();
                  "
                  class="mr-2"
                  variant="link"
                >
                  {{ row.detailsShowing ? row.item.Client : row.item.Client }}
                </b-button>
              </template>
              <template #cell(edit)="row">
                <span v-if="isAdmin == 0">
                  <b-button
                    size="sm"
                    @click="showClientEdit(row.item)"
                    class="mr-2"
                    variant="link"
                  >
                    <b-icon-pencil-fill />
                  </b-button>
                </span>
                <span v-else></span>
              </template>
            </b-table>

            <b-button
              size="sm"
              @click="
                openSidebar();
                row.toggleDetails();
              "
              squared
              variant="primary"
              >Close</b-button
            >
          </b-card>
        </template>
      </b-table>

      <!-- search Result Swiss TAL -->

      <b-table
        v-if="getuparentsBySwissTALLoaded === true"
        id="SearchResultTableSwissTAL"
        :items="getuparentsBySwissTAL"
        :fields="columnsClients"
        bordered
        small
        hover
        responsive="sm"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(Client)="row">
          <b-button
            size="sm"
            @click="
              showClientDetail(row.item);
              row.toggleDetails();
              disableButton();
            "
            class="mr-2"
            variant="link"
          >
            {{ row.detailsShowing ? row.item.Client : row.item.Client }}
          </b-button>
        </template>
      </b-table>

      <!-- Uparent by Client Name -->
      <b-table
        v-if="getuparentsByClientNameLoaded === true"
        id="SearchResultTableSwissTAL"
        :items="getuparentsByClientName"
        :fields="columnsClients"
        bordered
        small
        hover
        responsive="sm"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(Client)="row">
          <b-button
            size="sm"
            @click="
              showClientDetail(row.item);
              row.toggleDetails();
              disableButton();
            "
            class="mr-2"
            variant="link"
          >
            {{ row.detailsShowing ? row.item.Client : row.item.Client }}
          </b-button>
        </template>
      </b-table>

      <b-pagination
        v-if="getuparentsloaded === true"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="uparentTable"
        size="sm"
        pills
        align="right"
      ></b-pagination>

      <b-pagination
        v-if="getUParentsNoTalStatus === true"
        v-model="currentPage"
        :total-rows="totalRowsNoTal"
        :per-page="perPage"
        aria-controls="uparentTableNOTAL"
        size="sm"
        pills
        align="right"
      ></b-pagination>
    </div>

    <!-- client edit sidebar -->
    <b-sidebar
      id="sidebar-client-edit"
      title="Edit Client Details"
      width="30%"
      right
      no-header-close
      backdrop
      bg-variant="dark"
      text-variant="light"
    >
      <template #default="{ hide }">
        <b-button
          squared
          aria-label="Close Button"
          id="button3"
          size="sm"
          @click="hide"
          >Close</b-button
        >
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <div class="px-3 py-2">
              <h5>{{ clientdetails.entityname }}</h5>
              <br />
              <div>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Entity ID"
                  :rules="{ required: true, regex: /^[0-9]+$/ }"
                >
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    id="entityID"
                    v-model="clientdetails.entityID"
                    size="sm"
                    placeholder="Edit Entity ID"
                    type="text"
                  ></b-form-input>
                </ValidationProvider>
                <label for="entityID">Entity ID</label>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Entity Name"
                  :rules="{
                    required: true,
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.entityname"
                    size="sm"
                    placeholder="Edit Entity Name"
                  ></b-form-input
                  ><label for="sb-step">Entity Name</label>
                </ValidationProvider>



                <ValidationProvider
                  v-slot="{ errors }"
                  name="Channel"
                  :rules="{ regex: /^[1-2]+$/ }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.channel"
                    size="sm"
                    placeholder="Edit Channel"
                  ></b-form-input
                  ><label for="sb-step">Channel</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Swiss TAL"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.SwissTAL"
                    size="sm"
                    placeholder="Edit Swiss TAL"
                  ></b-form-input
                  ><label for="sb-step">Swiss TAL</label>
                </ValidationProvider>

               
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Global TAL"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.gtal"
                    size="sm"
                    placeholder="Edit Global TAL"
                  ></b-form-input
                  ><label for="sb-step">Global TAL</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Account Segment"
                  :rules="{ regex: /^[a-zA-Z0-9/\s/]+$/ }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.accountSegment"
                    size="sm"
                    placeholder="Edit Account Segment"
                  ></b-form-input
                  ><label for="sb-step">Account Segment</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="GCSP"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.gcsp"
                    size="sm"
                    placeholder="Edit GCSP"
                  ></b-form-input>
                  <label for="sb-step">GCSP</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="UltimateParentName"
                  :rules="{
                    required: true,
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.uparentname"
                    size="sm"
                    placeholder="Edit Ultimate Parent Name"
                  ></b-form-input>
                  <label for="sb-step">Ultimate Parent Name</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="UDUNS Number"
                  :rules="{ required: true, regex: /^[0-9]+$/ }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.uduns"
                    size="sm"
                    placeholder="Edit UDUNS"
                  ></b-form-input>
                  <label for="sb-step">UDUNS Number</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Notes"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="clientdetails.notes"
                    size="sm"
                    placeholder="Edit Notes"
                  ></b-form-input>
                  <label for="sb-step">Notes</label>
                </ValidationProvider>
              </div>
              <b-row>
                <b-col></b-col>
                <b-col cols="12" md="auto">
                  <b-button
                    variant="warning"
                    squared
                    @click="submitClientEdit()"
                    :disabled="invalid"
                    v-b-toggle.sidebar-client-edit
                    >Save Changes</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </b-sidebar>

    <!-- client add sidebar 
    <b-sidebar
      id="sidebar-client-add"
      title="Add Client"
      width="30%"
      right
      no-header-close
      bg-variant="dark"
      text-variant="light"
    >
      <template #default="{ hide }">
        <b-button
          squared
          aria-label="Close Button"
          id="button3"
          size="sm"
          @click="hide"
          >Close</b-button
        >
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <div class="px-3 py-2">
              <br />
              <div>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Entity ID"
                  :rules="{ required: true, regex: /^[0-9]+$/ }"
                >
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    id="entityIDadd"
                    v-model="entityID"
                    size="sm"
                    placeholder="Edit Entity ID"
                    type="text"
                  ></b-form-input>
                </ValidationProvider>
                <label for="entityIDadd">Entity ID</label>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Entity Name"
                  :rules="{
                    required: true,
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="entityname"
                    size="sm"
                    placeholder="Edit Entity Name"
                  ></b-form-input
                  ><label for="sb-step">Entity Name</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="State / Province"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="stateProvice"
                    size="sm"
                    placeholder="Edit State / Provice"
                  ></b-form-input
                  ><label for="sb-step">State / Province</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="City"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="city"
                    size="sm"
                    placeholder="Edit City"
                  ></b-form-input
                  ><label for="sb-step">City</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Channel"
                  :rules="{ regex: /^[1-2]+$/ }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="channel"
                    size="sm"
                    placeholder="Edit Channel"
                  ></b-form-input
                  ><label for="sb-step">Channel</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Swiss TAL"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="swissTAL"
                    size="sm"
                    placeholder="Edit Swiss TAL"
                  ></b-form-input
                  ><label for="sb-step">Swiss TAL</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Previous TAL"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="talOld"
                    size="sm"
                    placeholder="Edit Previous TAL"
                  ></b-form-input
                  ><label for="sb-step">Previous TAL</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Global TAL"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="gtal"
                    size="sm"
                    placeholder="Edit Global TAL"
                  ></b-form-input
                  ><label for="sb-step">Global TAL</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Account Segment"
                  :rules="{ regex: /^[a-zA-Z0-9/\s/]+$/ }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="accountSegment"
                    size="sm"
                    placeholder="Edit Account Segment"
                  ></b-form-input
                  ><label for="sb-step">Account Segment</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="GCSP"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="gcsp"
                    size="sm"
                    placeholder="Edit GCSP"
                  ></b-form-input>
                  <label for="sb-step">GCSP</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="DUNS Number"
                  :rules="{
                    regex: /^[0-9]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="duns"
                    size="sm"
                    placeholder="Edit DUNS"
                  ></b-form-input>
                  <label for="sb-step">DUNS</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="UltimateParentName"
                  :rules="{
                    required: true,
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="uparentname"
                    size="sm"
                    placeholder="Edit Ultimate Parent Name"
                  ></b-form-input>
                  <label for="sb-step">Ultimate Parent Name</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="UDUNS Number"
                  :rules="{ required: true, regex: /^[0-9]+$/ }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="uduns"
                    size="sm"
                    placeholder="Edit UDUNS"
                  ></b-form-input>
                  <label for="sb-step">UDUNS Number</label>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Notes"
                  :rules="{
                    regex:
                      /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                  }"
                >
                  <br />
                  <span id="errorSpan">{{ errors[0] }}</span>
                  <b-form-input
                    v-model="notes"
                    size="sm"
                    placeholder="Edit Notes"
                  ></b-form-input>
                  <label for="sb-step">Notes</label>
                </ValidationProvider>
              </div>
              <b-row>
                <b-col></b-col>
                <b-col cols="12" md="auto">
                  <b-button
                    variant="warning"
                    squared
                    @click="submitClientAdd()"
                    :disabled="invalid"
                    v-b-toggle.sidebar-client-add
                    >Save Changes</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </b-sidebar>-->

    <!-- client details sidebar -->
    <b-sidebar
      id="sidebar-client-detail"
      title="Client Details"
      width="70%"
      right
      no-header-close
      bg-variant="light"
      text-variant="dark"
    >
      <b-button
        squared
        aria-label="Close Button"
        id="close_button"
        size="sm"
        @click="openSidebar"
        >Close</b-button
      >
      <div class="px-3 py-5">
        <h5>{{ clientdetails.entityname }}</h5>
        <br />
        <p>
          Below are additional information blocks regarding
          {{ clientdetails.entityname }}. Keep in mind the below is for quick
          data gathering and may not be fully completed or accurate as the
          current application is still in
          <b-badge variant="danger">BETA</b-badge> development
        </p>
        <h5>Get the latest News</h5>
        <b-button squared variant="dark" size="sm" @click="gotoInfoGator()"
          >InfoGator</b-button
        >
        <br />
        <br />
        <h5>Request a data change</h5>
        <div>
          <b-button
            v-b-toggle.collapse-1
            variant="dark btn-remove-border"
            size="sm"
            squared
            >Data change</b-button
          >
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
              <b-form id="changedata" @reset="onReset">
                <b-row class="my-1">
                  <b-col sm="3">
                    <label for="email" class="labelsidebar"
                      >User Name<span id="label">*</span></label
                    >
                  </b-col>
                  <b-col sm="9">
                    <b-form-input
                      readonly
                      id="changedata.name"
                      v-model="this.getUserName"
                      type="text"
                      size="sm"
                      required
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col sm="3">
                    <label for="email" class="labelsidebar"
                      >Your EY email<span id="label">*</span></label
                    >
                  </b-col>
                  <b-col sm="9">
                    <b-form-input
                      readonly
                      id="changedata.email"
                      v-model="this.getEmailAddress"
                      type="email"
                      size="sm"
                      required
                    ></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col sm="3">
                    <label for="field" class="labelsidebar"
                      >Field to change<span id="label">*</span></label
                    >
                  </b-col>
                  <b-col sm="9">
                    <b-form-select
                      id="changedata.field"
                      v-model="changedata.field"
                      :options="options"
                      size="sm"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label for="newvalue" class="labelsidebar"
                        >New value<span id="label">*</span></label
                      >
                    </b-col>
                    <b-col sm="9">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="New Value"
                        :rules="{
                          required: true,
                          regex:
                            /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                        }"
                      >
                        <span id="errorSpan">{{ errors[0] }}</span>
                        <b-form-input
                          id="changedata.newvalue"
                          v-model="changedata.newvalue"
                          placeholder="Enter the new value"
                          size="sm"
                          required
                        ></b-form-input>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row class="my-1">
                    <b-col sm="3">
                      <label for="datachangedetails" class="labelsidebar"
                        >Details<span id="label">*</span></label
                      >
                    </b-col>
                    <b-col sm="9">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Details"
                        :rules="{
                          required: true,
                          regex:
                            /^[a-zA-Z0-9áÁàÀâÂäÄãÃéÉèÈêÊëíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕúÚùÙûÛüÜýÝÿçÇß().,' &\\ \-]+$/,
                        }"
                      >
                        <span id="errorSpan">{{ errors[0] }}</span>
                        <b-form-textarea
                          id="changedata.datachangedetails"
                          v-model="changedata.datachangedetails"
                          size="sm"
                          placeholder="Enter any additional data..."
                        ></b-form-textarea>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-button
                    size="sm"
                    type="submit"
                    variant="warning"
                    v-b-toggle.collapse-1
                    @click="Submit()"
                    :disabled="invalid"
                    >Submit</b-button
                  >
                  <b-button
                    size="sm"
                    id="button4"
                    type="reset"
                    variant="outline-danger btn-remove-border"
                    @click="onReset"
                    >Reset</b-button
                  ></ValidationObserver
                >
              </b-form>
            </b-card>
          </b-collapse>
        </div>
        <br />
        <br />
        <h5>Engagements</h5>
        <vue-good-table
          :columns="colengagement"
          :rows="getengsum"
          :pagination-options="{
            enabled: true,
            perPage: 5,
            perPageDropdown: [5, 10, 20],
          }"
          :sort-options="{
            enabled: true,
          }"
          compactMode
        />
        <br />
        <br />
        <h5>Registered opportunities</h5>
        <vue-good-table
          :columns="colopportunity"
          :rows="getopportunity"
          :pagination-options="{
            enabled: true,
            perPage: 5,
            perPageDropdown: [5, 10, 20],
          }"
          :sort-options="{
            enabled: true,
          }"
          compactMode
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  GET_CLIENTS,
  UPDATE_CLIENT,
  LOAD_STATUS_CLIENTS,
  POST_CLIENT,
  GET_CLIENT_BY_ID,
  GET_CLIENTS_BY_UDUNS,
  LOAD_STATUS_CLIENTS_NO_TAL,
  LOAD_STATUS_CLIENTS_BY_NAME,
  LOAD_STATUS_UPARENTS_BY_SwissTAL,
} from "@/store/actions/clients";
import { POST_CHANGES } from "@/store/actions/admin";
import {
  GET_ENGAGEMENTS,
  GET_ENGAGEMENTS_BY_ID,
  LOAD_STATUS_UPARENTS_BY_ENGAGEMENT_ID,
} from "@/store/actions/engagements";
import { GET_OPPORTUNITIES } from "@/store/actions/opportunities";
import {
  GET_UPARENTS_NO_TAL,
  LOAD_STATUS_UPARENTS,
  LOAD_STATUS_UPARENTS_NO_TAL,
  GET_UPARENTS,
  GET_UPARENTS_BY_NAME,
  LOAD_STATUS_UPARENTS_BY_NAME,
  GET_UPARENTS_BY_GTAL,
} from "@/store/actions/uparent";

import decode from "jsonwebtoken/decode";
const jwt = require("jsonwebtoken");

import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import { BDropdownItem } from "bootstrap-vue";
import axios from "axios";

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

export default {
  name: "clients",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      detailsOpen: false,
      isActive: false,
      modalShow: false,
      showAlert: false,
      showAlertEdit: false,
      showAlertAdd: false,
      perPage: 40,
      currentPage: 1,
      sortBy: "Account",
      filter: null,
      filterGTAL: null,
      filterSwissTAL: null,
      filterClientName: null,
      filterEngagementID: null,
      entityname: null,
      entityID: null,
      accountSegment: null,
      channel: null,
      gcsp: null,
      stateProvice: null,
      swissTAL: null,
      talOld: null,
      city: null,
      duns: null,
      uparentname: null,
      uduns: null,
      notes: null,
      link: null,
      gtal: null,
      totalRowsNoTal: 1,
      totalRows: 1,
      // INFO: Data change value in sidebar
      changedata: {
        email: "",
        datachangedetails: "",
        newvalue: "",
        field: null,
      },
      options: [
        { value: null, text: "Select field to change" },
        { value: "entityname", text: "Entity Name" },
        { value: "swisstal", text: "Swiss TAL" },
        { value: "gcsp", text: "GCSP" },
        { value: "gtal", text: "GTAL" },
      ],
      clientdetails: {
        entityname: null,
        entityID: null,
        accountSegment: null,
        channel: null,
        gcsp: null,
        stateProvice: null,
        SwissTAL: null,
        talOld: null,
        city: null,
        duns: null,
        uparentname: null,
        uduns: null,
        notes: null,
        link: null,
        gtal: null,
      },
      colengagement: [
        {
          label: "Engagement ID",
          field: "EngagementID",
        },
        {
          label: "Engagement",
          field: "Project",
        },
        {
          label: "Engagement Manager",
          field: "EngagementManager",
        },
        {
          label: "Engagement Partner",
          field: "EngagementPartner",
        },
        {
          label: "Engagement Status",
          field: "EngagementStatus",
        },
        {
          label: "Release Date",
          field: "ReleaseDate",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss", // expects 2018-03-16
          dateOutputFormat: "dd.MM.yyyy", // outputs Mar 16th 2018
        },
        {
          label: "SSL",
          field: "EngagementSubServiceLine",
        },
        {
          label: "Engagement Service",
          field: "EngagementServiceOffering",
        },

        {
          label: "Currency",
          field: "CurrencyCd",
        },
        {
          label: "Planned TER",
          field: "Planned_TER",
          tdClass: "text-right",
        },
      ],
      colopportunity: [
        {
          label: "Opportunity ID",
          field: "OpportunityId",
        },
        {
          label: "Opportunity",
          field: "OpportunityNm",
        },
        {
          label: "Opportunity Partner",
          field: "OpportunityPartner",
        },
        {
          label: "Outcome",
          field: "StatusNm",
        },
  

        {
          label: "SSL",
          field: "EngagementSubServiceLine",
        },
        {
          label: "Created Date",
          field: "CreatedDt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss", // expects 2018-03-16
          dateOutputFormat: "dd.MM.yyyy", // outputs Mar 16th 2018
        },
        {
          label: "Won Date",
          field: "OpportunityWonDt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss", // expects 2018-03-16
          dateOutputFormat: "dd.MM.yyyy", // outputs Mar 16th 2018
        },
        {
          label: "Value of potential sale in CHF",
          field: "ExpectedAmt",
          tdClass: "text-right",
        },
      ],
      columns: [
        {
          label: "Ultimate Parent",
          key: "Account",
          sortable: true,
        },

        {
          label: "HQ Country",
          key: "HQCountry",
          sortable: true,
        },
        {
          label: "UDUNS",
          key: "UDUNS",
          sortable: true,
        },

        {
          label: "Global TAL",
          key: "GTAL",
          width: "120px",
          sortable: true,
        },
        {
          label: "GCSP",
          key: "GCSP",
          width: "120px",
          sortable: true,
        }
      
      ],
      columnsClients: [
        {
          label: "Entity Name",
          key: "Client",
          sortable: true,
        },
        {
          label: "Entity ID",
          key: "ClientID",
          sortable: true,
        },
        {
          label: "HQ Coutry",
          key: "HQCoutry",
          sortable: true,
        },
        {
          label: "Swiss TAL",
          key: "SwissTal",
          width: "120px",
          sortable: true,
        },

        {
          label: "Global TAL",
          key: "GTAL",
          width: "120px",
          sortable: true,
        },
        {
          label: "UDUNS",
          key: "AccountID",
          width: "120px",
          sortable: true,
        },

        {
          label: "GCSP",
          key: "GCSP",
          width: "120px",
          sortable: true,
        },

        {
          label: "",
          key: "edit",
        },
      ],
      columnsEngagementSearch: [
        {
          label: "Ultimate Parent",
          key: "Account",
          sortable: true,
        },
        {
          label: "Channel",
          key: "AccountChannel",
          sortable: true,
        },
        {
          label: "Account Segment",
          key: "AccountSubSegment",
          sortable: true,
        },
        {
          label: "EngagementPartner",
          key: "EngagementPartner",
          sortable: true,
        },
        {
          label: "SSL",
          key: "EngagementSubServiceLine",
          sortable: true,
        },
        {
          label: "Engagement Status",
          key: "EngagementStatus",
          sortable: true,
        },
        {
          label: "Currency",
          key: "CurrencyCd",
          sortable: true,
        },
        {
          label: "Value YTD",
          key: "FYTD_TERAmt",
          sortable: true,
        },
      ],
      /* excel_fields: {
        EntityName: "EntityName",

      }, */
      clientslist: [],
      rowengagement: [],
      rowopportunity: [],
      child: [],
    };
  },
  mounted() {
    // Set the initial number of items
    var rows = this.$store.getters.getuparents;
    this.totalRows = rows.length;

    var rowsNoTal = this.$store.getters.getUparentsNoTal;

    this.totalRowsNoTal = rowsNoTal.length;
  },

  methods: {
    showClientDetail(item) {
      // params contains the row information

      this.$store.dispatch(GET_ENGAGEMENTS, item.ClientID);
      this.$store.dispatch(GET_OPPORTUNITIES, item.ClientID);
      this.$store.dispatch(GET_CLIENT_BY_ID, item.ClientID);

      this.$root.$emit("bv::toggle::collapse", "sidebar-client-detail");
      this.clientdetails.entityname = item.Client;
      this.clientdetails.entityID = item.ClientID;

      this.clientdetails.link =
        "https://eyinfogator.infodesk.com/company/snapshot?customer=ey&q=" +
        item.UDUNS +
        ":DUNS&page=news&newsCategory=major";
      if (this.detailsOpen === false) {
        this.detailsOpen = true;
      } else {
        this.detailsOpen = false;
      }
    },

    showClientDetailENG(item) {
      // params contains the row information

      this.$store.dispatch(GET_ENGAGEMENTS, item.ClientID);
      this.$store.dispatch(GET_OPPORTUNITIES, item.ClientID);

      this.$root.$emit("bv::toggle::collapse", "sidebar-client-detail");
      this.clientdetails.entityname = item.Client;
      this.clientdetails.entityID = item.ClientID;

      this.clientdetails.link =
        "https://eyinfogator.infodesk.com/company/snapshot?customer=ey&q=" +
        item.UDUNS +
        ":DUNS&page=news&newsCategory=major";
      if (this.detailsOpen === false) {
        this.detailsOpen = true;
      } else {
        this.detailsOpen = false;
      }
    },

    seachAllClientsEngagements() {
      this.$store.dispatch(GET_ENGAGEMENTS_BY_ID, this.filterEngagementID);
      this.$store.dispatch("LOAD_STATUS_UPARENTS");
    },

    showClientEdit(item) {
      this.$root.$emit("bv::toggle::collapse", "sidebar-client-edit");
      this.clientdetails.entityname = item.Client;
      this.clientdetails.entityID = item.ClientID;
      this.clientdetails.channel = item.Channel;
      this.clientdetails.gcsp = item.GCSP;
      this.clientdetails.stateProvice = item.HQCoutry;
      this.clientdetails.SwissTAL = item.SwissTAL;
      this.clientdetails.talOld = item.TALOLD;
      this.clientdetails.notActive = item.NotActive;
      this.clientdetails.uduns = item.AccountID;
      this.clientdetails.uparentname = item.Account;
      this.clientdetails.gtal = item.GTAL;
    },
    showClientAdd() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-client-add");
    },
    Submit() {
      event.preventDefault();

      var clientinformation = this.$store.getters.getClientByID;

      if (this.changedata.field === "swisstal") {
        var oldfieldvalue = clientinformation.SwissTal;
      } else if (this.changedata.field === "entityname") {
        var oldfieldvalue = clientinformation.EntityName;
      } else if (this.changedata.field === "gtal") {
        var oldfieldvalue = clientinformationGTAL;
      } else if (this.changedata.field === "gcsp") {
        var oldfieldvalue = clientinformation.GCSP;
      }
      // oldfieldvalue must by of type string; cannot be null
      if (oldfieldvalue === null) {
        oldfieldvalue = "";
      }

      let payload = {
        email: this.getEmailAddress,

        clientid: this.clientdetails.entityID,
        clientname: this.clientdetails.entityname,
        changefield: this.changedata.field,
        oldfieldvalue: oldfieldvalue,
        newfieldvalue: this.changedata.newvalue,
        changedetails: this.changedata.datachangedetails,
        active: true,
        approved: false,
      };


      this.$store.dispatch(POST_CHANGES, payload);

      this.changedata.email = "";
      this.changedata.newvalue = "";
      this.changedata.field = null;
      this.changedata.datachangedetails = "";
      this.showAlert = true;
    },
    //send notification mail to responsible person
    //use Post request to send email via power automate
    // {{ this.changedata.email }} add to Mail
    sendMail() {
      axios({
        method: "post",
        url: "https://prod-93.westeurope.logic.azure.com:443/workflows/da35111092c0438bbc5b795b0f4e1c0b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bZP939uwcEUpNcC0nJ0xrS-CQXi8x632Ma_4TmgMgcU",
        data: {
          to: "sabina.scherle@ch.ey.com",
          subject: "TAL Tool: A user sent a change request",
          body: "A user sent a change request.",
        },
      });
    },
    onReset(event) {
      event.preventDefault();
      // Reset form values
      this.changedata.email = "";
      this.changedata.newvalue = "";
      this.changedata.field = null;
      this.changedata.datachangedetails = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    editFunc() {
      if (this.isAdmin === 401) {
        hidden = true;
      }
    },
    submitClientEdit() {
      var payload = {
        EntityName: this.clientdetails.entityname,
        EntityID: this.clientdetails.entityID,
        AccountSegment: this.clientdetails.accountSegment,
        Channel: this.clientdetails.channel,
        GCSP: this.clientdetails.gcsp,
        StateProvince: this.clientdetails.stateProvice,
        SwissTal: this.clientdetails.SwissTAL,
        TALOLD: this.clientdetails.talOld,
        StateProvince: this.clientdetails.stateProvice,
        NotActive: this.clientdetails.notActive,
        UltimateParentName: this.clientdetails.uparentname,
        UDUNS: this.clientdetails.uduns,
        Notes: this.clientdetails.notes,
        city: this.clientdetails.city,
        GTAL: this.clientdetails.gtal,
      };

      this.$store.dispatch(UPDATE_CLIENT, payload);
      this.showAlertEdit = true;
    },
    submitClientAdd() {
      var payload = {
        EntityName: this.entityname,
        EntityID: this.entityID,
        AccountSegment: this.accountSegment,
        Channel: this.channel,
        GCSP: this.gcsp,
        StateProvince: this.stateProvice,
        SwissTAL: this.swissTAL,
        TALOLD: this.talOld,
        StateProvince: this.stateProvice,
        NotActive: this.notActive,
        UltimateParentName: this.uparentname,
        UDUNS: this.uduns,
        Notes: this.notes,
        city: this.city,
        GTAL: this.gtal,
      };

      this.$store.dispatch(POST_CLIENT, payload);

      this.clientdetails.entityname = null;
      this.clientdetails.entityID = null;
      this.clientdetails.accountSegment = null;
      this.clientdetails.channel = null;
      this.clientdetails.gcsp = null;
      this.clientdetails.stateProvice = null;
      this.clientdetails.swissTAL = null;
      this.clientdetails.talOld = null;
      this.clientdetails.stateProvice = null;
      this.clientdetails.notActive = null;
      this.clientdetails.uparentname = null;
      this.clientdetails.uduns = null;
      this.clientdetails.notes = null;
      this.clientdetails.city = null;
      this.clientdetails.gtal = null;

      this.showAlertAdd = true;
    },
    gotoInfoGator() {
      window.open(this.clientdetails.link);
    },
    refreshTable() {
      this.$store.dispatch(LOAD_STATUS_UPARENTS);
      this.$store.dispatch(GET_UPARENTS);
    },
    refreshTableNoTAL() {
      this.$store.dispatch(LOAD_STATUS_UPARENTS);
      this.$store.dispatch(GET_UPARENTS_NO_TAL);
    },
    showClientsNoTAL() {
      this.$store.dispatch(LOAD_STATUS_UPARENTS);
      this.$store.dispatch(GET_UPARENTS_NO_TAL);
    },
    showClientsTAL() {
      this.$store.dispatch(LOAD_STATUS_UPARENTS);
      this.$store.dispatch(LOAD_STATUS_UPARENTS_NO_TAL);
      this.$store.dispatch(GET_UPARENTS);
    },
    showChildren(item) {
      this.$store.dispatch("GET_CLIENTS_BY_UDUNS", item.AccountID);
      
    },
    showChildrenENG(item) {
      this.$store.dispatch("GET_CLIENTS_BY_UDUNS", item.AccountID);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    seachAllClients() {
      this.$store.dispatch("GET_UPARENTS_BY_NAME", this.filter);
      this.getuparents = this.$store.getters.getUparentsByName;
      this.$store.dispatch("LOAD_STATUS_UPARENTS");
    },
    seachAllClientsGTAL() {
      this.$store.dispatch("GET_UPARENTS_BY_GTAL", this.filterGTAL);
      this.getuparents = this.$store.getters.getuparentsByGTal;
      this.$store.dispatch("LOAD_STATUS_UPARENTS");
    },
    seachAllClientsSwissTAL() {
      this.$store.dispatch("GET_UPARENTS_BY_SWISSTAL", this.filterSwissTAL);
      this.getuparents = this.$store.getters.getupartensBySwissTAL;
      this.$store.dispatch("LOAD_STATUS_UPARENTS");
    },
    seachAllClientsByName() {
      this.$store.dispatch("GET_UPARENTS_BY_ClientName", this.filterClientName);
      this.getuparents = this.$store.getters.getupartensByClientName;
      this.$store.dispatch("LOAD_STATUS_UPARENTS");
    },
    clearSearch() {
      this.filter = null;
      this.filterGTAL = null;
      this.filterSwissTAL = null;
      this.filterEngagementID = null;
      this.filterClientName = null;
      this.$store.dispatch("GET_UPARENTS");
      this.$store.dispatch("LOAD_STATUS_UPARENTS_BY_NAME");
      this.$store.dispatch("LOAD_STATUS_UPARENTS_NO_TAL");
      this.$store.dispatch("LOAD_STATUS_UPARENTS_BY_GTAL");
      this.$store.dispatch("LOAD_STATUS_UPARENTS_BY_SwissTAL");
      this.$store.dispatch("LOAD_STATUS_UPARENTS_BY_ClientName");
    },

    openSidebar() {
      if (this.detailsOpen === true) {

        this.$root.$emit("bv::toggle::collapse", "sidebar-client-detail");
        this.detailsOpen = false;
        this.isActive = false;

      } else {
        this.isActive = false;
      }
    },
    disableButton() {
      this.isActive = true;
    },
  },

  computed: {
    getengsum() {
      return this.$store.getters.getengagements;
    },
    getopportunity() {
      return this.$store.getters.getopportunities;
    },
 getEmailAddress() {
      var token = sessionStorage.getItem("adal.idtoken");
       var decoded = jwt.decode(token, { complete: true });
       return decoded.payload.unique_name;
       
     },
     getUserName() {
     var token = sessionStorage.getItem("adal.idtoken");
       var decoded = jwt.decode(token, { complete: true });

       return decoded.payload.name;
     
     }, 
    isAdmin() {
      return this.$store.getters.getError;
    },
    getClientsList() {
      return this.showChildren;
    },
    getClientsListNoTAL() {
      return this.$store.getters.getclientsNoTal;
    },
    getUParentsStatus() {
      return this.$store.getters.uparentsloadingstate;
    },
    getUParentsNoTalStatus() {
      return this.$store.getters.uparentsNoTalLoadedState;
    },
    getuparents() {
      return this.$store.getters.getuparents;
    },
    getClientByUDUNS() {

      return this.$store.getters.getClientByUDUNS;
    },
    rows() {
      return this.$store.getters.getuparents.length;
    },
    getuparentsListNoTAL() {
      return this.$store.getters.getUparentsNoTal;
    },

    getUparentsByName() {
      return this.$store.getters.getUparentsByName;
    },
    getuparentsloadedName() {
      return this.$store.getters.getuparentsloadedName;
    },
    getuparentsloaded() {
      return this.$store.getters.uparentsloadingstate;
    },
    getuparentsByGTalLoaded() {
      return this.$store.getters.getuparentsByGTalLoaded;
    },
    getuparentsByGTal() {
      return this.$store.getters.getuparentsByGTal;
    },
    getuparentsBySwissTALLoaded() {
      return this.$store.getters.getupartensBySwissTALLoaded;
    },
    getuparentsBySwissTAL() {
      return this.$store.getters.getupartensBySwissTAL;
    },
    getuparentsByClientNameLoaded() {
      return this.$store.getters.getupartensByClientNameLoaded;
    },
    getuparentsByClientName() {
      return this.$store.getters.getupartensByClientName;
    },
  },
};
</script>

<style>
#button {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 2%;
}
#button2 {
  margin-left: 2%;
  margin-right: 0%;
  margin-top: 2%;
}
#button4 {
  margin-left: 10px;
}
#spinner {
  margin-left: 10%;
  margin-right: 0%;
  margin-top: 2%;
}
#form_group {
  margin-right: 1%;
  margin-left: 1%;
}
#button3 {
  margin-top: 10px;
  margin-right: 35px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffe600;
  color: black;
}
#errorSpan {
  color: #b9251c;
}
#close_button {
  margin-top: 10px;
  margin-right: 35px;
  position: absolute;
  top: 0;
  right: 0;
}
#label {
  color: #ff0000;
}
</style>
