<template>
    <apexchart id="ytdAccountMargin"  type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  data: function() {
    return {
        
      options: {
        chart: {
          id: 'vuechart-example'
        },
        legend:{
            show:true,
            position: 'bottom'
        },
        xaxis: {
          categories: ["Switzerland Geo"]
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + " %";
            },
          },
        },
      },
      series: [{
        name: 'Margin YTD %',
        data: [33.1]
      },
      {
        name: 'Margin ytd % pts growth',
        data: [1.4]
      }
      ],
     
    
    }
  },

     mounted(){
       
          var values = this.$store.getters.getengagementsMarginGrowthPY
          
    var ter = [];
    var margin = [];

    for (var i = 0; i < values.length; i++) {
      
      ter.push(values[i].MarginPercent);
      margin.push(values[i].GrowthToPY)
    }

    
    this.series[0].data = ter;
    this.series[1].data = margin;
         }
         
  
};
</script>

<style>
#ytdAccountMargin{
  height: 700;

}
</style>