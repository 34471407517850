import engagements from "./engagements";
import clients from "./clients";
import opportunities from "./opportunities";

import admin from "./admin"
import uparent from "./uparent";







const repositories = {
    engagements: engagements,
    clients: clients,
    opportunities: opportunities,

    admin: admin,
    uparent: uparent,
 
   

    
    


    
};

export default {
    get: name => repositories[name]
};