<template>
  <apexchart
    id="arAgingOver150"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data: function () {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: ['Blum, Oliver',	'Nutsubidze, Ioseb','Muller, Aurélien',	'Suter, Benno',	'Casals, Virginie',	'Hildebrandt-Stürmer, Silke'
],
        },
      },
      series: [
        {
          name: "Total Amount by Engagement Partner in CHF",
          data: [43649.35,	15000,	6011.78,	4250,	4223.61,	2574
],
        },
      ],
    };
  },

  /*mounted() {
    var values = this.$store.getters.getengagementsAROver150ByEngPartner;

    var cat = [];

    var plan = [];
    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].EngagementPartner);
      plan.push(parseFloat((values[i].totalARAmount / 1000).toFixed(1)));
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = plan;
  },*/
};
</script>

<style>
#pipelinewins {
  height: 460;
}
</style>