// INFO: import necessary api factory
import {
  GET_CLIENTS,
  UPDATE_CLIENT,
  GET_CLIENT,
  LOAD_STATUS_CLIENTS,
  POST_CLIENT,
  GET_CLIENTS_NO_TAL,
  LOAD_STATUS_CLIENTS_NO_TAL,
  GET_CLIENT_BY_ID,
  GET_CLIENTS_BY_UDUNS,
  GET_CLIENTS_BY_NAME,
  LOAD_STATUS_CLIENTS_BY_NAME
} from "../actions/clients";
import apiFactory from "../../services/api.factory";
const clients = apiFactory.get("clients");

// INFO: Here we declare the stores available for this 'accounts' store
const state = {
  clientsloaded: false,
  clientsNoTalLoaded: false,
  clientsloadedUDUNS: false,
  clientsloadedName: false,
  clients: [],
  clientsNoTal: [],
  clientByID: [],
  clientsByUDUNS: [],
  clientByName: []
};
// INFO: getters are to compute a state (derivation)
const getters = {
  clientsloadingstate: state => {
    return state.clientsloaded;
  },
  clientsNoTalLoadedState: state =>{
    return state.clientsNoTalLoaded;
  },
  getclients: state => {
    return state.clients;
  },
  getclientsNoTal: state =>{
    return state.clientsNoTal;
  },

  getclientdetail: state => id => {
    return state.clientByUDUNS.find(client => client.ClientID === id)
  },

 getClientByID : state => {
    return state.clientByID;
  },
  getClientByUDUNS: state => {
    return state.clientsByUDUNS
  },
  getClientByName : state => {
    return state.clientByName;
  },
  getclientsloadedUDUNS: state =>{
    return state.clientsloadedUDUNS
  },
  getclientsloadedName: state =>{
    return state.clientsloadedName
  }
};

// INFO: Actions do not mutate directly a state instead they commit a mutation. Actions can contain async activities
const actions = {
  [GET_CLIENTS]: async ({ commit, dispatch }, pageNumber) => {
    await clients.get(pageNumber).then(resp => {
      commit("SET_CLIENTS", resp.data);
    });
  },
  [GET_CLIENTS_NO_TAL]: async ({ commit } ) => {
    await clients.getNoTAL().then(resp => {
      commit("SET_CLIENTS_NO_TAL", resp.data);
    });
  },

  [UPDATE_CLIENT]: async ({ commit }, payload) => {
    await clients.put(payload);
  },

  [POST_CLIENT]: async ({ commit }, payload) => {
    await clients.post(payload);
  },

  [LOAD_STATUS_CLIENTS]: async ({ commit }) => {
    commit("SET_LOAD_STATUS");
  },

  [LOAD_STATUS_CLIENTS_NO_TAL]: async ({ commit }) => {
    commit("SET_LOAD_STATUS_NO_TAL");
  },

  [GET_CLIENT_BY_ID]: async({commit}, ID)=>{
    await clients.getClientByID(ID).then(resp =>{
      commit("SET_CLIENT_BY_ID", resp.data);
    })
  },

  [GET_CLIENTS_BY_UDUNS]: async({commit}, UDUNS)=>{
    await clients.getClientsByUDUNS(UDUNS).then(resp =>{
      
      commit("SET_CLIENT_BY_UDUNS", resp.data);
    })
  },
  [GET_CLIENTS_BY_NAME]: async({commit}, name)=>{
    await clients.getClientsByName(name).then(resp =>{
      commit("SET_CLIENT_BY_NAME", resp.data);
    })
  },
  [LOAD_STATUS_CLIENTS_BY_NAME]: async ({ commit }) => {
    commit("SET_LOAD_STATUS_CLIENTS_BY_NAME");
  },
};

// INFO: Mutations are the only way to change the state
const mutations = {
  SET_CLIENTS(state, payload) {
    state.clientsloaded = true;
    state.clients = payload;
  },
  SET_LOAD_STATUS(state) {
    state.clientsloaded = false;
  },
  SET_CLIENTS_NO_TAL(state, payload){
    state.clientsloaded = true;
    state.clientsNoTalLoaded = true;
    state.clientsNoTal = payload;
  },
  SET_LOAD_STATUS_NO_TAL(state) {
    state.clientsNoTalLoaded = false;
  },
  SET_CLIENT_BY_ID(state, payload){
    state.clientsloadedID = true;
    state.clientByID = payload;
  },
  SET_CLIENT_BY_NAME(state, payload){
    state.clientsloadedName = true;
    state.clientByName = payload;
  },
  SET_LOAD_STATUS_CLIENTS_BY_NAME(state){
    state.clientsloadedName = false;
  },
  SET_CLIENT_BY_UDUNS(state, payload){
    state.clientsloadedName = true;
    state.clientsByUDUNS = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
