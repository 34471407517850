<template>
  <div>
   
    <div class="container-fluid bg-white mw-100 mx-auto my-2">


      <feedback />
    </div>
    </div>
</template>

<script>
import feedback from "@/components/Feedback.vue";

export default {
  name: "Feedback",
  components: {
    feedback,
  },
};
</script>
