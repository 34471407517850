// INFO: import necessary api factory
import {
  GET_OPPORTUNITIES,
  GET_PIPELINE,
  GET_WINS,
  GET_PIELINE_GROWTH,
  GET_WINS_GROWTH,
  GET_PIPELINE_WINS_BY_SSL,
  GET_COUNT_OPP_BY_STAGE,
  GET_PIELINE_BY_STAGE,
  GET_PIELINE_BY_SSL_COMP,
  GET_PIELINE_BY_PARTNER,
  GET_WINS_BY_SSL,
  GET_WINS_BY_DELIVERY_REGION,
  GET_OPEN_BY_SSL,
  GET_LOSS_BY_REGION,
  GET_TOP_25_OPEN,
  GET_TOP_25_WON,
  GET_TOP_25_LOST,
  GET_WEIGHTED_PIPELINE,
  GET_WEIGHTED_PIPELINE_GROWTH

} from "../actions/opportunities";
import apiFactory from '../../services/api.factory';
const opportunities = apiFactory.get('opportunities');


// INFO: Here we declare the stores available for this 'accounts' store
const state = {
  opportunitiesloaded: false,
  opportunities: [],
  pipeline: 0,
  wins: 0,
  pielineGrowth: 0,
  winGrowth: 0,
  pielineWinsBySSl: [],
  countOppByStage: [],
  pielineByStage: [],
  pipelineBySSLComp: [],
  pielineByPartner: [],
  winsBySSL: [],
  WinsBySSLLoaded: false,
  winsByDeliveryRegion: [],
  openBySSL: [],
  openBySSLLoaded: false,
  lossByRegion: [],
  top25Open: [],
  top25Won: [],
  top25Lost: [],
  weightedPipeline: 0,
  weightedPipelineGrowth: 0,
  top25LostLoaded: false,
  pielineByStageLoaded: false,
  pielineWinsBySSlLoaded: false
};
// INFO: getters are to compute a state (derivation)
const getters = {
  opportunitiesloadingstate: state => {
    return state.opportunitiesloaded;

  },
  getopportunities: state => {
    return state.opportunities.opportunities;
  },
  getpipeline: state => {
    return state.pipeline.pipeline;
  },
  getwins: state => {
    return state.wins.wins;
  },
  getpielineGrowth: state => {
    return state.pielineGrowth;
  },
  getwinGrowth: state => {
    return state.winGrowth.winsGrowth;
  },
  getpielineWinsBySSl: state => {
    return state.pielineWinsBySSl.finalArray;
  },
  getcountOppByStage: state => {
    return state.countOppByStage.oppByUDUNS;
  },
  getpielineByStage: state => {
    return state.pielineByStage.oppByUDUNS;
  },
  getpipelineBySSLComp: state => {
    return state.pipelineBySSLComp.oppByUDUNS;
  },
  getpielineByPartner: state => {
    return state.pielineByPartner.oppByPartner;
  },
  getwinsBySSL: state => {
    return state.winsBySSL.oppByUDUNS;
  },
  getWinsBySSLLoaded: state => {
    return state.WinsBySSLLoaded
  },
  getwinsByREgion: state => {
    return state.winsByRegion;
  },
  getlossByRegion: state => {
    return state.lossByRegion;
  },
  getopenBySSL: state => {
    return state.openBySSL.oppByUDUNS;
  },
  getopenBySSLLoaded: state => {
    return state.openBySSLLoaded
  },
  gettop25Open: state => {
    return state.top25Open.uniqueOppObj;
  },
  gettop25Won: state => {
    return state.top25Won.uniqueOppObj;
  },
  gettop25Lost: state => {
    return state.top25Lost.uniqueOppObj;
  },
  getweightedPipeline: state => {
    return state.weightedPipeline.weightedPipeline
  },
  getweightedPipelineGrowth: state => {
    return state.weightedPipelineGrowth.weightedPipelieGrowth
  },
  gettop25LostLoaded: state => {
    return state.top25LostLoaded
  },
  getpielineByStageLoaded: state =>{
    return state.pielineByStageLoaded
  },
  getpielineWinsBySSlLoaded: state =>{
    return state.pielineWinsBySSlLoaded
  }



};

// INFO: Actions do not mutate directly a state instead they commit a mutation. Actions can contain async activities
const actions = {
  [GET_OPPORTUNITIES]: async ({ commit, dispatch }, ClientID) => {
    await opportunities.get(ClientID).then(resp => {
      commit('SET_OPPORTUNITIES', resp.data);
    });
  },

  [GET_PIPELINE]: async ({ commit, dispatch }) => {
    await opportunities.getPipeline().then(resp => {
      commit('SET_GET_PIPELINE', resp.data);
    });
  },

  [GET_WINS]: async ({ commit, dispatch }) => {
    await opportunities.getWins().then(resp => {
      commit('SET_GET_WINS', resp.data);
    });
  },
  [GET_PIELINE_GROWTH]: async ({ commit, dispatch }) => {
    await opportunities.getPipelineGrowth().then(resp => {
      commit('SET_GET_PIELINE_GROWTH', resp.data);
    });
  },
  [GET_WINS_GROWTH]: async ({ commit, dispatch }) => {
    await opportunities.getWinGrowth().then(resp => {
      commit('SET_GET_WINS_GROWTH', resp.data);
    });
  },
  [GET_PIPELINE_WINS_BY_SSL]: async ({ commit, dispatch }) => {
    await opportunities.getPipelineWinsBySSL().then(resp => {
      commit('SET_GET_PIPELINE_WINS_BY_SSL', resp.data);
    });
  },
  [GET_COUNT_OPP_BY_STAGE]: async ({ commit, dispatch }) => {
    await opportunities.getCountOppByStage().then(resp => {
      commit('SET_GET_COUNT_OPP_BY_STAGE', resp.data);
    });
  },
  [GET_PIELINE_BY_STAGE]: async ({ commit, dispatch }) => {
    await opportunities.getPipelineByStage().then(resp => {
      commit('SET_GET_PIELINE_BY_STAGE', resp.data);
    });
  },
  [GET_PIELINE_BY_SSL_COMP]: async ({ commit, dispatch }) => {
    await opportunities.getPipelineBySSLComp().then(resp => {
      commit('SET_GET_PIELINE_BY_SSL_COMP', resp.data);
    });
  },
  [GET_PIELINE_BY_PARTNER]: async ({ commit, dispatch }) => {
    await opportunities.getPipelineByPartner().then(resp => {
      commit('SET_GET_PIELINE_BY_PARTNER', resp.data);
    });
  },
  [GET_WINS_BY_SSL]: async ({ commit, dispatch }) => {
    await opportunities.getWinBySSL().then(resp => {
      commit('SET_GET_WINS_BY_SSL', resp.data);
    });
  },
  [GET_WINS_BY_DELIVERY_REGION]: async ({ commit, dispatch }) => {
    await opportunities.getWinByDeliveryRegion().then(resp => {
      commit('SET_GET_WINS_BY_DELIVERY_REGION', resp.data);
    });
  },
  [GET_OPEN_BY_SSL]: async ({ commit, dispatch }) => {
    await opportunities.getOpenBySSL().then(resp => {
      commit('SET_GET_OPEN_BY_SSL', resp.data);
    });
  },

  [GET_LOSS_BY_REGION]: async ({ commit, dispatch }) => {
    await opportunities.getLossByRegion().then(resp => {
      commit('SET_GET_LOSS_BY_REGION', resp.data);
    });
  },
  [GET_TOP_25_OPEN]: async ({ commit, dispatch }) => {
    await opportunities.getTop25Open().then(resp => {
      commit('SET_GET_TOP_25_OPEN', resp.data);
    });
  },
  [GET_TOP_25_WON]: async ({ commit, dispatch }) => {
    await opportunities.getTop25Won().then(resp => {
      commit('SET_GET_TOP_25_WON', resp.data);
    });
  },
  [GET_TOP_25_LOST]: async ({ commit, dispatch }) => {
    await opportunities.getTop25Lost().then(resp => {
      commit('SET_GET_TOP_25_LOST', resp.data);
    });
  },
  [GET_WEIGHTED_PIPELINE]: async ({ commit, dispatch }) => {
    await opportunities.getweightedPipeline().then(resp => {
      commit('SET_WEIGHTED_PIPELINE', resp.data);
    });
  },
  [GET_WEIGHTED_PIPELINE_GROWTH]: async ({ commit, dispatch }) => {
    await opportunities.getweightedPipelineGrowth().then(resp => {
      commit('SET_WEIGHTED_PIPELINE_GROWTH', resp.data);
    });
  },



};


// INFO: Mutations are the only way to change the state
const mutations = {
  SET_OPPORTUNITIES(state, payload) {
    state.opportunitiesloaded = true;
    state.opportunities = payload;
  },
  SET_GET_PIPELINE(state, payload) {
    
    state.pipeline = payload;
  },
  SET_GET_WINS(state, payload) {
    state.wins = payload;
  },
  SET_GET_PIELINE_GROWTH(state, payload) {
    state.pielineGrowth = payload;
  },
  SET_GET_WINS_GROWTH(state, payload) {
    state.winGrowth = payload;
  },
  SET_GET_PIPELINE_WINS_BY_SSL(state, payload) {
    state.pielineWinsBySSl = payload;
    state.pielineWinsBySSlLoaded = true;
  },
  SET_GET_COUNT_OPP_BY_STAGE(state, payload) {
    state.countOppByStage = payload;
  },
  SET_GET_PIELINE_BY_STAGE(state, payload) {
    state.pielineByStage = payload;
    state.pielineByStageLoaded = true;
  },
  SET_GET_PIELINE_BY_SSL_COMP(state, payload) {
    state.pipelineBySSLComp = payload;
  },
  SET_GET_PIELINE_BY_PARTNER(state, payload) {
    state.pielineByPartner = payload;
  },
  SET_GET_WINS_BY_SSL(state, payload) {
    state.WinsBySSLLoaded = true;
    state.winsBySSL = payload;
  },
  SET_GET_WINS_BY_DELIVERY_REGION(state, payload) {
    state.winsByRegion = payload;
  },
  SET_GET_OPEN_BY_SSL(state, payload) {
    state.openBySSL = payload;
    state.openBySSLLoaded = true;
  },
  SET_GET_LOSS_BY_REGION(state, payload) {
    state.lossByRegion = payload;
  },
  SET_GET_TOP_25_OPEN(state, payload) {
    state.top25Open = payload;
  },
  
  SET_GET_TOP_25_WON(state, payload) {
    state.top25Won = payload;
  },
  
  SET_GET_TOP_25_LOST(state, payload) {
    state.top25Lost = payload;
    state.top25LostLoaded = true;
  },
  SET_WEIGHTED_PIPELINE(state, payload) {
    state.weightedPipeline = payload;
  },
  SET_WEIGHTED_PIPELINE_GROWTH(state, payload) {

    state.weightedPipelineGrowth = payload;
  }

  

};

export default {
  state,
  getters,
  actions,
  mutations,
};