import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './scss/global.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import authentication from './authentication/index_msal'

// import table component
// https://xaksis.github.io/vue-good-table/
import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css';
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';
import JsonExcel from "vue-json-excel";


//require('./scss/global.scss')

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Vue Good table
Vue.use(VueGoodTablePlugin);
// Vue ApexCharts
Vue.use(VueApexCharts); 
Vue.component('apexchart', VueApexCharts);
Vue.component("downloadExcel", JsonExcel);


Vue.config.productionTip = false;

const token = sessionStorage.getItem('adal.idtoken')



if (token){
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}



authentication.initialize().then(_=>{
  
  new Vue({
    router,
    store,
    render: (h) => h(App),
    
}).$mount('#app');
})
