import api from "./api.services";



const tenantid = sessionStorage.getItem('adal.idtoken');

const resource = "/adminchanges";
const resourceEngagement = "/adminEngagementTracking";
const config = { headers: { Authorization: tenantid } }

export default {
  get() {
    return api.get(`${resource}`, config);
  },
  put(payload) {
    return api.post(`${resource}` + '/post', { data: payload }, config)
  },
  post(payload) {
    return api.post(`${resource}`, { data: payload }, config)
  },
  getEngagementTracking() {
    return api.get(`${resourceEngagement}`, config)
  },
  getEngagementTrackingActionRequired() {
    return api.get('/adminEngagementTracking/actionRequired', config)
  },
  getTrackingByClient(name) {
    const params = new URLSearchParams([['ClientName', name]])
    return api.get("/adminEngagementTracking/ByClient", { params }, config)
  },
  getTrackingClientByUduns(ClientID) {
    const params = new URLSearchParams([['ClientID', ClientID]])
    return api.get('/engagements', { params }, config);
  },
  getTrackingClientBySwissTal(tal) {
    const params = new URLSearchParams([['SwissTal', tal]])
    return api.get('/adminEngagementTracking/bySwissTal', { params }, config);
  },
  getClientsNoEngagement(skip) {
    const params = new URLSearchParams([['skip', skip]])
    return api.get("/adminEngagementTracking/ClientsNoEngagement", { params }, config)
  },

  getOpportunitiesForTracking() {
    return api.get("/adminOpportunityTracking", config)
  },
  getUparentsNoOpportunity(skip) {
    const params = new URLSearchParams([['skip', skip]])
    return api.get("/adminEngagementTracking/uparentNoOpportunity",{ params }, config)
  },
  getTrackingClientByUdunsOpportunity(uduns) {
    const params = new URLSearchParams([['UDUNS', uduns]])
    return api.get("/adminOpportunityTracking/client", { params }, config)
  },
  getTrackingOpportunityByName(name){
    const params = new URLSearchParams([['ClientName', name]])
    return api.get("/adminEngagementTracking/OpportunitybyName", {params}, config)
  },
  getTrackingOpportunityByTAL(tal){
    const params = new URLSearchParams([['SwissTal', tal]])
    return api.get("/adminEngagementTracking/OpportunitybySwissTAL", {params}, config)
  },
  getOpportunityTrackingActionRequired() {
    return api.get('/adminEngagementTracking/OpportunityActionRequired', config)
  },
};