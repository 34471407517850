import api from "./api.services";

const tenantid = sessionStorage.getItem('adal.idtoken');
const resource = "/engagements";
const config = { headers: { Authorization: 'Bearer ' + tenantid } }

export default {
  get(ClientID) {
    const params = new URLSearchParams([['ClientID', ClientID]])
    return api.get(`${resource}`, { params }, config);
  },
  getByEngagement(EngagementID) {
    const params = new URLSearchParams([['EngagementID', EngagementID]])
    return api.get('/engagements/engagementID', { params }, config);
  },
  getGTERytd() {
    return api.get('/gter/gterFYTD', config);
  },
  getGTERGrowth() {
    return api.get('/gter/growth', config);
  },
  getGTERPlanned() {
    return api.get('/terplanned', config);
  },
  getGTERvsPlanned() {
    return api.get('/terVSplanned', config);
  },
  getGTERMarginBySSL() {
    return api.get('/TERMarginBySSL', config);
  },
  getChargedHoursByCompetency() {
    return api.get('/engagements/chargedHoursByCompetency', config);
  },
  getMarginGrowthPY(){
    return api.get('/engagements/MarginGrwothPY', config);
  },
  getTopTenAccounts(){
    return api.get('/TopTenAccounts', config);
  },
  getMargin(){
    return api.get('/margin/marginFYTD', config);
  },
  getMarginPercent(){
    return api.get('/margin/growth', config);
  },
  getSSLPenetration(){
    return api.get('/SSLPenetration', config);
  },
  getARbelow90(){
    return api.get('/ARbelow90', config);
  },
  getARbetween90_150(){
    return api.get('/AR91_150', config);
  },
  getARover151(){
    return api.get('/ARabove150', config);
  },
  getARtotal(){
    return api.get('/ARtotal', config);
  },
  getARover150byEngPartner(){
    return api.get('/engagements/ARover150byEngPartner', config);
  },
  getARbyEngPartner(){
    return api.get('/engagements/ARbyEngPartner', config);
  },
  


};
