<template>
  <b-container class="container-fluid bg-white mw-100 px-12 mx-auto">
    <br>
    <h4 class="h2 m-0 pb-5">Your applications</h4>
    <b-card-group deck id="card_group">

      <b-card no-body class="overflow-hidden" id="card" >
        <b-row no-gutters>
          <b-col md="4" class="text-center my-auto">
            <i class="material-icons md-75" id="i" aria-hidden="true">contacts</i>
          </b-col>
          <b-col md="8">
            <b-card-header id="card_header" >TAL Management</b-card-header>
            <b-card-body>
              <b-card-text id="card_text" >Find all relevant information around the Swiss TALs, accounts without TALs, opportunities, engagements and events.</b-card-text>
              <b-button id="button" v-if="getClientsStatus === true" variant="outline-dark btn-remove-border" to="ultimateParents" >Take me to TAL Management</b-button>
              <b-spinner id="spinner" v-else small label="Small Spinner"   ></b-spinner>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="overflow-hidden" id="card">
        <b-row no-gutters>
          <b-col md="4" class="text-center my-auto" >
            <i id="i2" class="material-icons md-75"  aria-hidden="true">star_rate</i>
          </b-col>
          <b-col md="8">
            <b-card-header id="card_header" >Information</b-card-header>
            <b-card-body>
              <b-card-text id="card_text">Find all information for performing TALs, such as TAL 2.0, G360 Hub, Tax Solutions, Market Accelerator and much more.</b-card-text>
              <b-button variant="outline-dark btn-remove-border" to="Information" id="button">Take me to Information</b-button>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </b-container>
</template>

<script>
export default {
  name: "HomeIcons",
  computed:{
     getClientsStatus() {
      return this.$store.getters.uparentsloadingstate;
    },
  }
};
</script>

<style>
#card_group {align-content: center;}
#card {max-width: 540px; margin: 0 auto; float: none; margin-bottom: 10px;}
#i {margin-left: auto;margin-right: auto;width: 40%; font-size: 10vmin}
#card_header {font-size: 3.2vmin; background-color: white !important; border: none;}
#card_text {font-size: 2.2vmin; margin-top: -2%;}
#button {font-size: 2.2vmin}
#spinner {margin-left:40%; margin-right: 0%; margin-top: 5%}
#i2 {margin-left: auto;margin-right: auto;width: 40%; font-display: block; font-size: 11vmin}

</style>
