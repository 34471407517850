<template>
  <apexchart
    id="GTERvsPlan"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data: function () {
    return {
      gtervsplandataloaded: false,
      options: {
        chart: {
          id: "vuechart-example",
        },
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value ;
            },
          },
        },
      },
      series: [
        {
          name: "Total Planned GTER Constant in MCHF",
          data: [],
        },
        {
          name: "Total GTER YTD Constant in MCHF",
          data: [],
        },
      ],
    };
  },
 mounted() {
    var values = this.$store.getters.getengagementsGTERvsvPlanned;

    var cat = [];
    var gter = [];
    var plan = [];
    for (var i = 0; i < values.length; i++) {
     var catValue = values[i].AccountSubSegment
     cat.push(catValue)
     var gterValue = values[i].FYTD_TERAmt
     gterValue = (gterValue /1000000).toFixed(2)
     gter.push(gterValue)
     var planValue = values[i].Planned_TER
     planValue = (planValue / 1000000).toFixed(2)
     plan.push(planValue)
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = plan;
    this.series[1].data = gter;

  },
};
</script>

<style>
#GTERvsPlan {
  height: 460;
}
</style>