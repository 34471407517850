<template>
  <div>

    <div class="container-fluid bg-white mw-100 mx-auto my-2">


      <ultimateParents />
    </div>
    </div>
  
</template>

<script>
import ultimateParents from "@/components/talmanagement/ultimateParents"

export default {
  name: "TalmanagementUltimateParents",
  components: {
    ultimateParents,
  },
  computed:{
      engagementslist() {
      // INFO: get transactions through a getters instead of calling the state
      // INFO: using a getter returns an array ready to use while using the state returns an object
      // this.isBusy = true
      //return this.$store.getters.gettransactions;
    },
  }
};
</script>
