import api from "./api.services";

const tenantid = sessionStorage.getItem('adal.idtoken');
const resource = "/clients";
const config = {headers: { Authorization: 'Bearer ' + tenantid}}
export default {

  get() {
    return api.get(`${resource}`,{
     config
});
  },

  put(payload) {
    return api.post("/client/post", {data:payload}, config)
  },
  post(payload){
    return api.post("/client", {data:payload}, config)
  },
  getNoTAL(){
    return api.get("/clients/noTAL", config)
  },
  getClientByID(ID){
    const params = new URLSearchParams([['ID', ID ]])
    return api.get("/client",{params} , config)
  },
  getClientsByUDUNS(UDUNS){
    const params = new URLSearchParams([['UDUNS', UDUNS ]])
    return api.get("/clients/uduns",{params} , config)
  },
  getClientsByName(name){
    const params = new URLSearchParams([['EntityName', name ]])
    return api.get("/clients/ByName",{params} , config)
  }
};
