<template>
  <!-- eslint-disable max-len -->

  <div class="home">
    <!--Header Picture block-->
    <div class="container-fluid bg-secondary mw-100 mx-auto p-0">
      <div class="ey-banner-wrapper">
        <div class="ey-img-box-home"></div>
        <div class="ey-img-overlay"></div>
        <div class="ey-text-box">
          <p class="text-white h1 font-weight-normal m-0">Swiss TAL Management</p>
        </div>
      </div>
    </div>

    <!--Empty spacer block-->
    <div class="container-fluid bg-dark mw-100 m-0"  id="container_fluid_l"></div>
    <!-- Header Block -->
    <div class="container-fluid bg-dark text-white mw-100 px-12 mx-auto">
      <div class="row align-items-center">
        <div class="col text-left text-yellow">
          <p class="h1 m-0 pb-5">You are not authorized to access here. </p>
        </div>
      </div>

      <div class="row d-flex justify-content-end">
        <div class="col-6">
         
        </div>
        <div class="col">
        </div>
      </div>
    </div>
    <!--Empty spacer block-->
    <div class="container-fluid bg-dark mw-100 m-0"  id="container_fluid_l"></div>
    <!--Empty spacer block-->
    <div class="container-fluid bg-white mw-100 m-0"  id="container_fluid_l"></div>

    <div class="container-fluid bg-white mw-100 m-0"  id="container_fluid_l"></div>

  </div>
</template>

<script>


export default {
  name: 'NotAdmin',
  components: {
    
  },
};
</script>
<style>

#container_fluid_l {min-height: 40px;}

</style>
