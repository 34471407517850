<template>
    <div class="footer__group">
        <img
        alt="EY Logo"
        class="footer__image"
        src="../../resources/images/ey-logo-with-tagline.svg">
        <p class="footer__parag"><i class="material-icons">copyright</i>&nbsp;
            <span class="eyfontlight">2022</span>
             EYGM Limited | All Rights Reserved.
            <br> Content within this application is confidential and meant for EY staff
            only. </p><div v-if="isAdmin === 0"><b-link to="/admin">Admin</b-link></div> 
    </div>
</template>

<script>

export default {
  name: 'eyfooter',
methods:{
  getChanges(){
    window.location.href="/admin"
  
}},
computed:{
  isAdmin(){

    return this.$store.getters.getError;
  }
}
};
</script>
