// INFO: import necessary api factory
import {
    GET_CHANGES,
    POST_CHANGES,
    PUT_CHANGES,
    POST_CHANGES_ENGAGEMENT_TRACKING,
    GET_CHANGES_ENGAGEMENT_TRACKING,
    GET_TRACKING_BY_NAME,
    LOAD_STATUS_TRACKING_BY_NAME,
    GET_CLIENT_BY_UDUNS,
    GET_CLIENT_BY_UDUNS_OPPORTUNITY,
    GET_CLIENT_NO_ENGAGEMENT,
    GET_OPPORTUNITY_FOR_TRACKING,
    GET_UPARENT_NO_OPPORTUNITY,
    LOAD_STATUS_UPARENT_NO_OPPORTUNITY,
    GET_ENGAGEMENT_TRACKING_ACTION_REQUIRED,
    LOAD_STATUS_TRACKING_CLIENT,
    LOAD_STATUS_TRACKING_CLIENT_OPPORTUNITY,
    GET_TRACKING_BY_SWISS_TAL,
    LOAD_STATUS_TRACKING_BY_SWISS_TAL,
    GET_TRACKING_OPPORTUNITY_BY_NAME,
    LOAD_STATUS_OPPORTUNITY_BY_NAME,
    LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL,
    GET_TRACKING_OPPORTUNITY_BY_SWISS_TAL,
    GET_OPPORTUNITY_TRACKING_ACTION_REQUIRED,
    LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED
} from "../actions/admin";
import apiFactory from '../../services/api.factory';
const admin = apiFactory.get('admin');


// INFO: Here we declare the stores available for this 'accounts' store
const state = {
    changesloaded: false,
    changes: [],
    newChanges: [],
    InactiveChanges: [],
    isAdmin: false,
    error: 0,
    engagementTrackingList: [],
    engagementTrackingListActionRequried: [],
    trackingListLoaded: false,
    trackingListLoadedActionRequired: false,
    trackingListByUParentLoaded: false,
    trakingListByUparent: [],
    trackingClientList: [],
    trackingClientListOpportunity: [],
    trackingClientListLoadedOpportunity: false,
    trackingOpportunityLoaded: false,
    trackingClientListLoaded: false,
    trackingListClientNoEngagement: [],
    trackingListClientNoEngagementLoaded: false,
    opportunitiesForTracking: [],
    trackingOpportunityLoaded: false,
    trackingListUparentNoOpportunity: [],
    trackingListUparentNoOpportunityLoaded: false,
    trackingListBySwissTal: [],
    trackingListBySwissTalLoaded: false,
    trackingOpportunityByName: [],
    trackingOpportunityByNameLoaded: false,
    trackingOpportunityBySwissTalLoaded: false,
    trackingOpportunityBySwissTal: [],
    opportunityTrackingListActionRequried: [],
    opportunitytrackingListLoadedActionRequired: false
};
// INFO: getters are to compute a state (derivation)
const getters = {
    getactivechanges: state => {
        return state.changes.filter(change => change.active === true);
    },
    getinactivechanges: state => {
        return state.changes.filter(change => change.active === false);
    },
    verifyadmin: state => {
        return state.changes
    },
    getChanges: state => {
        return state.changes
    },
    getAdmin: state => {
        return state.isAdmin
    },
    getError: state => {
        return state.error
    },
    getEngagementTrackingList: state => {
        return state.engagementTrackingList
    },
    getengagementTrackingListActionRequried: state => {
        return state.engagementTrackingListActionRequried
    },
    getTrackingListLoaded: state => {
        return state.trackingListLoaded
    },
    gettrackingListLoadedActionRequired: state => {
        return state.trackingListLoadedActionRequired
    },
    gettrackingListByUParentLoaded: state => {
        return state.trackingListByUParentLoaded
    },
    gettrackingListByUParent: state => {
        return state.trakingListByUparent
    },
    gettrackingClientList: state => {
        return state.trackingClientList
    },
    gettrackingClientListLoaded: state => {
        return state.trackingClientListLoaded
    },
    gettrackingClientListOpportunity: state => {
        return state.trackingClientListOpportunity
    },
    gettrackingClientListLoadedOpportunity: state => {
        return state.trackingClientListLoadedOpportunity
    },
    gettrackingListClientNoEngagement: state => {
        return state.trackingListClientNoEngagement
    },
    gettrackingListClientNoEngagementLoaded: state => {
        return state.trackingListClientNoEngagementLoaded
    },
    getopportunitiesForTracking: state => {
        return state.opportunitiesForTracking
    },
    gettrackingOpportunityLoaded: state => {
        return state.trackingOpportunityLoaded
    },
    gettrackingListUparentNoOpportunity: state => {
        return state.trackingListUparentNoOpportunity
    },
    gettrackingListUparentNoOpportunityLoaded: state => {
        return state.trackingListUparentNoOpportunityLoaded
    },
    gettrackingListBySwissTal: state => {
        return state.trackingListBySwissTal
    },
    gettrackingListBySwissTalLoaded: state => {

        return state.trackingListBySwissTalLoaded
    },
    gettrackingOpportunityByName: state => {
        return state.trackingOpportunityByName
    },
    gettrackingOpportunityByNameLoaded: state => {
        return state.trackingOpportunityByNameLoaded
    },
    gettrackingOpportunityBySwissTalLoaded: state => {
        return state.trackingOpportunityBySwissTalLoaded
    },
    gettrackingOpportunityBySwissTal: state => {
        return state.trackingOpportunityBySwissTal
    },
    getopportunityTrackingListActionRequried: state => {
        return state.opportunityTrackingListActionRequried
    },
    getopportunitytrackingListLoadedActionRequired: state => {
        return state.opportunitytrackingListLoadedActionRequired
    }
    

};

// INFO: Actions do not mutate directly a state instead they commit a mutation. Actions can contain async activities
const actions = {
    [GET_CHANGES]: async ({ commit }) => {

        await admin.get()

            .then(
                resp => {
                    commit('SET_CHANGES', resp.data,)



                })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.status)
                    if (error.response.status === 401) {
                        console.log(error.response.status)
                        commit('SET_ERROR', error.response.status);
                    }
                }
            });

    },

    [POST_CHANGES]: async ({ commit }, payload) => {
        await admin.post(payload)
            .then(
                resp => {
                    commit('SET_CHANGES', resp.data)
                });
    },
    [PUT_CHANGES]: async ({ commit }, payload) => {
        await admin.put(payload).then(admin.get()

            .then(
                resp => {
                    commit('SET_CHANGES', resp.data);


                }))
    },

    [GET_CHANGES_ENGAGEMENT_TRACKING]: async ({ commit }) => {

        await admin.getEngagementTracking()

            .then(
                resp => {
                    commit('SET_ENGAGEMENT_TRACKING', resp.data,)
                })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.status)
                    if (error.response.status === 401) {
                        console.log(error.response.status)
                        commit('SET_ERROR', error.response.status);
                    }
                }
            });
    },

    [GET_ENGAGEMENT_TRACKING_ACTION_REQUIRED]: async ({ commit }) => {

        await admin.getEngagementTrackingActionRequired()

            .then(
                resp => {
                    commit('SET_ENGAGEMENT_TRACKING_ACTION_REQUIRED', resp.data,)
                })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.status)
                    if (error.response.status === 401) {
                        console.log(error.response.status)
                        commit('SET_ERROR', error.response.status);
                    }
                }
            });
    },
    [GET_TRACKING_BY_NAME]: async ({ commit }, name) => {
        await admin.getTrackingByClient(name).then(resp => {
            commit("SET_TRACKING_BY_NAME", resp.data)
        })
    },
    [LOAD_STATUS_TRACKING_BY_NAME]: async ({ commit }) => {
        commit("SET_STATUS_TRACKING_BY_NAME");
    },
    [LOAD_STATUS_TRACKING_CLIENT]: async ({ commit }) => {
        commit("SET_STATUS_LOAD_TRACKING_CLIENT");
    },
    [GET_CLIENT_BY_UDUNS]: async ({ commit }, uduns) => {
        await admin.getTrackingClientByUduns(uduns).then(resp => {
            commit("SET_TRACKING_CLIENT", resp.data)
        })
    },
    [GET_CLIENT_BY_UDUNS_OPPORTUNITY]: async ({ commit }, uduns) => {
        await admin.getTrackingClientByUdunsOpportunity(uduns).then(resp => {
            commit("SET_TRACKING_CLIENT_OPPORTUNITY", resp.data)
        })
    },
    [LOAD_STATUS_TRACKING_CLIENT_OPPORTUNITY]: async ({ commit }) => {
        commit("SET_STATUS_LOAD_TRACKING_CLIENT_OPPORTUNITY");
    },
    [GET_CLIENT_NO_ENGAGEMENT]: async ({ commit }, skip) => {
        await admin.getClientsNoEngagement(skip).then(resp => {
            commit("SET_CLIENT_NO_ENGAGEMENT", resp.data)
        });
    },
    [GET_OPPORTUNITY_FOR_TRACKING]: async ({ commit }) => {
        await admin.getOpportunitiesForTracking().then(resp => {
            commit("SET_OPPORTUNITY_FOR_TRACKING", resp.data)
        });
    },
    [GET_UPARENT_NO_OPPORTUNITY]: async ({ commit }, skip) => {
        await admin.getUparentsNoOpportunity(skip).then(resp => {
            commit("SET_UPARENT_NO_OPPORTUNITY", resp.data)
        });
    },
    [GET_TRACKING_BY_SWISS_TAL]: async ({ commit }, tal) => {
        await admin.getTrackingClientBySwissTal(tal).then(resp => {
            commit("SET_TRACKING_BY_SWISS_TAL", resp.data)
        })
    },
    [LOAD_STATUS_TRACKING_BY_SWISS_TAL]: async ({ commit }) => {
        commit("SET_LOAD_STATUS_TRACKING_BY_SWISS_TAL");
    },
    [LOAD_STATUS_UPARENT_NO_OPPORTUNITY]: async ({ commit }) => {
        commit("SET_LOAD_STATUS_UPARENT_NO_OPPORTUNITY")
    },
    [GET_TRACKING_OPPORTUNITY_BY_NAME]: async ({ commit }, name) => {
        await admin.getTrackingOpportunityByName(name).then(resp => commit("SET_TRACKING_OPPORTUNITY_BY_NAME", resp.data))
    },
    [LOAD_STATUS_OPPORTUNITY_BY_NAME]: async({commit}) => {
        commit("SET_LOAD_STATUS_OPPORTUNITY_BY_NAME")
    },
    [LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL]: async({commit}) => {
        commit("SET_LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL")
    },
    [GET_TRACKING_OPPORTUNITY_BY_SWISS_TAL]: async ({commit}, tal)=>{
        await admin.getTrackingOpportunityByTAL(tal).then(resp => 
            commit("SET_TRACKING_OPPORTUNITY_BY_SWISS_TAL", resp.data)
        )
    },
    [GET_OPPORTUNITY_TRACKING_ACTION_REQUIRED]: async ({commit})=>{
        await admin.getOpportunityTrackingActionRequired().then(resp => 
            commit("SET_OPPORTUNITY_TRACKING_ACTION_REQUIRED", resp.data))
    },
    [LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED]: async({commit})=>{
        commit("SET_LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED")
    }



};

// INFO: Mutations are the only way to change the state
const mutations = {
    SET_CHANGES(state, payload) {
        state.changesloaded = true;
        state.changes = payload;
        state.isAdmin = true;
    },
    SET_NEWCHANGES(state, payload) {
        state.changesloaded = true;
        state.newChanges = payload;
    },
    SET_INACTIVE(state, payload) {
        state.changesloaded = true;
        state.InactiveChanges = payload;
    },
    SET_ADMIN(state) {
        state.isAdmin = true
    },
    SET_ERROR(state) {
        state.error = 401
    },
    SET_ENGAGEMENT_TRACKING(state, payload) {
        state.trackingListLoaded = true;
        state.engagementTrackingList = payload;

    },
    SET_ENGAGEMENT_TRACKING_ACTION_REQUIRED(state, payload) {
        state.trackingListLoadedActionRequired = true;
        state.engagementTrackingListActionRequried = payload;
    },
    SET_TRACKING_BY_NAME(state, payload) {
        state.trackingListByUParentLoaded = true;
        state.trakingListByUparent = payload;
    },
    SET_STATUS_TRACKING_BY_NAME(state) {
        state.trackingListByUParentLoaded = false;
    },
    SET_TRACKING_CLIENT(state, payload) {
        state.trackingClientList = payload;
        state.trackingClientListLoaded = true;
    },
    SET_STATUS_LOAD_TRACKING_CLIENT(state) {
        state.trackingClientListLoaded = false;
    },
    SET_CLIENT_NO_ENGAGEMENT(state, payload) {
        state.trackingListClientNoEngagement = payload,
            state.trackingListClientNoEngagementLoaded = true;
    },
    SET_OPPORTUNITY_FOR_TRACKING(state, payload) {
        state.opportunitiesForTracking = payload;
        state.trackingOpportunityLoaded = true;

    },
    SET_UPARENT_NO_OPPORTUNITY(state, payload) {
        state.trackingListUparentNoOpportunity = payload,
            state.trackingListUparentNoOpportunityLoaded = true;
    },
    SET_TRACKING_CLIENT_OPPORTUNITY(state, payload) {
        state.trackingClientListOpportunity = payload;
        state.trackingClientListLoadedOpportunity = true;
    },
    SET_STATUS_LOAD_TRACKING_CLIENT_OPPORTUNITY(state) {
        state.trackingClientListLoadedOpportunity = false;
    },
    SET_TRACKING_BY_SWISS_TAL(state, payload) {
        state.trackingListBySwissTalLoaded = true;
        state.trackingListBySwissTal = payload;

    },
    SET_LOAD_STATUS_TRACKING_BY_SWISS_TAL(state) {
        state.trackingListBySwissTalLoaded = false;
    },
    SET_LOAD_STATUS_UPARENT_NO_OPPORTUNITY(state) {
        state.trackingListUparentNoOpportunityLoaded = false;
    },
    SET_TRACKING_OPPORTUNITY_BY_NAME(state, payload) {
        state.trackingOpportunityByName = payload;
        state.trackingOpportunityByNameLoaded = true;
    },
    SET_LOAD_STATUS_OPPORTUNITY_BY_NAME(state){
        state.trackingOpportunityByNameLoaded = false;
    },
    SET_LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL(state){
        state.trackingOpportunityBySwissTalLoaded = false;
    },
    SET_TRACKING_OPPORTUNITY_BY_SWISS_TAL(state, payload){
        state.trackingOpportunityBySwissTalLoaded = true;
        state.trackingOpportunityBySwissTal = payload;
    },
    SET_OPPORTUNITY_TRACKING_ACTION_REQUIRED(state, payload){
        state.opportunityTrackingListActionRequried = payload,
        state.opportunitytrackingListLoadedActionRequired = true
    },
    SET_LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED(state){
        state.opportunitytrackingListLoadedActionRequired = false
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};