<template>
    <apexchart id="OppsByStage"  type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  data: function() {
    return {
     options: {
        chart: {
          id: "vuechart-example",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Number of Opportunities by Stage",
          data: [],
        },
      ],
    }
  },

  
 mounted() {
    var values = this.$store.getters.getcountOppByStage;
    

    var cat = [];

    var plan = [];
    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].SalesPhaseNm);
      plan.push (values[i].Value);
    
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };
         
    this.series[0].data = plan;
  },
  
};
</script>

<style>
#OppsByStage{
  width: 600;
}
</style>