<template>
  <div>
    <div class="container-fluid bg-white mw-100 px-12 mx-auto">
      <h2>Dashboard</h2>
      <div
        class="container-fluid bg-white mw-100 m-0"
        id="container_fluid_m"
      ></div>

      <div id="overview_cards">
        <b-card-group deck>
          <b-card
            title="GTER YTD (CHF)"
            bg-variant="light"
            border-variant="primary"
          >
            <br />
            <b-card-text>
              <h2>{{ getGTER }} M</h2>
            </b-card-text>
          </b-card>

          <b-card
            title="Open Opportunities (CHF)"
            bg-variant="light"
            border-variant="primary"
          >
            <br />
            <b-card-text>
              <h2>{{ getPipeline }} M</h2>
            </b-card-text>
          </b-card>
        </b-card-group>
        <div
          class="container-fluid bg-white mw-100 m-0"
          id="container_fluid_m"
        ></div>

        <b-card-group deck>
          <b-card
            title="Margin YTD (CHF)"
            bg-variant="light"
            border-variant="primary"
          >
            <br />
            <b-card-text>
              <h2>{{ getMargin }} M</h2>
            </b-card-text>
          </b-card>

          <b-card
            title="Wins YTD (CHF)"
            bg-variant="light"
            border-variant="primary"
          >
            <br />
            <b-card-text>
              <h2>{{ getWins }} M</h2>
            </b-card-text>
          </b-card>

          <b-card
            title="Wins YTD growth % on PY"
            bg-variant="light"
            border-variant="primary"
          >
            <b-card-text>
              <h2>{{ getWinsGrowth }}</h2>
            </b-card-text>
          </b-card>
        </b-card-group>
        <div
          class="container-fluid bg-white mw-100 m-0"
          id="container_fluid_m"
        ></div>
      </div>

      <div
        class="text-center my-2"
        v-if="
          GTERvsPlannedLoadedGetter === false ||
          GTERplannedLoaded === false ||
          GTERMarginBySSLLoaded === false ||
          OpenBySSLLoaded === false ||
          WinsBySSLLoaded === false ||
          getpielineByStageLoaded === false ||
          getpielineWinsBySSlLoaded === false
        "
      >
        <b-spinner type="grow" class="align-middle"></b-spinner>
      </div>

      <dashboard
        v-if="
          GTERvsPlannedLoadedGetter === true &&
          GTERplannedLoaded === true &&
          GTERMarginBySSLLoaded === true &&
          OpenBySSLLoaded === true && 
          WinsBySSLLoaded === true &&
          getpielineByStageLoaded === true &&
          getpielineWinsBySSlLoaded === true
        "
      />

      <b-button @click="up()" id="buttonUp"><b-icon-arrow-up /></b-button>
    </div>
  </div>
</template>

<script>
import dashboard from "@/components/dashboard/dashboard.vue";
import {
  GET_WEIGHTED_PIPELINE,
  GET_PIELINE_BY_PARTNER,
  GET_TOP_25_OPEN,
  GET_TOP_25_WON,
  GET_TOP_25_LOST,
  GET_WINS_BY_SSL,
  GET_OPEN_BY_SSL,
  GET_PIPELINE_WINS_BY_SSL,
  GET_PIELINE_BY_STAGE,
  GET_COUNT_OPP_BY_STAGE
} from "@/store/actions/opportunities";
import {
  GET_ENGAGEMENTS_GTER_PLANNED,
  GET_ENGAGEMENTS_GTER_VS_PLANNED,
  GET_ENGAGEMENTS_GTER_MARGIN_BY_SSL,
  GET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY,
  GET_ENGAGEMENTS_MARGIN_GROWTH_PY,
  GET_ENGAGEMENTS_TOP_TEN_ACCOUNTS,
  GET_ENGAGEMENTS_GROWTH,
  GET_ENGAGEMENTS_GTER,
  GET_ENGAGEMENTS_MARGIN,
  GET_ENGAGEMENTS_MARGIN_PERCENT,
  GET_ENGAGEMENTS_SSL_PENETRATION,
  GET_ENGAGEMENTS_AR_BELOW_90,
  GET_ENGAGEMENTS_AR_OVER_150,
  GET_ENGAGEMENTS_AR_TOTAL,
  GET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER,
  GET_ENGAGEMENTS_AR_BETWEEN_90_150,
  GET_ENGAGEMENTS_AR_BY_ENG_PARTNER,
} from "@/store/actions/engagements";

export default {
  name: "Dashboard",
  components: {
    dashboard,
  },
  data() {
    return {
      GTERvsPlannedLoaded: false,
    };
  },
  mounted() {
    this.$store.dispatch(GET_PIELINE_BY_PARTNER);
    this.$store.dispatch(GET_ENGAGEMENTS_SSL_PENETRATION);
    this.$store.dispatch(GET_TOP_25_OPEN);
    this.$store.dispatch(GET_TOP_25_WON);
    this.$store.dispatch(GET_TOP_25_LOST);
    this.$store.dispatch(GET_ENGAGEMENTS_GROWTH);
    this.$store.dispatch(GET_ENGAGEMENTS_MARGIN_PERCENT);
    this.$store.dispatch(GET_ENGAGEMENTS_GTER_VS_PLANNED);
    this.$store.dispatch(GET_ENGAGEMENTS_GTER_PLANNED);
    this.$store.dispatch(GET_ENGAGEMENTS_GTER_MARGIN_BY_SSL);
    this.$store.dispatch(GET_ENGAGEMENTS_TOP_TEN_ACCOUNTS);
    this.$store.dispatch(GET_ENGAGEMENTS_AR_BELOW_90);
    this.$store.dispatch(GET_ENGAGEMENTS_AR_BETWEEN_90_150);
    this.$store.dispatch(GET_ENGAGEMENTS_AR_OVER_150);
    this.$store.dispatch(GET_ENGAGEMENTS_AR_TOTAL);
    this.$store.dispatch(GET_WEIGHTED_PIPELINE);
    this.$store.dispatch(GET_WINS_BY_SSL);
    this.$store.dispatch(GET_OPEN_BY_SSL);
    this.$store.dispatch(GET_PIPELINE_WINS_BY_SSL)
    this.$store.dispatch(GET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY);
    this.$store.dispatch(GET_PIELINE_BY_STAGE);
    this.$store.dispatch(GET_COUNT_OPP_BY_STAGE);
    
  },
  methods: {
    up() {
      window.scrollTo({ top: 0 });
    },
  },
  computed: {
    getGTERGrowth() {
      var value = this.$store.getters.getengagementsGrowth;
      value = value.toFixed(1);

      return value;
    },
    getGTER() {
      var gter = (this.$store.getters.getengagementsGTER / 1000000).toFixed(1);
      return gter;
    },
    getWins() {
      var wins = (this.$store.getters.getwins / 1000000).toFixed(1);
      return wins;
    },
    getWinsGrowth() {
      var winsgrowth = this.$store.getters.getwinGrowth;
      return winsgrowth;
    },
    getPipeline() {
      var pipeline = (this.$store.getters.getpipeline / 1000000).toFixed(1);
      return pipeline;
    },
    getMargin() {
      var margin = (this.$store.getters.getengagementsMargin / 1000000).toFixed(
        2
      );
      return margin;
    },
    getMarginPercent() {
      var marginPercent = this.$store.getters.getengagementsMarginPercent;
      marginPercent = marginPercent.toFixed(1);
      return marginPercent;
    },
    GTERvsPlannedLoadedGetter() {
      return this.$store.getters.getengagementsGTERvsPlannedLoaded;
    },
    GTERplannedLoaded() {
      return this.$store.getters.getengagementsGTERplannedLoaded;
    },
    GTERMarginBySSLLoaded() {
      return this.$store.getters.getengagementsGTERMarginBySSLLoaded;
    },
    OpenBySSLLoaded(){
      return this.$store.getters.getopenBySSLLoaded;
    },
    WinsBySSLLoaded(){
      return this.$store.getters.getWinsBySSLLoaded;
    },
    getpielineByStageLoaded(){
      return this.$store.getters.getpielineByStageLoaded;
    },
    getpielineWinsBySSlLoaded(){
      return this.$store.getters.getpielineWinsBySSlLoaded
    }
  },
};
</script>

<style>
#buttonUp {
  float: right;
}

#container_fluid_s {
  min-height: 475px;
}
#overview_cards {
  margin-left: 5%;
  margin-right: 5%;
}
</style>
