export const GET_OPPORTUNITIES = "GET_OPPORTUNITIES";
export const GET_PIPELINE = "GET_PIPELINE";
export const GET_WINS = "GET_WINS";
export const GET_PIELINE_GROWTH = "GET_PIELINE_GROWTH";
export const GET_WINS_GROWTH = "GET_WINS_GROWTH";
export const GET_PIPELINE_WINS_BY_SSL = "GET_PIPELINE_WINS_BY_SSL";
export const GET_COUNT_OPP_BY_STAGE = "GET_COUNT_OPP_BY_STAGE";
export const GET_PIELINE_BY_STAGE = "GET_PIELINE_BY_STAGE";
export const GET_PIELINE_BY_SSL_COMP = "GET_PIELINE_BY_SSL_COMP";
export const GET_PIELINE_BY_PARTNER = "GET_PIELINE_BY_PARTNER";
export const GET_WINS_BY_SSL = "GET_WINS_BY_SSL";
export const GET_WINS_BY_DELIVERY_REGION = "GET_WINS_BY_DELIVERY_REGION";
export const GET_OPEN_BY_SSL = "GET_OPEN_BY_SSL";
export const GET_LOSS_BY_REGION = "GET_LOSS_BY_REGION";
export const GET_TOP_25_OPEN = "GET_TOP_25_OPEN";
export const GET_TOP_25_WON = "GET_TOP_25_WON";
export const GET_TOP_25_LOST = "GET_TOP_25_LOST";
export const GET_WEIGHTED_PIPELINE = "GET_WEIGHTED_PIPELINEGET_OPEN_BY_SSL";
export const GET_WEIGHTED_PIPELINE_GROWTH = "GET_WEIGHTED_PIPELINE_GROWTH";