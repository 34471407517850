<template>
    <apexchart id="GTERMarginSSL" type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  data: function() {
    return {
        
      options: {
        chart: {
          id: 'GTERMarginSSL'
        },
        legend:{
            show:true,
            position: 'bottom'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        xaxis: {
          categories: []
        },
         yaxis: {
          labels: {
            formatter: function (value) {
              return value ;
            },
          },
        },
      },
      series: [
        {
        name: 'Total GTER YTD in MCHF',
        data: []
      },{
        name: 'Total Margin YTD in MCHF',
        data: []
      },
      
      ],
     
    
    }
  },

  
   mounted(){
          var values = this.$store.getters.getengagementsTopTenAccounts
         var cat = [];
    var ter = [];
    var margin = [];

    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].Account);
      ter.push((values[i].FYTD_TERAmt/1000000).toFixed(2));
      margin.push((values[i].FYTD_MarginAmt/1000000).toFixed(2))
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };
    this.series[0].data = ter.sort((a,b) => b-a);
    this.series[1].data = margin;
         }
  
};
</script>

<style>
#GTERMarginSSL{
  height: 300;
  width: 700;
}
</style>