// INFO: import necessary api factory
import {
  GET_UPARENTS,
  GET_UPARENTS_NO_TAL,
  LOAD_STATUS_UPARENTS_NO_TAL,
  LOAD_STATUS_UPARENTS,
  GET_UPARENTS_BY_NAME,
  LOAD_STATUS_UPARENTS_BY_NAME,
  GET_UPARENTS_BY_GTAL,
  LOAD_STATUS_UPARENTS_BY_GTAL,
  GET_UPARENTS_BY_SWISSTAL,
  LOAD_STATUS_UPARENTS_BY_SwissTAL,
  LOAD_STATUS_UPARENTS_BY_ClientName,
  GET_UPARENTS_BY_ClientName
} from "../actions/uparent";
import apiFactory from '../../services/api.factory';
const uparents = apiFactory.get('uparent');


// INFO: Here we declare the stores available for this 'accounts' store
const state = {
  uparentsloaded: false,
  uparentsNoTalLoaded: false,
  uparents: [],
  uparentsNoTal: [],
  uparentsloadedName: false,
  uparentsByName: [],
  uparentsByGTal: [],
  uparentsByGTalLoaded: false,
  upartensBySwissTAL: [],
  upartensBySwissTALLoaded: false,
  upartensByClientName: [],
  upartensByClientNameLoaded: false,

};
// INFO: getters are to compute a state (derivation)
const getters = {
  uparentsloadingstate: state => {
    return state.uparentsloaded;

  },
  getuparents: state => {
    return state.uparents;
  },

  uparentsNoTalLoadedState: state => {
    return state.uparentsNoTalLoaded;
  },

  getUparentsNoTal: state => {
    return state.uparentsNoTal;
  },
  getUparentsByName: state => {
    return state.uparentsByName;
  },
  getuparentsloadedName: state => {
    return state.uparentsloadedName
  },
  getuparentsByGTal: state => {
    return state.uparentsByGTal
  },
  getuparentsByGTalLoaded: state => {
    return state.uparentsByGTalLoaded
  },
  getupartensBySwissTAL: state => {
    return state.upartensBySwissTAL
  },
  getupartensBySwissTALLoaded: state => {
    return state.upartensBySwissTALLoaded
  },
  getupartensByClientName: state =>{
    return state.upartensByClientName
  },
  getupartensByClientNameLoaded: state => {
    return state.upartensByClientNameLoaded
  }
};

// INFO: Actions do not mutate directly a state instead they commit a mutation. Actions can contain async activities
const actions = {
  [GET_UPARENTS]: async ({
    commit,
    dispatch
  },) => {
    await uparents.get().then(resp => {
      commit('SET_UPARENTS', resp.data);
    });
  },
  [GET_UPARENTS_NO_TAL]: async ({ commit }) => {
    await uparents.getNoTAL().then(resp => {
      commit("SET_UPARENTS_NO_TAL", resp.data);
    });
  },
  [LOAD_STATUS_UPARENTS_NO_TAL]: async ({ commit }) => {
    commit("SET_LOAD_STATUS_NO_TAL");
  },
  [LOAD_STATUS_UPARENTS]: async ({ commit }) => {
    commit("SET_LOAD_STATUS");
  },
  [GET_UPARENTS_BY_NAME]: async ({ commit }, name) => {
    await uparents.getUparentsByName(name).then(resp => {
      commit("SET_UPARENTS_BY_NAME", resp.data);
    })
  },
  [LOAD_STATUS_UPARENTS_BY_NAME]: async ({ commit }) => {
    commit("SET_LOAD_STATUS_UPARENTS_BY_NAME");
  },
  [GET_UPARENTS_BY_GTAL]: async ({ commit }, gtal) => {
    await uparents.getUparentsbyGTAL(gtal).then(resp => { commit("SET_UPARENTS_BY_GTAL", resp.data); })
  },
  [LOAD_STATUS_UPARENTS_BY_GTAL]: async ({ commit }) => {
    commit("SET_LOAD_STATUS_UPARENTS_BY_GTAL");
  },
  [GET_UPARENTS_BY_SWISSTAL]: async ({ commit }, swissTAL) => {
    await uparents.getUparentsbySwissTAL(swissTAL).then(resp => { commit("SET_UPARENTS_BY_SWISS_TAL", resp.data) })
  },
  [LOAD_STATUS_UPARENTS_BY_SwissTAL]: async ({commit}) =>{
    commit("SET_LOAD_STATUS_UPARENTS_BY_SWISS_TAL");
  },
  [GET_UPARENTS_BY_ClientName]: async ({ commit }, client) => {
    await uparents.getUparentsbyClientNAme(client).then(resp => { commit("SET_UPARENTS_BY_CLIENT_NAME", resp.data) })
  },
  [LOAD_STATUS_UPARENTS_BY_ClientName]: async ({commit}) =>{
    commit("LOAD_STATUS_UPARENTS_BY_ClientName");
  }


};

// INFO: Mutations are the only way to change the state
const mutations = {
  SET_UPARENTS(state, payload) {
    state.uparentsloaded = true;
    state.uparents = payload;
  },

  SET_UPARENTS_NO_TAL(state, payload) {

    state.uparentsNoTalLoaded = true;
    state.uparentsNoTal = payload;
  },
  SET_LOAD_STATUS_NO_TAL(state) {
    state.uparentsNoTalLoaded = false;
  },
  SET_LOAD_STATUS(state) {
    state.uparentsloaded = false;
  },
  SET_UPARENTS_BY_NAME(state, payload) {
    state.uparentsloadedName = true;
    state.uparentsByName = payload;
  },
  SET_LOAD_STATUS_UPARENTS_BY_NAME(state) {
    state.uparentsloadedName = false;
  },
  SET_UPARENTS_BY_GTAL(state, payload) {
    state.uparentsByGTal = payload;
    state.uparentsByGTalLoaded = true;

  },
  SET_LOAD_STATUS_UPARENTS_BY_GTAL(state) {
    state.uparentsByGTalLoaded = false;
  },
  SET_UPARENTS_BY_SWISS_TAL(state, payload){
    state.upartensBySwissTAL = payload,
    state.upartensBySwissTALLoaded = true;
  },
  SET_LOAD_STATUS_UPARENTS_BY_SWISS_TAL(state){
    state.upartensBySwissTALLoaded = false;
  },
  SET_UPARENTS_BY_CLIENT_NAME(state, payload){
    state.upartensByClientName = payload,
    state.upartensByClientNameLoaded = true;
  },
  LOAD_STATUS_UPARENTS_BY_ClientName(state){
    state.upartensByClientNameLoaded = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
