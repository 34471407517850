<template>
  <div>
    <!-- empty spacer block -->
    <!-- test -->
    <div class="container-fluid bg-white mw-100 m-0" id="container"></div>
    <!-- Content block - table -->
    <div class="container-fluid bg-white mw-100 mx-auto my-2">
      <h3>Admin - TAL Tracking</h3>

      <b-button
        v-if="engagements === true"
        size="sm"
        variant="outline-dark"
        class="btn-approve mx-2 btn-remove-border"
        @click="engagementsFalse(), getOpportunities()"
      >
        Show Opportunity Tracking</b-button
      >
      <b-button
        v-if="engagements === false"
        size="sm"
        variant="outline-dark"
        class="btn-approve mx-2 btn-remove-border"
        @click="engagementsTrue()"
      >
        Show Engagement Tracking</b-button
      >
      <br />
      <br />
      <span v-if="engagements === true"> <talTrackingEngagements /></span>
      <span v-if="engagements === false">
        <talTrackingOpportunities />
      </span>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import talTrackingEngagements from "@/components/admin/talTracking.vue";
import talTrackingOpportunities from "@/components/admin/talTrackingOpportunity.vue";
import { GET_CHANGES_ENGAGEMENT_TRACKING, GET_OPPORTUNITY_FOR_TRACKING } from "@/store/actions/admin";


export default {
  name: "admin",
  data() {
    return {
      engagements: true,
    };
  },
  mounted(){
        this.$store.dispatch(GET_CHANGES_ENGAGEMENT_TRACKING);
    
  },
  components: {
    talTrackingEngagements,
    talTrackingOpportunities,
  },
  methods: {
    engagementsFalse() {
      this.engagements = false;
    },
    engagementsTrue() {
      this.engagements = true;
    },
    getOpportunities() {
this.$store.dispatch(GET_OPPORTUNITY_FOR_TRACKING);
    }
  },
};
</script>

<style>
#container {
  min-height: 20px;
}
</style>
