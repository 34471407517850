<template>
    <apexchart id="pipelinewins" type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  data: function() {
    return {
        
      options: {
        chart: {
          id: 'vuechart-example'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        legend:{
            show:true,
            position: 'bottom'
        },
        xaxis: {
          categories: ['BTS/ BTS-CTOR',	'BTS/ BTS-Private Tax',	'BTS/ BTS-Tax Policy & Controversy',	'BTS/ BTS-BTA',	'BTS/ BTS-Quantitative Services',	'GCR/ GCR - Global Payroll',	'GCR/ GCR Core',	'GCR/ TARAS',	'GCR/ GCR - ACR',	'Indirect/ Indirect Tax-Global Trade',	'Indirect/ Indirect Tax Core',	'International Tax Transaction Services/ ITTS Advisory',	'International Tax Transaction Services/ ITTS Transfer Pricing',	'International Tax Transaction Services/ ITTS Transactions Related',	'Law/ Law Operations',	'Law/ Law Advisory Services']
        }
      },
      series: [{
        name: 'Pipeline in MCHF',
        data: [0,	0.51,	0.04,	5.66,	0.05,	7.49,	9.26,	0.22,	1.31,	3.84,	2.55,	5.07,	12.15,	3.9,	1.17,	1.69]
      }
      ],
     
    
    }
  },

    /* mounted(){
          // all values returned from server
         var values = this.$store.getters.getpipelineBySSLComp;
        
    var cat = [];

    var plan = [];
    for (var i = 0; i < values.length; i++) {

        cat.push(values[i].SSL);
        plan.push((values[i].ValuePipeline/1000000).toFixed(1));
      }
    

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = plan;

         }*/
  
};
</script>

<style >
#pipelinewins{
  height: 460;
}
</style>