<template>
  <apexchart
    id="pipelinewins"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data: function () {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Wins by SSL in MCHF",
          data: [],
        },
      ],
    };
  },

mounted() {
    var values = this.$store.getters.getwinsBySSL;

    var cat = [];

    var wins = [];
    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].SSL);
      wins.push(parseFloat((values[i].WinsYTD / 1000000).toFixed(1)));
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = wins;
  },
};
</script>

<style>
#pipelinewins {
  height: 460;
}
</style>