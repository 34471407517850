export const GET_ENGAGEMENTS = "GET_ENGAGEMENTS";
export const GET_ENGAGEMENTS_GTER = "GET_ENGAGEMENTS_GTER";
export const GET_ENGAGEMENTS_GROWTH = "GET_ENGAGEMENTS_GROWTH";
export const GET_ENGAGEMENTS_GTER_PLANNED = "GET_ENGAGEMENTS_GTER_PLANNED";
export const GET_ENGAGEMENTS_GTER_VS_PLANNED = "GET_ENGAGEMENTS_GTER_VS_PLANNED";
export const GET_ENGAGEMENTS_GTER_MARGIN_BY_SSL = "GET_ENGAGEMENTS_GTER_MARGIN_BY_SSL";
export const GET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY = "GET_ENGAGEMENTS_CHARGED_HOURS_BY_COMPETENCY";
export const GET_ENGAGEMENTS_MARGIN_GROWTH_PY = "GET_ENGAGEMENTS_MARGIN_GROWTH_PY";
export const GET_ENGAGEMENTS_TOP_TEN_ACCOUNTS = "GET_ENGAGEMENTS_TOP_TEN_ACCOUNTS";
export const GET_ENGAGEMENTS_MARGIN = "GET_ENGAGEMENTS_MARGIN";
export const GET_ENGAGEMENTS_MARGIN_PERCENT = "GET_ENGAGEMENTS_MARGIN_PERCENT";
export const GET_ENGAGEMENTS_SSL_PENETRATION = "GET_ENGAGEMENTS_SSL_PENETRATION";
export const GET_ENGAGEMENTS_AR_BELOW_90 = "GET_ENGAGEMENTS_AR_BELOW_90";
export const GET_ENGAGEMENTS_AR_BETWEEN_90_150 = "GET_ENGAGEMENTS_AR_BETWEEN_90_150";
export const GET_ENGAGEMENTS_AR_OVER_150 = "GET_ENGAGEMENTS_AR_OVER_150";
export const GET_ENGAGEMENTS_AR_TOTAL = "GET_ENGAGEMENTS_AR_TOTAL";
export const GET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER= "GET_ENGAGEMENTS_AR_OVER_150_BY_ENG_PARTNER";
export const GET_ENGAGEMENTS_AR_BY_ENG_PARTNER= "GET_ENGAGEMENTS_AR_BY_ENG_PARTNER";
export const GET_ENGAGEMENT_SUM = "GET_ENGAGEMENT_SUM";
export const GET_ENGAGEMENTS_BY_ID = "GET_ENGAGEMENTS_BY_ID";
export const LOAD_STATUS_UPARENTS_BY_ENGAGEMENT_ID = "LOAD_STATUS_UPARENTS_BY_ENGAGEMENT_ID";