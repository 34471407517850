<template>
<div>

    <apexchart id="GTERMarginSSL" type="bar" :options="options" :series="series"></apexchart>
</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  data: function() {
    return {
        
      options: {
        chart: {
          id: 'GTERMarginSSL'
        },
        legend:{
            show:true,
            position: 'bottom'
        },
        xaxis: {
          categories: []
        },
         yaxis: {
          labels: {
            formatter: function (value) {
              return value
            },
          },
        },
      },
      series: [{
        name: 'Total TER YTD in MCHF',
        data: []
      },
      {
        name: 'Total Margin YTD in MCHF',
        data: []
      },
      ],
     
    
    }
  },

    mounted(){
         var values = this.$store.getters.getengagementsGTERMarginBySSL
          
         var cat = [];
    var ter = [];
    var margin = [];

    for (var i = 0; i < values.length; i++) {
    var catValue = values[i].EngagementSubServiceLine
    cat.push(catValue)
    var terValue = values[i].FYTD_TERAmt
    terValue = (terValue/1000000).toFixed(2)
    ter.push(terValue)
    var marginValue = values[i].FYTD_MarginAmt
    marginValue = (marginValue /1000000).toFixed(2)
    margin.push(marginValue)
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };
    this.series[0].data = ter;
    this.series[1].data = margin;
         }
  
};
</script>

<style>
#GTERMarginSSL{
  height: 300;
  width: 300;
}
</style>