<template>
    <apexchart id="pipelinewins" type="bar" :options="options" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  data: function() {
    return {
        
      options: {
        chart: {
          id: 'vuechart-example'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        legend:{
            show:true,
            position: 'bottom'
        },
        xaxis: {
          categories: []
        }
      },
      series: [{
        name: 'Open Opportunities by SSL in MUSD',
        data: []
      }
      ],
     
    
    }
  },

    mounted(){
      var values = this.$store.getters.getopenBySSL;

    var cat = [];

    var open = [];
    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].SSL);
      open.push(parseFloat((values[i].OpenYTD / 1000000).toFixed(1)));
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = open;
  },
        
  
};
</script>

<style>
#pipelinewins{
  height: 460;
}
</style>