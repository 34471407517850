export const GET_CHANGES = "GET_CHANGES";
export const POST_CHANGES = "POST_CHANGES";
export const PUT_CHANGES = "PUT_CHANGES";
export const POST_CHANGES_ENGAGEMENT_TRACKING = "POST_CHANGES_ENGAGEMENT_TRACKING";
export const GET_CHANGES_ENGAGEMENT_TRACKING = "GET_CHANGES_ENGAGEMENT_TRACKING";
export const LOAD_STATUS_TRACKING_BY_NAME = "LOAD_STATUS_TRACKING_BY_NAME";
export const GET_CLIENT_BY_UDUNS = "GET_CLIENT_BY_UDUNS";
export const GET_TRACKING_BY_NAME = "GET_TRACKING_BY_NAME";
export const GET_CLIENT_NO_ENGAGEMENT = "GET_CLIENT_NO_ENGAGEMENT";
export const GET_OPPORTUNITY_FOR_TRACKING = "GET_OPPORTUNITY_FOR_TRACKING";
export const GET_UPARENT_NO_OPPORTUNITY = "GET_UPARENT_NO_OPPORTUNITY";
export const GET_ENGAGEMENT_TRACKING_ACTION_REQUIRED  = "GET_ENGAGEMENT_TRACKING_ACTION_REQUIRED";
export const LOAD_STATUS_TRACKING_CLIENT = "LOAD_STATUS_TRACKING_CLIENT";
export const GET_CLIENT_BY_UDUNS_OPPORTUNITY = "GET_CLIENT_BY_UDUNS_OPPORTUNITY";
export const LOAD_STATUS_TRACKING_CLIENT_OPPORTUNITY = "LOAD_STATUS_TRACKING_CLIENT_OPPORTUNITY";
export const GET_TRACKING_BY_SWISS_TAL ="GET_TRACKING_BY_SWISS_TAL";
export const LOAD_STATUS_TRACKING_BY_SWISS_TAL = "LOAD_STATUS_TRACKING_BY_SWISS_TAL";
export const LOAD_STATUS_UPARENT_NO_OPPORTUNITY = "LOAD_STATUS_UPARENT_NO_OPPORTUNITY";
export const GET_TRACKING_OPPORTUNITY_BY_NAME = "GET_TRACKING_OPPORTUNITY_BY_NAME";
export const LOAD_STATUS_OPPORTUNITY_BY_NAME = "LOAD_STATUS_OPPORTUNITY_BY_NAME";
export const LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL = "LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL";
export const GET_TRACKING_OPPORTUNITY_BY_SWISS_TAL = "GET_TRACKING_OPPORTUNITY_BY_SWISS_TAL";
export const GET_OPPORTUNITY_TRACKING_ACTION_REQUIRED  = "GET_OPPORTUNITY_TRACKING_ACTION_REQUIRED";
export const LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED = "LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED";