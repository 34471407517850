<template>
  <apexchart
    id="pipelinewins"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data: function () {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Pipeline FYTD in MCHF",
          data: [],
        },
        {
          name: "Wins FYTD in MCHF",
          data: [],
        },

        
      ],
    };
  },

 mounted() {
    var values = this.$store.getters.getpielineWinsBySSl;

    var cat = [];
    var wins = [];
    var pipeline = [];
    for (var i = 0; i < values.length; i++) {
      cat.push(values[i].StatusNm);
      wins.push(parseFloat((values[i].ValueWon / 1000000).toFixed(1)));
      pipeline.push(parseFloat((values[i].ValueOpen / 1000000).toFixed(1)));
    }

    this.options = {
      xaxis: {
        categories: cat,
      },
    };

    this.series[0].data = wins;
    this.series[1].data = pipeline;
  },
  computed: {
    getValues() {
      return this.$store.getters.getlossBySSL;
    },
  },
};
</script>

<style>
#pipelinewins {
  height: 460;
}
</style>