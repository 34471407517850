<template>
  <div>
    <h5><b-icon-inbox></b-icon-inbox> Changes awaiting your approval</h5>
    <vue-good-table :columns="colchanges" :rows="getChanges" compactMode>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <span>
            <span>
              <b-button
                size="sm"
                variant="outline-dark"
                class="btn-approve mx-2 btn-remove-border"
                @click="approved(props.row)"
                ><b-icon-check2 /> Approve</b-button
              >
              <b-button
                size="sm"
                variant="outline-dark"
                class="btn-approve mx-2 btn-remove-border"
                @click="reject(props.row)"
                ><b-icon-slash-circle /> Reject</b-button
              >
            </span>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { PUT_CHANGES } from "@/store/actions/admin";
import { UPDATE_CLIENT } from "../../store/actions/clients";
export default {
  name: "inbox",
  data() {
    return {
      colchanges: [
        {
          label: "Requestor Email",
          field: "email",
        },
        {
          label: "ClientID",
          field: "clientid",
        },
        {
          label: "Client Name",
          field: "clientname",
        },
        {
          label: "Field",
          field: "changefield",
        },
        {
          label: "Old Value",
          field: "oldfieldvalue",
        },
        {
          label: "New Value",
          field: "newfieldvalue",
        },
        {
          label: "Details",
          field: "changedetails",
        },
        // INFO: special changes
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  computed: {
    getChanges() {
      return this.$store.getters.getactivechanges;
    },
  },
  methods: {
    approved: function (item) {
      if (item.active === true) {
        var payload = {
          id: item.clientid,
          approved: true,
        };
        this.$store.dispatch(PUT_CHANGES, payload);
        
        var body =
          "Dear requestor,%0D%0A" +
          "your request to change the " +
          item.changefield +
          " to " +
          item.newfieldvalue +
          " was approved.%0D%0A %0D%0A" + "Kind regards,%0D%0A"+ "Your Swiss TAL Management Admin";
        window.open(
          "mailto:" +
            item.email +
            "?subject=Your Change Request for " +
            item.clientid +
            "-" +
            item.clientname +
            "&body=" +
            body
        );
        var id = String(item.clientid);
        var payload = {
          EntityID: id,
          changefield: item.changefield,
          newfieldvalue: item.newfieldvalue,
        };
      }
      this.$store.dispatch(UPDATE_CLIENT, payload);
    },
    reject: function (item) {
   
      if (item.active === true) {
        var payload = {
          id: item.clientid,
          approved: false,
        };
        this.$store.dispatch(PUT_CHANGES, payload);
        var body =
          "Dear requestor, %0D%0A" +
          "your request to change the " +
          item.changefield +
          " to " +
          item.newfieldvalue +
          " was rejected.%0D%0A %0D%0A" + "Kind regards,%0D%0A"+ "Your Swiss TAL Management Admin";
        window.open(
          "mailto:" +
            item.email +
            "?subject=Your Change Request for " +
            item.clientid +
            "-" +
            item.clientname +
            "&body=" +
            body
        );
      }
    },
  },
};
</script>
