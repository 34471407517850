<template>
  <div>
    <h5><b-icon-journal /> Change Log</h5>
    <vue-good-table :columns="colinactive" :rows="getInactChanges" compactMode />
  </div>
</template>

<script>

export default {
  name: "changelog",
  data() {
    return {
      colinactive: [
        {
          label: "Requestor Email",
          field: "email"
        },
        {
          label: "ClientID",
          field: "clientid"
        },
        {
          label: "Client Name",
          field: "clientname"
        },
        {
          label: "Field",
          field: "changefield"
        },
        {
          label: "Old Value",
          field: "oldfieldvalue"
        },
        {
          label: "New Value",
          field: "newfieldvalue"
        },
        {
          label: "Details",
          field: "changedetails"
        },
        {
          label: "Approved",
          field: "approved"
        }
      ],
     
    };
  },

  computed:{
   
    getInactChanges(){
      
      return this.$store.getters.getinactivechanges
      
    }

  }
};
</script>
