<template>
  <div>
    <h5>Opportunity Tracking by Client</h5>
    <b-button
     v-if="
        displayActionRequired === false &&
        displayClientsNoOpportunity === false
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="actionRequiredTrue(), getActionRequired()"
      ><b-icon-flag-fill variant="danger" /> Show Where Action
      Required</b-button
    >
    <b-button
      v-if="
        displayActionRequired === true &&
        TrackingListLoadedActionRequired === true
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="actionRequiredFalse()"
    >
      Show All Entries</b-button
    >

    <b-button
      v-if="
        displayClientsNoOpportunity === false &&
        TrackingListLoadedActionRequired === false
        
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="
        clientsNoEngagement();
        getClientsNoOpportunity();
      "
      >Show Clients without Opportunities
    </b-button>
    <b-button
      v-if="
        displayClientsNoOpportunity === true &&
        getOpportunitiesNoOppLoaded === true
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="clientsNoOpportunityFalse()"
    >
      Show All Entries</b-button
    >

    <br />
    <br />
    <!-- search bar client Name -->
    <div>
      <b-form-group class="mb-2" id="form_group">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            placeholder="Type to Search for Client Name"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              v-if="trackingOpportunityByNameLoaded === false"
              variant="light"
              :disabled="!filter"
              @click="seachAllClients()"
              ><b-icon-search
            /></b-button>
          </b-input-group-append>
          <b-button
            size="sm"
            v-if="trackingOpportunityByNameLoaded === true"
            @click="clearSearch()"
            ><b-icon-x
          /></b-button>
           <b-spinner v-if="spinClient===true && trackingOpportunityByNameLoaded===false "></b-spinner>
        </b-input-group>
        <br />
        <!-- search bar swiss tal -->
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filterTAL"
            placeholder="Type to Search for Swiss Tal"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              v-if="trackingOpportunityBySwissTalLoaded === false"
              variant="light"
              :disabled="!filterTAL"
              @click="seachAllClientsforSwissTAL()"
              ><b-icon-search
            /></b-button>
          </b-input-group-append>
          <b-button
            size="sm"
            v-if="trackingOpportunityBySwissTalLoaded === true"
            @click="clearSearch()"
            ><b-icon-x
          /></b-button>
          <b-spinner v-if="spin===true && trackingOpportunityBySwissTalLoaded===false "></b-spinner>
        </b-input-group>
      </b-form-group>
      <br />
    </div>

    <!-- default table -->

    <b-table
      bordered
      v-if="
        displayActionRequired === false &&
        
        displayClientsNoOpportunity === false && 
        trackingOpportunityByNameLoaded === false &&
        trackingOpportunityBySwissTalLoaded === false
      "
      :fields="colchanges"
      :items="OpportunitiesForTracking"
      small
      outlined
      :busy="!TrackingListLoaded"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(Client)="row">
        <!-- button show engagements -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getOpportunities(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="getOpportunitiesByClient"
            :fields="colsClients"
            small
            outlined
            :busy="!getOpportunitiesByClientLoaded"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthOppValue)="row">
              <b-icon-flag-fill
                v-if="row.item.growthOppValue === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              enableButtons();
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthOppValue)="row">
        <b-icon-flag-fill v-if="row.item.growthOppValue === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>

    <!-- table where action requried table -->
    <b-table
      bordered
      v-if="
        displayActionRequired === true &&
         trackingOpportunityByNameLoaded === false &&
        trackingOpportunityBySwissTalLoaded === false
      "
      :fields="colchanges"
      :items="showActionRequired"
      small
      outlined
      :busy="!TrackingListLoadedActionRequired"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getOpportunities(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="getOpportunitiesItems"
            :fields="colsClients"
            small
            outlined
            :busy="!getOpportunitiesItemsLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthOppValue)="row">
              <b-icon-flag-fill
                v-if="row.item.growthOppValue === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthOppValue)="row">
        <b-icon-flag-fill v-if="row.item.growthOppValue === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>

    <!-- table search result client name-->

    <b-table
      bordered
      v-if="trackingOpportunityByNameLoaded === true "
      :fields="colchanges"
      :items="gettrackingOpportunityByName"
      small
      outlined
    >
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getOpportunities(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="getOpportunitiesByClient"
            :fields="colsClients"
            small
            outlined
            :busy="!getOpportunitiesByClientLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthOppValue)="row">
              <b-icon-flag-fill
                v-if="row.item.growthOppValue === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthOppValue)="row">
        <b-icon-flag-fill v-if="row.item.growthOppValue === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>

 <!-- table search result swiss tal-->

       <b-table
      bordered
      v-if="trackingOpportunityBySwissTalLoaded === true "
      :fields="colchanges"
      :items="gettrackingOpportunityBySwissTal"
      small
      outlined
   >
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getOpportunities(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="getOpportunitiesByClient"
            :fields="colsClients"
            small
            outlined
            :busy="!getOpportunitiesByClientLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthOppValue)="row">
              <b-icon-flag-fill
                v-if="row.item.growthOppValue === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthOppValue)="row">
        <b-icon-flag-fill v-if="row.item.growthOppValue === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>


    <!-- table clients without Opportunities -->

    <b-table
      bordered
      v-if="
        displayClientsNoOpportunity === true 
   
      "
      :fields="colchanges"
      :items="getOpportunitiesNoOpp"
      small
      outlined
      :busy="!getOpportunitiesNoOppLoaded"
      ><template #table-busy>
        <div class="text-center my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
        </div>
      </template>
      >
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getOpportunities(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="getOpportunitiesByClient"
            :fields="colsClients"
            small
            outlined
            :busy="!getOpportunitiesByClientLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthOppValue)="row">
              <b-icon-flag-fill
                v-if="row.item.growthOppValue === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthOppValue)="row">
        <b-icon-flag-fill v-if="row.item.growthOppValue === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>
    <b-button
      v-if="
        displayClientsNoOpportunity === true 
        
        
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="
        clientsNoEngagement();
        getClientsNoOpportunityLoadMore();
      "
      >Load more
    </b-button>
    <b-button
      v-if="
        displayClientsNoOpportunity === true &&
        
        
        skip > 0
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="
        clientsNoEngagement();
        getClientsNoOpportunityLoadLess();
      "
      >Back
    </b-button>
  </div>
</template>

<script>
import {
 LOAD_STATUS_TRACKING_CLIENT_OPPORTUNITY,
 GET_OPPORTUNITY_FOR_TRACKING,
 GET_UPARENT_NO_OPPORTUNITY,
 LOAD_STATUS_UPARENT_NO_OPPORTUNITY,
 GET_TRACKING_OPPORTUNITY_BY_NAME,
 LOAD_STATUS_OPPORTUNITY_BY_NAME,
 LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL,
 GET_TRACKING_OPPORTUNITY_BY_SWISS_TAL,
 GET_OPPORTUNITY_TRACKING_ACTION_REQUIRED,
 LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED
} from "@/store/actions/admin";

import {
  GET_OPPORTUNITIES
} from "@/store/actions/opportunities";
export default {
  name: "talTrackingEngagements",
  data() {
    return {
      displayActionRequired: false,
      filter: null,
      isActive: false,
      displayClientsNoOpportunity: false,
      skip: 0,
      filterTAL: null,
      spin: false,
      spinClient: false,

      colchanges: [
        {
          label: "Client ID",
          key: "ClientID",
          sortable: true,
        },
        {
          label: "Client Name",
          key: "Client",
          sortable: true,
        },
        {
          label: "GTAL",
          key: "GTAL",
          sortable: true,
        },
        {
          label: "Swiss Tal",
          key: "SwissTal",
          sortable: true,
        },
        {
          label: "Channel",
          key: "AccountChannel",
          sortable: true,
        },

        {
          label: "newly created opportunities FY22",
          key: "NumberofOpportunitiesFY22",
          sortable: true,
        },
        {
          label: "Expected Amount of newly created opportunities in FY22",
          key: "OpportunitiesValueFY22",
          formatter: (value) => {
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          },
          sortable: true,
        },
        {
          label: "newly created opportunities FY23",
          key: "NumberofOpportunitiesFY23",
          sortable: true,
        },
        {
          label: "Expected Amount of newly created opportunities in FY23",
          key: "OpportunitiesValueFY23",
          formatter: (value) => {
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          },
          sortable: true,
        },
        
        {
          label: "Growth Opportunities",
          key: "growthOppValue",
          sortable: true,
        },
      ],
      colsClients: [
        {
          label: "Opportunity ID",
          key: "OpportunityId",
          sortable: true,
        },
        {
          label: "Opportunity",
          key: "OpportunityNm",
          sortable: true,
        },
        {
          label: "Status",
          key: "StatusNm",
          sortable: true,
        },
        {
          label: "Opportunity Partner",
          key: "OpportunityPartner",
          sortable: true,
        },

        {
          label: "FYTD TER",
          key: "ExpectedAmt",
          
          sortable: true,
        },
        {
          label: "Currency",
          key: "CurrencyCd",
          sortable: true,
        },
        {
          label: "SSL",
          key: "EngagementSubServiceLine",
          sortable: true,
        },
        {
          label: "Release Date",
          key: "CreatedDt",
          formatter: (value) => {
            if (value != null) {
              return value.slice(0, 10);
            } else {
              return value;
            }
          },
          sortable: true,
        },

      ],
    };
  },
  computed: {
    TrackingListLoaded() {
      return this.$store.getters.gettrackingOpportunityLoaded;
    },
    gettrackingListOpportunities() {
      return this.$store.getters.gettrackingListOpportunities;
    },
    OpportunitiesForTracking() {
      return this.$store.getters.getopportunitiesForTracking;
    },
    showActionRequired() {
      return this.$store.getters.getopportunityTrackingListActionRequried;
    },
    TrackingListLoadedActionRequired() {
      return this.$store.getters.getopportunitytrackingListLoadedActionRequired;
    },
    getgettrackingListByUParent() {
      return this.$store.getters.gettrackingListByUParent;
    },
    getOpportunitiesByClient() {
      return this.$store.getters.getopportunities;
    },
    getOpportunitiesByClientLoaded(){
      return this.$store.getters.opportunitiesloadingstate
    },
    getOpportunitiesItems() {
      return this.$store.getters.getopportunitiesForTracking;
    },
    getOpportunitiesNoOppLoaded() {
      return this.$store.getters.gettrackingListUparentNoOpportunityLoaded;
    },
    getOpportunitiesNoOpp() {
      return this.$store.getters.gettrackingListUparentNoOpportunity;
    },
    trackingListBySwissTalLoaded() {
      return this.$store.getters.gettrackingListBySwissTalLoaded;
    },
     trackingListBySwissTal() {
      return this.$store.getters.gettrackingListBySwissTal;
    },
    gettrackingOpportunityByName(){
      return this.$store.getters.gettrackingOpportunityByName;
    },
    trackingOpportunityByNameLoaded(){
      return this.$store.getters.gettrackingOpportunityByNameLoaded;
    },
    gettrackingOpportunityBySwissTal(){
      return this.$store.getters.gettrackingOpportunityBySwissTal;
    },
    trackingOpportunityBySwissTalLoaded(){
      return this.$store.getters.gettrackingOpportunityBySwissTalLoaded;
    },
  },

  methods: {
    actionRequiredTrue() {
      this.displayActionRequired = true;
    },
    getActionRequired() {
      this.$store.dispatch(GET_OPPORTUNITY_TRACKING_ACTION_REQUIRED);
    },
    actionRequiredFalse() {
      this.displayActionRequired = false;
      this.$store.dispatch(LOAD_STATUS_OPPORTUNITY_ACTION_REQUIRED);
    },
    seachAllClients() {
      this.spinClient = true;
      this.$store.dispatch(GET_TRACKING_OPPORTUNITY_BY_NAME, this.filter);
    },
    clearSearch() {
      this.filter = null;
      this.$store.dispatch(LOAD_STATUS_OPPORTUNITY_BY_NAME);
      this.filterTAL = null;
      this.spin = false;
      this.spinClient = false; 
      this.$store.dispatch(LOAD_STATUS_OPPORTUNITY_BY_SWISS_TAL);
    },
    getOpportunities(item) {
      this.$store.dispatch(GET_OPPORTUNITIES, item.ClientID);
    },
    disableButton() {
      this.isActive = true;
    },
    clientsNoEngagement() {
      this.displayClientsNoOpportunity = true;
    },
    getClientsNoOpportunity() {
      this.$store.dispatch(GET_UPARENT_NO_OPPORTUNITY, this.skip);
    },
    getClientsNoOpportunityLoadMore() {
      this.skip = this.skip + 200;
      this.$store.dispatch(GET_UPARENT_NO_OPPORTUNITY, this.skip);
    },
    getClientsNoOpportunityLoadLess() {
      this.skip = this.skip - 200;
      this.$store.dispatch(GET_UPARENT_NO_OPPORTUNITY, this.skip);
    },
    clientsNoOpportunityFalse() {
      this.displayClientsNoOpportunity = false;
      this.$store.dispatch(LOAD_STATUS_UPARENT_NO_OPPORTUNITY);
    },
    resetClientLoadStatus() {
      this.$store.dispatch(LOAD_STATUS_TRACKING_CLIENT);
    },
    enableButtons() {
      this.isActive = false;
    },
    seachAllClientsforSwissTAL() {
      this.spin = true;
      this.$store.dispatch(GET_TRACKING_OPPORTUNITY_BY_SWISS_TAL, this.filterTAL);
    },
  },
};
</script>
