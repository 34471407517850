import Vue from 'vue';
import Vuex from 'vuex';

import engagements from "./modules/engagements";
import clients from "./modules/clients";
import opportunities from "./modules/opportunities";
import admin from "./modules/admin";
import uparent from "./modules/uparent";









Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        engagements,
        clients,
        opportunities,
        admin,
        uparent,

     

 
      

        
    },
});