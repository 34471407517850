import api from "./api.services";


const tenantid = sessionStorage.getItem('adal.idtoken');
const resource = "/uparents";
const config = {headers: { Authorization: 'Bearer ' + tenantid}}
export default {

  get() {
    return api.get(`${resource}`,{config});
  },
  getNoTAL(){
    return api.get("/uparents/noTAL", config)
  },
  getUparentsByName(name){
    const params = new URLSearchParams([['EntityName', name ]])
    return api.get("/uparents/ByName",{params} , config)
  },
  getUparentsbyGTAL(gtal){
    const params = new URLSearchParams([['GlobalTal', gtal]])
    return api.get("/uparents/ByGTAL", {params}, config)
  },
  getUparentsbySwissTAL(swissTAL){
    const params = new URLSearchParams([['SwissTal', swissTAL]])
    return api.get("/uparents/BySwiss", {params}, config)
  },

  getUparentsbyClientNAme(client){
    const params = new URLSearchParams([['ClientName', client]])
    return api.get("/uparents/ByClient", {params}, config)
  }


  
};
