<template>
  <div>
    <h5>Engagement Tracking by Client</h5>
    <b-button
      v-if="
        displayActionRequired === false &&
        gettrackingListByUParentLoaded === false &&
        displayClientsNoEngagement === false
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="actionRequiredTrue(), getActionRequired()"
      ><b-icon-flag-fill variant="danger" /> Show Where Action
      Required</b-button
    >
    <b-button
      v-if="
        displayActionRequired === true &&
        gettrackingListByUParentLoaded === false
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="actionRequiredFalse()"
    >
      Show All Entries</b-button
    >

    <b-button
      v-if="
        displayClientsNoEngagement === false &&
        gettrackingListByUParentLoaded === false &&
        displayActionRequired === false
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="
        clientsNoEngagement();
        getclientsNoEngagement();
      "
      >Show Clients without Engagements
    </b-button>
    <b-button
      v-if="
        displayClientsNoEngagement === true &&
        gettrackingListByUParentLoaded === false
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="clientsNoEngagementFalse()"
    >
      Show All Entries</b-button
    >

    <br />
    <br />
    <!-- search bar client Name -->
    <div>
      <b-form-group class="mb-2" id="form_group">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            placeholder="Type to Search for Client Name"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              v-if="gettrackingListByUParentLoaded === false"
              variant="light"
              :disabled="!filter"
              @click="seachAllClients()"
              ><b-icon-search
            /></b-button>
          </b-input-group-append>
          <b-button
            size="sm"
            v-if="gettrackingListByUParentLoaded === true"
            @click="clearSearch()"
            ><b-icon-x
          /></b-button>
           <b-spinner v-if="spinClient===true && gettrackingListByUParentLoaded===false "></b-spinner>
        </b-input-group>
        <br />
        <!-- search bar swiss tal -->
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filterTAL"
            placeholder="Type to Search for Swiss Tal"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              v-if="trackingListBySwissTalLoaded === false"
              variant="light"
              :disabled="!filterTAL"
              @click="seachAllClientsforSwissTAL()"
              ><b-icon-search
            /></b-button>
          </b-input-group-append>
          <b-button
            size="sm"
            v-if="trackingListBySwissTalLoaded === true"
            @click="clearSearch()"
            ><b-icon-x
          /></b-button>
          <b-spinner v-if="spin===true && trackingListBySwissTalLoaded===false "></b-spinner>
        </b-input-group>
      </b-form-group>
      <br />
    </div>

    <!-- default table -->

    <b-table
      bordered
      v-if="
        displayActionRequired === false &&
        gettrackingListByUParentLoaded === false &&
        displayClientsNoEngagement === false && 
        trackingListBySwissTalLoaded === false
      "
      :fields="colchanges"
      :items="getEngagementTrackingList"
      small
      outlined
      :busy="!getTrackingListLoaded"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(Client)="row">
        <!-- button show engagements -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getClients(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="gettrackingClientList"
            :fields="colsClients"
            small
            outlined
            :busy="!gettrackingClientListLoaded"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthTER)="row">
              <b-icon-flag-fill
                v-if="row.item.growthTER === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              enableButtons();
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthTER)="row">
        <b-icon-flag-fill v-if="row.item.growthTER === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>

    <!-- table where action requried table -->
    <b-table
      bordered
      v-if="
        displayActionRequired === true &&
        gettrackingListByUParentLoaded === false &&
        displayClientsNoEngagement === false
      "
      :fields="colchanges"
      :items="showActionRequired"
      small
      outlined
      :busy="!getTrackingListLoadedActionRequired"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getClients(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="gettrackingClientList"
            :fields="colsClients"
            small
            outlined
            :busy="!gettrackingClientListLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthTER)="row">
              <b-icon-flag-fill
                v-if="row.item.growthTER === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthTER)="row">
        <b-icon-flag-fill v-if="row.item.growthTER === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>

    <!-- table search result client name-->

    <b-table
      bordered
      v-if="gettrackingListByUParentLoaded === true "
      :fields="colchanges"
      :items="getgettrackingListByUParent"
      small
      outlined
    >
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getClients(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="gettrackingClientList"
            :fields="colsClients"
            small
            outlined
            :busy="!gettrackingClientListLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthTER)="row">
              <b-icon-flag-fill
                v-if="row.item.growthTER === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthTER)="row">
        <b-icon-flag-fill v-if="row.item.growthTER === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>

 <!-- table search result swiss tal-->

       <b-table
      bordered
      v-if="trackingListBySwissTalLoaded === true "
      :fields="colchanges"
      :items="trackingListBySwissTal"
      small
      outlined
   >
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getClients(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="gettrackingClientList"
            :fields="colsClients"
            small
            outlined
            :busy="!gettrackingClientListLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthTER)="row">
              <b-icon-flag-fill
                v-if="row.item.growthTER === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthTER)="row">
        <b-icon-flag-fill v-if="row.item.growthTER === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>


    <!-- table clients without Engagements -->

    <b-table
      bordered
      v-if="
        displayClientsNoEngagement === true &&
        gettrackingListByUParentLoaded === false
      "
      :fields="colchanges"
      :items="getgettrackingListClientNoEngagement"
      small
      outlined
      :busy="!gettrackingClientListNoEngagementsLoaded"
      ><template #table-busy>
        <div class="text-center my-2">
          <b-spinner type="grow" class="align-middle"></b-spinner>
        </div>
      </template>
      >
      <template #cell(Client)="row">
        <!-- button show client to uparent -->
        <b-button
          :disabled="isActive"
          size="sm"
          @click="
            getClients(row.item);
            row.toggleDetails();
            disableButton();
          "
          class="mr-2"
          variant="link"
        >
          {{ row.detailsShowing ? row.item.Client : row.item.Client }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table
            :items="gettrackingClientList"
            :fields="colsClients"
            small
            outlined
            :busy="!gettrackingClientListLoaded"
            ><template #table-busy>
              <div class="text-center my-2">
                <b-spinner type="grow" class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(growthTER)="row">
              <b-icon-flag-fill
                v-if="row.item.growthTER === 0"
                variant="danger"
              >
              </b-icon-flag-fill>
            </template>
          </b-table>

          <b-button
            size="sm"
            @click="
              isActive = !isActive;
              row.toggleDetails();
              resetClientLoadStatus();
            "
            squared
            variant="primary"
            >Close</b-button
          >
        </b-card>
      </template>

      <template #cell(growthTER)="row">
        <b-icon-flag-fill v-if="row.item.growthTER === 0" variant="danger">
        </b-icon-flag-fill>
      </template>
    </b-table>
    <b-button
      v-if="
        displayClientsNoEngagement === true &&
        gettrackingListByUParentLoaded === false &&
        displayActionRequired === false
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="
        clientsNoEngagement();
        getclientsNoEngagementLoadMore();
      "
      >Load more
    </b-button>
    <b-button
      v-if="
        displayClientsNoEngagement === true &&
        gettrackingListByUParentLoaded === false &&
        displayActionRequired === false &&
        skip > 0
      "
      size="sm"
      variant="outline-dark"
      class="btn-approve mx-2 btn-remove-border"
      @click="
        clientsNoEngagement();
        getclientsNoEngagementLoadLess();
      "
      >Back
    </b-button>
  </div>
</template>

<script>
import {
  GET_TRACKING_BY_NAME,
  LOAD_STATUS_TRACKING_BY_NAME,
  GET_CLIENT_BY_UDUNS,
  GET_CLIENT_NO_ENGAGEMENT,
  GET_ENGAGEMENT_TRACKING_ACTION_REQUIRED,
  LOAD_STATUS_TRACKING_CLIENT,
  GET_TRACKING_BY_SWISS_TAL,
  LOAD_STATUS_TRACKING_BY_SWISS_TAL,
} from "@/store/actions/admin";

export default {
  name: "talTrackingEngagements",
  data() {
    return {
      displayActionRequired: false,
      filter: null,
      isActive: false,
      displayClientsNoEngagement: false,
      skip: 0,
      filterTAL: null,
      spin: false,
      spinClient: false,

      colchanges: [
        {
          label: "Client ID",
          key: "ClientID",
          sortable: true,
        },
        {
          label: "Client Name",
          key: "Client",
          sortable: true,
        },
        {
          label: "GTAL",
          key: "GTAL",
          sortable: true,
        },
        {
          label: "Swiss Tal",
          key: "SwissTal",
          sortable: true,
        },
        {
          label: "Channel",
          key: "Channel",
          sortable: true,
        },

        {
          label: "newly created engagements FY22",
          key: "NumberofEngagementsFY22",
          sortable: true,
        },
        {
          label: "TER of newly created engagements in FY22",
          key: "EngagementsTotalTERFY22",
          formatter: (value) => {
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          },
          sortable: true,
        },
        {
          label: "newly created engagements FY23",
          key: "NumberofEngagementsFY23",
          sortable: true,
        },
        {
          label: "TER of newly created engagements in FY23",
          key: "EngagementsTotalTERFY23",
          formatter: (value) => {
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          },
          sortable: true,
        },
        {
          label: "Total TER of Client",
          key: "TotalTERofClient",
          formatter: (value) => {
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          },
          sortable: true,
        },
        {
          label: "Growth Engagements",
          key: "growthTER",
          sortable: true,
        },
      ],
      colsClients: [
        {
          label: "Engagement ID",
          key: "EngagementID",
          sortable: true,
        },
        {
          label: "Engagement",
          key: "Project",
          sortable: true,
        },
        {
          label: "Engagement Status",
          key: "EngagementStatus",
          sortable: true,
        },
        {
          label: "Engagement Partner",
          key: "EngagementPartner",
          sortable: true,
        },

        {
          label: "FYTD TER",
          key: "FYTD_TERAmt",
          formatter: (value) => {
            if (value != null) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else return value;
          },
          sortable: true,
        },
        {
          label: "Currency",
          key: "CurrencyCd",
          sortable: true,
        },
        {
          label: "SSL",
          key: "EngagementSubServiceLine",
          sortable: true,
        },
        {
          label: "Release Date",
          key: "ReleaseDate",
          formatter: (value) => {
            if (value != null) {
              return value.slice(0, 10);
            } else {
              return value;
            }
          },
          sortable: true,
        },

        {
          label: "Engagement Type",
          key: "EngagementType",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    getTrackingListLoaded() {
      return this.$store.getters.getTrackingListLoaded;
    },
    gettrackingListByUParentLoaded() {
      return this.$store.getters.gettrackingListByUParentLoaded;
    },
    getEngagementTrackingList() {
      return this.$store.getters.getEngagementTrackingList;
    },
    showActionRequired() {
      return this.$store.getters.getengagementTrackingListActionRequried;
    },
    getTrackingListLoadedActionRequired() {
      return this.$store.getters.gettrackingListLoadedActionRequired;
    },
    getgettrackingListByUParent() {
      return this.$store.getters.gettrackingListByUParent;
    },
    getgettrackingListClientNoEngagement() {
      return this.$store.getters.gettrackingListClientNoEngagement;
    },
    gettrackingClientList() {
      return this.$store.getters.gettrackingClientList;
    },
    gettrackingClientListLoaded() {
      return this.$store.getters.gettrackingClientListLoaded;
    },
    gettrackingClientListNoEngagementsLoaded() {
      return this.$store.getters.gettrackingListClientNoEngagementLoaded;
    },
    trackingListBySwissTalLoaded() {
      return this.$store.getters.gettrackingListBySwissTalLoaded;
    },
     trackingListBySwissTal() {
      return this.$store.getters.gettrackingListBySwissTal;
    },
  },

  methods: {
    actionRequiredTrue() {
      this.displayActionRequired = true;
    },
    getActionRequired() {
      this.$store.dispatch(GET_ENGAGEMENT_TRACKING_ACTION_REQUIRED);
    },
    actionRequiredFalse() {
      this.displayActionRequired = false;
    },
    seachAllClients() {
      this.spinClient = true;
      this.$store.dispatch(GET_TRACKING_BY_NAME, this.filter);
    },
    clearSearch() {
      this.filter = null;
      this.$store.dispatch(LOAD_STATUS_TRACKING_BY_NAME);
      this.filterTAL = null;
      this.spin = false;
      this.spinClient = false; 
      this.$store.dispatch(LOAD_STATUS_TRACKING_BY_SWISS_TAL);
    },
    getClients(item) {
      this.$store.dispatch(GET_CLIENT_BY_UDUNS, item.ClientID);
    },
    disableButton() {
      this.isActive = true;
    },
    clientsNoEngagement() {
      this.displayClientsNoEngagement = true;
    },
    getclientsNoEngagement() {
      this.$store.dispatch(GET_CLIENT_NO_ENGAGEMENT, this.skip);
    },
    getclientsNoEngagementLoadMore() {
      this.skip = this.skip + 200;
      this.$store.dispatch(GET_CLIENT_NO_ENGAGEMENT, this.skip);
    },
    getclientsNoEngagementLoadLess() {
      this.skip = this.skip - 200;
      this.$store.dispatch(GET_CLIENT_NO_ENGAGEMENT, this.skip);
    },
    clientsNoEngagementFalse() {
      this.displayClientsNoEngagement = false;
      this.skip = 0;
    },
    resetClientLoadStatus() {
      this.$store.dispatch(LOAD_STATUS_TRACKING_CLIENT);
    },
    enableButtons() {
      this.isActive = false;
    },
    seachAllClientsforSwissTAL() {
      this.spin = true;
      this.$store.dispatch(GET_TRACKING_BY_SWISS_TAL, this.filterTAL);
    },
  },
};
</script>
