
// using this tutorial https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322

import axios from 'axios';

const url = apiurl();

function apiurl() {
  var hostname = window.location.hostname;
  if(hostname === "uat.talmanagement.ey.com"){
    return process.env.VUE_APP_BASE_URL_UAT
  }
  else if(hostname === "talmanagement.ey.com"){
    return process.env.VUE_APP_BASE_URL_PROD
  }
  else if(hostname === "localhost"){
    return process.env.VUE_APP_BASE_URL_LOCAL
  }
}

const baseDomain = url;

const baseURL = `${baseDomain}`;
const token = sessionStorage.getItem('adal.idtoken');;

export default axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
 },
});
