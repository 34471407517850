var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h5',[_vm._v("Opportunity Tracking by Client")]),(
       _vm.displayActionRequired === false &&
       _vm.displayClientsNoOpportunity === false
     )?_c('b-button',{staticClass:"btn-approve mx-2 btn-remove-border",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){_vm.actionRequiredTrue(), _vm.getActionRequired()}}},[_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}),_vm._v(" Show Where Action Required")],1):_vm._e(),(
       _vm.displayActionRequired === true &&
       _vm.TrackingListLoadedActionRequired === true
     )?_c('b-button',{staticClass:"btn-approve mx-2 btn-remove-border",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.actionRequiredFalse()}}},[_vm._v(" Show All Entries")]):_vm._e(),(
       _vm.displayClientsNoOpportunity === false &&
       _vm.TrackingListLoadedActionRequired === false
       
     )?_c('b-button',{staticClass:"btn-approve mx-2 btn-remove-border",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){_vm.clientsNoEngagement();
       _vm.getClientsNoOpportunity();}}},[_vm._v("Show Clients without Opportunities ")]):_vm._e(),(
       _vm.displayClientsNoOpportunity === true &&
       _vm.getOpportunitiesNoOppLoaded === true
     )?_c('b-button',{staticClass:"btn-approve mx-2 btn-remove-border",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.clientsNoOpportunityFalse()}}},[_vm._v(" Show All Entries")]):_vm._e(),_c('br'),_c('br'),_c('div',[_c('b-form-group',{staticClass:"mb-2",attrs:{"id":"form_group"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","placeholder":"Type to Search for Client Name"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[(_vm.trackingOpportunityByNameLoaded === false)?_c('b-button',{attrs:{"variant":"light","disabled":!_vm.filter},on:{"click":function($event){return _vm.seachAllClients()}}},[_c('b-icon-search')],1):_vm._e()],1),(_vm.trackingOpportunityByNameLoaded === true)?_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.clearSearch()}}},[_c('b-icon-x')],1):_vm._e(),(_vm.spinClient===true && _vm.trackingOpportunityByNameLoaded===false )?_c('b-spinner'):_vm._e()],1),_c('br'),_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","placeholder":"Type to Search for Swiss Tal"},model:{value:(_vm.filterTAL),callback:function ($$v) {_vm.filterTAL=$$v},expression:"filterTAL"}}),_c('b-input-group-append',[(_vm.trackingOpportunityBySwissTalLoaded === false)?_c('b-button',{attrs:{"variant":"light","disabled":!_vm.filterTAL},on:{"click":function($event){return _vm.seachAllClientsforSwissTAL()}}},[_c('b-icon-search')],1):_vm._e()],1),(_vm.trackingOpportunityBySwissTalLoaded === true)?_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.clearSearch()}}},[_c('b-icon-x')],1):_vm._e(),(_vm.spin===true && _vm.trackingOpportunityBySwissTalLoaded===false )?_c('b-spinner'):_vm._e()],1)],1),_c('br')],1),(
       _vm.displayActionRequired === false &&
       
       _vm.displayClientsNoOpportunity === false && 
       _vm.trackingOpportunityByNameLoaded === false &&
       _vm.trackingOpportunityBySwissTalLoaded === false
     )?_c('b-table',{attrs:{"bordered":"","fields":_vm.colchanges,"items":_vm.OpportunitiesForTracking,"small":"","outlined":"","busy":!_vm.TrackingListLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(Client)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isActive,"size":"sm","variant":"link"},on:{"click":function($event){_vm.getOpportunities(row.item);
           row.toggleDetails();
           _vm.disableButton();}}},[_vm._v(" "+_vm._s(row.detailsShowing ? row.item.Client : row.item.Client)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-table',{attrs:{"items":_vm.getOpportunitiesByClient,"fields":_vm.colsClients,"small":"","outlined":"","busy":!_vm.getOpportunitiesByClientLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":function($event){_vm.enableButtons();
             row.toggleDetails();
             _vm.resetClientLoadStatus();}}},[_vm._v("Close")])],1)]}},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,false,4156139286)}):_vm._e(),(
       _vm.displayActionRequired === true &&
        _vm.trackingOpportunityByNameLoaded === false &&
       _vm.trackingOpportunityBySwissTalLoaded === false
     )?_c('b-table',{attrs:{"bordered":"","fields":_vm.colchanges,"items":_vm.showActionRequired,"small":"","outlined":"","busy":!_vm.TrackingListLoadedActionRequired},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(Client)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isActive,"size":"sm","variant":"link"},on:{"click":function($event){_vm.getOpportunities(row.item);
           row.toggleDetails();
           _vm.disableButton();}}},[_vm._v(" "+_vm._s(row.detailsShowing ? row.item.Client : row.item.Client)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-table',{attrs:{"items":_vm.getOpportunitiesItems,"fields":_vm.colsClients,"small":"","outlined":"","busy":!_vm.getOpportunitiesItemsLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":function($event){_vm.isActive = !_vm.isActive;
             row.toggleDetails();
             _vm.resetClientLoadStatus();}}},[_vm._v("Close")])],1)]}},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,false,3487074639)}):_vm._e(),(_vm.trackingOpportunityByNameLoaded === true )?_c('b-table',{attrs:{"bordered":"","fields":_vm.colchanges,"items":_vm.gettrackingOpportunityByName,"small":"","outlined":""},scopedSlots:_vm._u([{key:"cell(Client)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isActive,"size":"sm","variant":"link"},on:{"click":function($event){_vm.getOpportunities(row.item);
           row.toggleDetails();
           _vm.disableButton();}}},[_vm._v(" "+_vm._s(row.detailsShowing ? row.item.Client : row.item.Client)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-table',{attrs:{"items":_vm.getOpportunitiesByClient,"fields":_vm.colsClients,"small":"","outlined":"","busy":!_vm.getOpportunitiesByClientLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":function($event){_vm.isActive = !_vm.isActive;
             row.toggleDetails();
             _vm.resetClientLoadStatus();}}},[_vm._v("Close")])],1)]}},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,false,4082520129)}):_vm._e(),(_vm.trackingOpportunityBySwissTalLoaded === true )?_c('b-table',{attrs:{"bordered":"","fields":_vm.colchanges,"items":_vm.gettrackingOpportunityBySwissTal,"small":"","outlined":""},scopedSlots:_vm._u([{key:"cell(Client)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isActive,"size":"sm","variant":"link"},on:{"click":function($event){_vm.getOpportunities(row.item);
           row.toggleDetails();
           _vm.disableButton();}}},[_vm._v(" "+_vm._s(row.detailsShowing ? row.item.Client : row.item.Client)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-table',{attrs:{"items":_vm.getOpportunitiesByClient,"fields":_vm.colsClients,"small":"","outlined":"","busy":!_vm.getOpportunitiesByClientLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":function($event){_vm.isActive = !_vm.isActive;
             row.toggleDetails();
             _vm.resetClientLoadStatus();}}},[_vm._v("Close")])],1)]}},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,false,4082520129)}):_vm._e(),(
       _vm.displayClientsNoOpportunity === true 
  
     )?_c('b-table',{attrs:{"bordered":"","fields":_vm.colchanges,"items":_vm.getOpportunitiesNoOpp,"small":"","outlined":"","busy":!_vm.getOpportunitiesNoOppLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(Client)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isActive,"size":"sm","variant":"link"},on:{"click":function($event){_vm.getOpportunities(row.item);
           row.toggleDetails();
           _vm.disableButton();}}},[_vm._v(" "+_vm._s(row.detailsShowing ? row.item.Client : row.item.Client)+" ")])]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-table',{attrs:{"items":_vm.getOpportunitiesByClient,"fields":_vm.colsClients,"small":"","outlined":"","busy":!_vm.getOpportunitiesByClientLoaded},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}})],1)]},proxy:true},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,true)}),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":function($event){_vm.isActive = !_vm.isActive;
             row.toggleDetails();
             _vm.resetClientLoadStatus();}}},[_vm._v("Close")])],1)]}},{key:"cell(growthOppValue)",fn:function(row){return [(row.item.growthOppValue === 0)?_c('b-icon-flag-fill',{attrs:{"variant":"danger"}}):_vm._e()]}}],null,false,474130767)},[_vm._v(" > ")]):_vm._e(),(
       _vm.displayClientsNoOpportunity === true 
       
       
     )?_c('b-button',{staticClass:"btn-approve mx-2 btn-remove-border",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){_vm.clientsNoEngagement();
       _vm.getClientsNoOpportunityLoadMore();}}},[_vm._v("Load more ")]):_vm._e(),(
       _vm.displayClientsNoOpportunity === true &&
       
       
       _vm.skip > 0
     )?_c('b-button',{staticClass:"btn-approve mx-2 btn-remove-border",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){_vm.clientsNoEngagement();
       _vm.getClientsNoOpportunityLoadLess();}}},[_vm._v("Back ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }