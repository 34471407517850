<template>
  <div class="information">
    <!--Header Picture block-->
    <div class="container-fluid bg-secondary mw-100 mx-auto p-0">
      <div class="ey-banner-wrapper">
        <div class="ey-img-box-home"></div>
        <div class="ey-img-overlay"></div>
        <div class="ey-text-box">
          <h1 class="text-white h1 font-weight-normal m-0">TAL 2.0 Information</h1>
        </div>
      </div>
    </div>

    <!--Empty spacer block-->
    <div class="container-fluid bg-white mw-100 m-0"  id="container_fluid_l"></div>
    <!-- Header Block -->
    <div class="container-fluid bg-white mw-100 px-12 mx-auto">
      <div class="row align-items-center">
        <div class="col text-left">
          <h2 class="h1 m-0 pb-5">TAL 2.0</h2>
        </div>
      </div>

      <div class="row d-flex justify-content-end">
        <div class="col-6">
          <p>Tax Account Leaders (TALs) are the main distribution engine for our services and solutions to our clients. The TAL needs to understand what our clients are thinking – their key issues and pain points. The TAL then harnesses the right EY resources – across Tax, Law, PAS and other service lines – to address our clients' most complex business challenges.</p>
          <p>In today's world, there are many more buyers of our services. We estimate 30% of our business will soon be sold to buyers outside the tax department. Our TALs need to continue to maintain excellent relationships in the tax department, while also building strong relationships with other potential buyers of Tax services, such as the CFO, Treasurer, Controller, Chief Human Resources Officer, General Counsel, etc.</p>
        </div>
        <div class="col">
          <div>
            <b-img
              src="../assets/information/TAL-TMSL-Tax-acct-leader-graphic-v3.jpg"
              fluid
              alt="Responsive image"
            ></b-img>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-white mw-100 m-0" id="container_fluid_m"></div>
      <div class="row d-flex justify-content-end">
        <div class="col">
          <p>An effective TAL:</p>
          <ul>
            <li>Owns the overall account and develops action plans for all potential buyers.</li>
            <li>Works with the GCSP and other account team members to develop a relationship map wider than just the tax department.​</li>
            <li>To leverage our junior staff –​ teach our people how to develop world class revenue relationships from the beginning of their careers. These relationships, start early, could last a lifetime and help us gain more share over time as client professionals move around and grow in their corporate environments.</li>
            <li>Need to be accountable to grow and develop the next generation of TALs. This is a key part of the role.</li>
          </ul>
        </div>
        
      </div>
      <br>
      <div class="col text-left ">
          <h3 class="h5 m-0 pb-3">Additional resources</h3>
        </div>
        
      <div class="row align-items-center" id="list">
        <div class="col text-left text-yellow">
          <ul>
            <li>
              <b-link
                href="https://sites.ey.com/sites/DS_Tax/Pages/tal-2.0-essentials.aspx"
               
              >TAL 2.0 Essentials</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/CHTALReporting3/Shared%20Documents/TAL%202.0%20Role%20Description%20and%20baseline%20performance%20guide.pdf"
               
              >TAL 2.0 Role Description and baseline performance guide</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/globaltaxstrategy/Fields%20of%20Play%20Playbook/Sitepages/Home.aspx"
               
              >Account Excellence for Tax/Fields of Play​</b-link>
            </li>
            <li>
              <b-link
                href="https://www.ey.com/en_gl/tax-guides"
               
              >Tax and Law Guides​</b-link>
            </li>
            <li>
              <b-link
                href="http://gfis2008pdr3.iweb.ey.com/clientlookup/eycrt/search.asp"
               
              >Client Research Tool</b-link>
            </li>
            <li>
              <b-link
                href="https://abc-vdd.ey.net/ABCVDD/(S(kqwiuy4al4g1rx1a1pzal31z))/HomePage"
               
              >Anti Bribery and Corruption Vendor Due-Diligence</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/PACE/SitePages/Community Home.aspx"
               
              >PACE</b-link>
            </li>
          </ul>
        </div>
      </div>
      

      <div class="row align-items-center" id="list">
        <div class="col text-left text-yellow">
          <ul>
            <li>
              <b-link
                href="https://internal.ey.net/sites/marketsaccelerator/SiteAssets/index.html#/home"
              >Market Accelerator</b-link>
            </li>
            <li>
              <b-link
                href="https://eygermany.sharepoint.com/sites/TaxWaveCases"
              >Tax Wavecases</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/DS_Tax/Pages/tax-agenda-home.aspx"
              >Tax Agenda</b-link>
            </li>
            <li>
              <b-link
                href="https://accountlens.ey.com/AccountLens/"
              >Account LENS</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/g360/hub/default.aspx#/"
              >G360 Hub</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/ServiceHub/SitePages/WelcomeTAX.aspx"
              >Welcome Tax Service Hub</b-link>
            </li>
            <li>
              <b-link
                href="https://eyswitzerland.sharepoint.com/sites/CHMarketPortal"
              >Market Portal Schweiz</b-link>
            </li>
          </ul>
        </div>
      </div>

      <div class="row align-items-center" id="list">
        <div class="col text-left text-yellow">
          <ul>
            <li>
              <b-link
                href="https://sites.ey.com/sites/globaltaxstrategy/SitePages/Home.aspx"
              >Global Tax Strategy</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/EYAppsMarket"
              >Tax Apps Market</b-link>
            </li>
            <li>
              <b-link
                href="https://eyus.sharepoint.com/sites/GBB"
              >GBB</b-link>
            </li>
            <li>
              <b-link
                href="https://sites.ey.com/sites/EU_audit_legislation_in_GSA/SitePages/Home.aspx"
              >GSA Channel Shift​</b-link>
            </li>
            <li>
              <b-link
                href="https://gear.ey.net/GEAR/home/search/quick_actions/"
               
              >GEAR</b-link>
            </li>
            <li>
              <b-link
                href="https://gis.ey.net/home/dashboard/search"
               
              >GIS</b-link>
            </li>
            <li>
              <b-link
                href="https://ard.ey.net/ARD Dashboards/ARDHomePage.aspx"
               
              >ARD Home​</b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- empty spacer block -->
    <div class="container-fluid bg-dark mw-100 m-0"  id="container_fluid_m"></div>
    <div class="container-fluid bg-dark mw-100 px-12 mx-auto">
      <div class="row align-items-center">
        
      </div>
      



      
       
    </div>
  </div>
</template>

<script>
export default {
  name: "Information",
};
</script>

<style>
#list {display: inline-block;
      margin: 0px 0px 20px;
}
#container_fluid_l {min-height: 40px;}
#container_fluid_m {min-height: 20px;}
#container_fluid_s {min-height: 10px;}
</style>
